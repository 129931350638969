import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import { IconButton } from "@mui/material";

const icons = {
  left: FormatAlignLeftIcon,
  center: FormatAlignCenterIcon,
  right: FormatAlignRightIcon,
  justify: FormatAlignJustifyIcon,
};

export default function AlignInputSelect(props: SelectProps) {
  return (
    <FormControl size="small">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        renderValue={(option) => {
          const Icon = icons[option as keyof typeof icons];
          return <Icon sx={{ verticalAlign: "text-bottom" }} />;
        }}
        {...props}
      >
        {Object.entries(icons).map(([key, Icon]) => (
          <MenuItem value={key} key={key}>
            <Icon />
          </MenuItem>
        ))}
        {/* <MenuItem value={"left"}><FormatAlignLeftIcon /></MenuItem>
                <MenuItem value={"center"}><FormatAlignCenterIcon /></MenuItem>
                <MenuItem value={"right"}><FormatAlignRightIcon /></MenuItem>
                <MenuItem value={"justify"}><FormatAlignJustifyIcon /></MenuItem> */}
      </Select>
    </FormControl>
  );
}
