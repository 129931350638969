import {
  ElementType,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
// import Image from "next/image";
import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
  useParallaxController,
} from "react-scroll-parallax";
import {
  Box,
  Breakpoint,
  Container,
  Grid,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { withSectionWrapper } from "../SectionWrapper";
import { renderDoc } from "../../requests/s3";
import { useLocation } from "react-router-dom";

const defaultBreakPoints = {
  xs: 444,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

type TextProp = TypographyProps & {
  component?: ElementType<any>;
  sx?: any;
};

export type Layer = {
  layout: "image-left" | "image-right" | "text" | "image";
  hidden?: boolean;
  speed: number;
  maxWidth?: Breakpoint | undefined;
  image?: {
    alt: string;
    src: string;
    style: any;
    filter?: {
      grayscale?: number;
      opacity?: number;
      brightness?: number;
      blur?: number;
    };
  };
  texts?: TextProp[];
};

export const defaultLayers: Layer[] = [
  {
    layout: "image",
    speed: -40,
    image: {
      src: "/banner-background.jpg",
      alt: "background",
      style: { objectFit: "cover" },
    },
  },
  {
    layout: "image",
    speed: -20,
    image: {
      src: "/banner-foreground.png",
      alt: "background",
      style: { objectFit: "cover" },
    },
  },
  {
    layout: "image-right",
    speed: 0,
    texts: [
      {
        variant: "h1",
        component: "h1",
        fontWeight: "700",
        children: "Hello World!",
        align: "center",
      },
      { variant: "h2", children: "Hello" },
    ],
    image: {
      src: "/Logo-BMB-Blanc.png",
      alt: "background",
      style: { objectFit: "contain" },
    },
  },
];

const LayoutGridContainer = ({ children }: PropsWithChildren<{}>) => (
  <Grid
    container
    columns={2}
    spacing={0}
    sx={{ width: "100%", height: "100%" }}
  >
    {children}
  </Grid>
);

type LayoutGridItem = {
  sx?: { [key: string]: any };
};
const LayoutGridItem = ({
  children,
  sx = {},
}: PropsWithChildren<LayoutGridItem>) => (
  <Grid item xs={2} sm={1} sx={{ position: "relative" }} style={{ ...sx }}>
    {children}
  </Grid>
);

const ImageComponent = ({
  filter,
  style,
  alt,
  src,
}: {
  src: { imageUrl: string } | string;
  alt: string;
  filter?: any;
  style?: any;
}) => {
  const [image, setImage] = useState<any>();

  useEffect(() => {
    const getUrl = async (path: string) => {
      return await renderDoc(path);
    };
    if (typeof src === "object") {
      if (src?.hasOwnProperty("imageUrl")) {
        getUrl(src.imageUrl).then((res) =>
          setImage((res as { url: string; data: string }).url)
        );
      }
    } else {
      setImage(src);
    }
  }, [src]);
  const otherProps = {
    ...{
      style: {
        ...style,
        filter: `${
          filter?.grayscale !== undefined
            ? `grayscale(${filter?.grayscale}%)`
            : ""
        } ${
          filter?.opacity !== undefined ? `opacity(${filter?.opacity}%)` : ""
        } ${
          filter?.brightness !== undefined
            ? `brightness(${filter?.brightness}%)`
            : ""
        } ${filter?.blur !== undefined ? `blur(${filter?.blur}px)` : ""}`,
        position: "absolute",
        height: "100%",
        width: "100%",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      alt,
    },
  };
  console.log("HeroSection - ImageComponent", { otherProps });
  return image ? (
    <img
      src={image}
      {...otherProps}
      alt=""
      sizes="(max-width: 600px) 100vw, 50vw"
    />
  ) : // <Image
  //   priority={process.env.NEXT_PUBLIC_BUILD_PAGES === "true"}
  //   fill
  //   {...otherProps}
  // />
  null;
};

const Layout = ({ layout, ...rest }: Omit<Layer, "speed">) => {
  const { image, texts } = rest;
  switch (layout) {
    case "image":
      return image ? <ImageComponent {...image} /> : null;
    case "text":
      return rest.texts ? <TextLayerContent {...{ texts }} /> : null;
    case "image-left":
      return (
        <LayoutGridContainer>
          <LayoutGridItem sx={{ zIndex: "-1" }}>
            {image ? <ImageComponent {...image} /> : null}
          </LayoutGridItem>
          <LayoutGridItem>
            {rest.texts ? <TextLayerContent {...{ texts }} /> : null}
          </LayoutGridItem>
        </LayoutGridContainer>
      );
    case "image-right":
      return (
        <LayoutGridContainer>
          <LayoutGridItem>
            {rest.texts ? <TextLayerContent {...{ texts }} /> : null}
          </LayoutGridItem>
          <LayoutGridItem sx={{ zIndex: "-1" }}>
            {image ? <ImageComponent {...image} /> : null}
          </LayoutGridItem>
        </LayoutGridContainer>
      );
  }
};

const TextLayerContent = ({ texts }: { texts: any }) => {
  const location = useLocation();
  return (
    <div
      style={{
        // position: 'absolute',
        // top: 0,
        // left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: location.pathname.includes("/blog")
          ? "flex-start"
          : "center",
        marginTop: location.pathname.includes("/blog") ? 150 : 0,
      }}
    >
      <Stack
        direction="column"
        sx={{ p: { xs: 2, sm: 3 }, position: "relative", zIndex: "1" }}
        spacing={{ xs: 2, sm: 3 }}
      >
        {texts.map(
          ({ children, ...rest }: { children: ReactNode }, index: number) => (
            <Typography key={index} {...rest}>
              {children}
            </Typography>
          )
        )}
      </Stack>
    </div>
  );
};

const HeroSection = (props: { layers: Layer[]; refAnnonce?: number }) => {
  const location = useLocation();
  const { breakpoints } = useTheme();
  const { layers = [], refAnnonce } = props;
  console.log("HeroSection", { props, layers, breakpoints });
  return (
    <ParallaxProvider>
      <ParallaxBanner
        style={{
          height: location.pathname.includes("/blog") ? "70vh" : "100vh",
        }}
      >
        {refAnnonce ? (
          <Typography
            sx={{
              position: "absolute",
              zIndex: 1,
              backgroundColor: "#fff",
              p: 2,
              display: "inline-block",
              m: 2,
            }}
          >
            Reférence : {refAnnonce}
          </Typography>
        ) : null}
        {layers.map(({ speed, maxWidth = "xl", hidden, ...rest }, index) => {
          console.log(
            "HeroSection",
            index,
            speed,
            breakpoints.values[maxWidth]
          );
          const style = maxWidth
            ? { maxWidth: defaultBreakPoints[maxWidth], margin: "auto" }
            : {};
          return hidden ? null : (
            <ParallaxBannerLayer
              key={`layer-${index}`}
              {...{ speed }}
              style={{
                ...style,
                zIndex: location.pathname.includes("/blog")
                  ? "0"
                  : index === 1
                  ? "-1"
                  : "0",
              }}
            >
              <Layout {...rest} />
            </ParallaxBannerLayer>
          );
        })}
      </ParallaxBanner>
    </ParallaxProvider>
  );
};

export default withSectionWrapper(HeroSection);
