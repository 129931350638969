import React, { useState, useEffect } from "react";
import { FormControlLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectAnnonceProp, setAnnonceProp } from "../../store/annonceSlice";
import { useParams } from "react-router-dom";
import { CustomSwitch } from "./CustomStyles";
// import { useRouter } from "next/router";

const DisplayInAnnonce = ({
  path,
  label,
}: {
  path: string[];
  label: string;
}) => {
  const dispatch = useDispatch();
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  //   const [display, setDisplay] = useState(true);

  const display = useSelector(
    selectAnnonceProp([...path.slice(1), "display"], false)
  );
  console.log("sdfg", display);
  //   useEffect(() => {
  //     if (stateObject.hasOwnProperty("display")) {
  //       setDisplay(stateObject.display);
  //     } else {
  //       setDisplay(true);
  //     }
  //   }, [stateObject]);

  const changeDisplay = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "display"],
        value: event.target.checked,
      })
    );
  };

  return (
    <FormControlLabel
      control={<CustomSwitch checked={display} onChange={changeDisplay} />}
      label={label}
      sx={{ mb: 1, ml: "-3px" }}
    />
  );
};

export default DisplayInAnnonce;
