import React, { Dispatch, SetStateAction } from "react";
import {
  Theme,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  Chip,
  useTheme,
  OutlinedInput,
  FormLabel,
} from "@mui/material";

type Props = {
  chambres: string[];
  setChambres: Dispatch<SetStateAction<string[]>>;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

function getStyles(name: string, chambres: readonly string[], theme: Theme) {
  return {
    fontWeight:
      chambres.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const Chambres = ({ chambres, setChambres }: Props) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setChambres(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl sx={{ width: 250, height: "64.983px" }}>
      <InputLabel id="demo-multiple-chip-label">Chambres</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={chambres}
        onChange={handleChange}
        input={
          <OutlinedInput
            id="select-multiple-chip"
            label="Chambres"
            inputProps={{
              sx: {
                lineHeight: "2em",
              },
            }}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "no-wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {["1", "2", "3", "4", "5", "6 +"].map((number) => (
          <MenuItem
            key={number}
            value={number}
            style={getStyles(number, chambres, theme)}
          >
            {number}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Chambres;
