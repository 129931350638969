import { setConnected } from "../store/authSlice";
import store from "../store/store";
import { getCurrent } from "../util/getCurrent";
import { getToken } from "../util/getToken";

export const loginRequest = async (email: string, password: string) => {
  const token = await getToken(email, password);

  if (token === "error") {
    return "error";
  } else {
    const user = await getCurrent(token as string);
    if (user === "error") {
      return "error";
    } else {
      if (user.data.directProprietaire) {
        localStorage.setItem("token", token as string);
        store.dispatch(setConnected({ data: user, token: token }));
      } else {
        return "error";
      }
    }
  }
};
