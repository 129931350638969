import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import set from "lodash.set";
import get from "lodash.get";
import { selectCurrentAnnonceId } from "./uiSlice";

export type Annonce = {
  _id: string;
  data: {
    data: string | any;
    [key: string]: any;
  };
  [key: string]: any;
};

const annonceAdapter = createEntityAdapter<Annonce>({
  selectId: (annonce) => annonce._id,
});

export const annonceSlice = createSlice({
  name: "annonces",
  initialState: annonceAdapter.getInitialState(),
  reducers: {
    setAllAnnonces: annonceAdapter.setAll,
    setAnnonceProp(state, action) {
      const { path, value, id } = action.payload;
      const entity = { ...state.entities[id as string]! };
      set(entity, ["data", ...path], value);
      state.entities[id as string]! = { ...entity };
    },
    // setAnnonces: (state, action) => {
    //   state.annonce = action.payload;
    // },
  },
});

export const { setAllAnnonces, setAnnonceProp } = annonceSlice.actions;
export const selectAnnonceProp =
  (...args: any[]) =>
  (state: any) => {
    try {
      const id = selectCurrentAnnonceId(state);
      return args.length === 1
        ? get(state.undoable.present.annonces.entities[id]!.data.data, args[0])
        : get(
            state.undoable.present.annonces.entities[id]!.data.data,
            args[0],
            args[1]
          );
    } catch (error) {
      return args.length === 1 ? undefined : args[1];
    }
  };
export const selectAnnoncePropOutData =
  (...args: any[]) =>
  (state: any) => {
    try {
      const id = selectCurrentAnnonceId(state);
      return args.length === 1
        ? get(state.undoable.present.annonces.entities[id]!.data, args[0])
        : get(
            state.undoable.present.annonces.entities[id]!.data,
            args[0],
            args[1]
          );
    } catch (error) {
      return args.length === 1 ? undefined : args[1];
    }
  };
export const {
  selectById: selectAnnonceById,
  selectEntities: selectLocalPageEntities,
  selectAll: selectAllAnnonces,
} = annonceAdapter.getSelectors(
  (state: any) => state.undoable.present.annonces
);

export default annonceSlice.reducer;
