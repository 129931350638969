import { setConnected } from "../store/authSlice";
import store from "../store/store";
import { getCurrent } from "../util/getCurrent";

export const signup = async (infos: {
  nomcomplet: string;
  email: string;
  password: string;
}) => {
  try {
    const response = await fetch(
      "https://formio.mobilecoveragemap.com/user/register",
      {
        method: "POST",
        body: JSON.stringify({
          data: {
            ...infos,
            directProprietaire: true,
          },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      const token = response.headers.get("x-jwt-token");
      const user = await getCurrent(token as string);
      if (user !== "error") {
        localStorage.setItem("token", token as string);
        store.dispatch(setConnected({ data: user, token: token }));
        return "ok";
      } else {
        return "error";
      }
    } else {
      return "error";
    }
  } catch (err) {
    return "error";
  }
};
