import React, { useEffect } from "react";
import {
  selectPaginationProp,
  setCurrentPage,
  setNumberPerPage,
  setTotal,
} from "../store/paginationSlice";
import { useDispatch, useSelector } from "react-redux";
import { Pagination as MuiPagination, Box } from "@mui/material";

type Props = {
  data: any[];
  props: { [key: string]: any };
  numberPerPage?: number;
  wrapper?: any;
};

const Pagination = ({ data, props, numberPerPage = 9, wrapper }: Props) => {
  const dispatch = useDispatch();
  const totalPages = useSelector(selectPaginationProp("totalPages"));
  const currentPage = useSelector(selectPaginationProp("currentPage"));
  const total = useSelector(selectPaginationProp("total"));
  const numberPerPageStore = useSelector(selectPaginationProp("numberPerPage"));

  useEffect(() => {
    if (numberPerPage !== numberPerPageStore) {
      dispatch(setNumberPerPage(numberPerPage));
    }
  }, [numberPerPage]);

  useEffect(() => {
    if (total !== data.length) {
      dispatch(setTotal(data.length));
    }
  }, [data]);

  return (
    <Box display="flex" justifyContent="center" mt={4}>
      <MuiPagination
        count={totalPages}
        onChange={(event: React.ChangeEvent<unknown>, page: number) => {
          dispatch(setCurrentPage(page));
          wrapper.current.scrollIntoView();
        }}
        page={currentPage}
        siblingCount={3}
        {...props}
      />
    </Box>
  );
};

export default Pagination;
