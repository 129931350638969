import React from "react";
import { Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {};

const PolitiqueConfidentialite = (props: Props) => {
  return (
    <Container maxWidth="xl" sx={{ fontWeight: "400" }}>
      <Typography variant="h1" textAlign="center" mt={6}>
        Politique de Confidentialité
      </Typography>
      <ul style={{ marginTop: "48px" }}>
        <li style={{ lineHeight: "1.5" }}>
          La personne en charge et déléguée à la protection des données (DPO ou
          DPD) que vous pouvez contacter sur les questions portant sur
          l’utilisation de données personnelles est M. Paul Muller : 
          <Link to="/#contactez-nous">via ce formulaire de contact</Link>
        </li>
        <li style={{ lineHeight: "1.5" }}>
          Les données collectées depuis notre site internet, 
          <Link to="/#contactez-nous">via ce formulaire</Link> de contact est
          destinée à contacter par téléphone ou par e-mail les personnes ayant
          complétées et envoyées ces formulaires afin de répondre à leurs
          questions, de leurs proposer des offres commerciales, de leur proposer
          un ou des rendez-vous, de leur envoyer notre newsletter pour les
          personnes l’ayant demandées.
        </li>
        <li style={{ lineHeight: "1.5" }}>
          Lorsque que vous consultez notre site internet, un cookie interne va
          mémoriser votre visite sur notre site internet permettant de tracer
          votre navigation à des fins statistiques ou publicitaires et
          d’effectuer une analyse par segment. Un bandeau vous avertissant de la
          présence d’un tracking apparaît donc. Vous pouvez nous informer de
          votre refus ou de votre accord tout en poursuivant votre navigation
          sur notre site internet.
        </li>
        <li style={{ lineHeight: "1.5" }}>
          Le caractère obligatoire ou facultatif des réponses et des
          conséquences pour l’internaute s’il ne répond pas à la question :{" "}
          <ul style={{ marginTop: "16px" }}>
            <li>
              Concernant{" "}
              <Link to="/#contactez-nous">ce formulaire de contact</Link>
              <Typography variant="body2">
                Les informations obligatoires sont : Nom, Prénom, Date de
                naissance, E-mail. Ces informations nous permettent d’identifier
                le contact et de posséder ses coordonnées pour revenir vers lui
                suite à sa demande de contact. S’il ne répond pas aux réponses,
                sa demande ne peut être envoyée.
              </Typography>
              <Typography variant="body2">
                Destinataire des données : M. Paul Muller
              </Typography>
              <ul style={{ marginTop: "16px" }}>
                <li style={{ lineHeight: "1.5" }}>
                  Droit de l’internaute, du client, à accéder, à rectifier, à
                  s’opposer et à effacer ses données : vous pouvez supprimer
                  votre demande de contact en contactant le service client de M.
                  Paul Muller à l’adresse e-mail suivante :{" "}
                  <Link to="/#contactez-nous">ce formulaire de contact</Link>
                </li>
                <li style={{ lineHeight: "1.5" }}>
                  Pas de transferts de données à caractère personnel envisagés à
                  destination d’un État n’appartenant pas à l’Union européenne.
                </li>
                <li style={{ lineHeight: "1.5" }}>
                  L’internaute, le client, s’il le souhaite peut déposer une
                  plainte auprès de la Cnil 
                  <Link to="https://www.cnil.fr/">https://www.cnil.fr/</Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <Typography variant="h2" mt={6}>
        QU’EST-CE QUE LA RGPD ? QUELLES SONT LES MESURES PRISENT PAR NOTRE
        ENTREPRISE POUR RESPECTER LA RGPD ?
      </Typography>

      <Typography variant="body2" mt={2}>
        Le RGPD pour Internet, c’est le Règlement Général sur la Protection des
        Données. À chaque visite sur notre site internet nous devons vous
        demander automatiquement votre accord avant toute collecte de
        renseignements. Nous devons vous assurer un archivage hermétique à tout
        piratage et vous autoriser à modifier vos données personnelles à tout
        moment.
      </Typography>
      <Typography variant="body2" mt={2}>
        En France, le gouvernement a institué la CNIL, la Commission Nationale
        de l’Informatique et des Libertés, en 1978. Coordonnée à la loi de
        l’Informatique et des Libertés, elle a la responsabilité de surveiller
        la mise en application de cette régulation.
      </Typography>
      <Typography variant="body2" mt={2}>
        Cependant, de quoi parle-t-on quand on évoque vos données personnelles
        dans notre entreprise ?
      </Typography>
      <ol style={{ marginTop: "16px" }}>
        <li style={{ marginBottom: "8px" }}>
          Les informations portant sur une personne identifiable ou identifiée :
          Nom, prénom, date de naissance, e-mail
        </li>
        <li style={{ marginBottom: "8px" }}>
          L’identification réalisable à partir d’une donnée unique :
          Localisation
        </li>
        <li style={{ marginBottom: "8px" }}>
          L’identification opérée avec un croisement d’informations : Adresse
        </li>
      </ol>
      <Typography variant="body2" mt={2}>
        Le traitement de données personnelles est tout ce qui recense des
        informations privées sous forme de recensement, d’enregistrement, de
        changement, d’examen, d’usage, ou de transmission de renseignements.
        Chaque récupération doit posséder un objectif bien clair, une finalité
        légale, pour nous : la gestion de notre clientèle. En effet, nous
        récupérons vos informations, vos coordonnées, vos données personnelles
        via <Link to="/#contactez-nous">ce formulaire de contact</Link>
      </Typography>
      <Typography variant="body2" mt={2}>
        Nos actions de mises en conformité aux normes RGPD :
      </Typography>
      <ol style={{ marginTop: "16px", marginBottom: "80px" }}>
        <li style={{ marginBottom: "16px" }}>
          L’élaboration d’un registre de traitement de données, qui recense nos
          activités nécessitant la récupération et le traitement de données, y
          figurent : Les types de données (nom, prénom) ; Les personnes qui y
          ont accès (service informatique, prestataires, hébergeurs) ; Les
          durées opérationnelles (archive de ces informations).
        </li>
        <li style={{ marginBottom: "16px" }}>
          Le tri des données :{" "}
          <Typography variant="body2" mt={1}>
            Chaque donnée renseignée est utilisée pour nos activités. Nous ne
            gardons que les données suivantes : Nom, (Prénom), Date de
            naissance, E-mail, le Message. Nous vérifions de ne posséder aucune
            donnée dite sensible (en exemple : opinion politique, origine
            raciale ou ethnique, convictions religieuses ou philosophiques ou
            l’appartenance syndicale, traitement des données génétiques, données
            biométriques aux fins d’identifier une personne physique de manière
            unique, données concernant la santé, données concernant la vie
            sexuelle ou l’orientation sexuelle d’une personne physique), seules
            les personnes autorisées y ont accès et nous ne gardons pas ces
            données au-delà du délai nécessaire. En effet, les indices marketing
            n’ont pas le droit d’être gardés plus de 3 ans.
          </Typography>
        </li>
        <li style={{ marginBottom: "16px" }}>
          Nous respectons le droit des personnes :{" "}
          <Typography variant="body2" mt={1}>
            Pour répondre à une responsabilité de transparence, nous devons vous
            informer sur les mentions obligatoires de nos formulaires. Indiquer
            nos mentions légales et indiquer ce que vous êtes en train de lire
            ci-dessus et ci-dessous. Vous possédez le droit d’accéder, de
            rectifier, d’opposer et d’effacer vos données : vous pouvez
            supprimer votre demande de contact, nous demander de savoir où et
            comment sont stockés vos données, de modifier, rectifier et effacer
            vos données en contactant le service client{" "}
            <span style={{ fontWeight: "700" }}>M. Paul Muller</span> via{" "}
            <Link to="/#contactez-nous">ce formulaire de contact</Link>. Lorsque
            que vous consultez notre site internet, un cookie interne va
            mémoriser votre visite sur notre site internet permettant de tracer
            votre navigation à des fins statistiques ou publicitaires et
            d’effectuer une analyse par segment. Un bandeau vous avertissant de
            la présence d’un tracking apparaît donc. Vous pouvez nous informer
            de votre refus ou de votre accord tout en poursuivant votre
            navigation sur notre site internet.
          </Typography>
        </li>
        <li style={{ marginBottom: "16px" }}>
          Nous garantissons la sécurité des données personnelles :{" "}
          <Typography variant="body2" mt={1}>
            En ayant mis en place des moyens, des procédures, des outils et des
            logiciels de sécurité que nous ne mentionnerons point ici par soucis
            de sécurité. Si nous sommes ou vous êtes victime d’une violation des
            informations, contactez la CNIL dans les 72 heures. N’hésitez pas à
            consulter le site gouvernemental de cyber malveillance pour plus
            d’informations : 
            <Link to="https://www.cybermalveillance.gouv.fr">
              https://www.cybermalveillance.gouv.fr
            </Link>
          </Typography>
        </li>
      </ol>
    </Container>
  );
};

export default PolitiqueConfidentialite;
