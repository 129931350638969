import React, { useEffect, useRef } from "react";
import { Box, Button, Typography, Grid, Container } from "@mui/material";
import heroImage from "../images/blog/direct-proprietaire-hero-blogV2.webp";
import HeroSection from "../components/AnnonceComponents/HeroSection";
import image from "../images/direct-proprietaire-annonce-bien-achat-immobilier-blanc.png";
import { useSelector } from "react-redux";
import ArticleCard from "../components/dashboard/ArticleCard";
import { selectAllArticles } from "../store/articleSlice";
import Pagination from "../components/Pagination";
import useArticles from "../hooks/useArticles";

type Props = {};

const Blog = (props: Props) => {
  const articles = useSelector(selectAllArticles);
  const displayArticles = useArticles();
  const wrapperRef = useRef(null);

  useEffect(() => {
    //preloading image
    const img = new Image();
    img.src = heroImage;
  }, []);

  const heroData = {
    layers: [
      {
        layout: "image",
        speed: 0,
        image: {
          src: heroImage,
          alt: "background",
          style: {
            objectFit: "cover",
          },
        },
        maxWidth: false,
      },
      {
        layout: "image-right",
        speed: 0,
        texts: [
          {
            variant: "h1",
            component: "h1",
            fontWeight: "700",
            children: "Notre Blog",
            align: "left",
            sx: {
              backgroundColor: "white",
            },
          },
          {
            variant: "subtitle1",
            component: "p",
            fontWeight: 500,
            children:
              "Nos conseils & astuces pour vous accompagner dans votre aventure immobilière",
            align: "left",
            sx: {
              backgroundColor: "white",
              opacity: "0.6",
            },
          },
        ],
        maxWidth: false,
      },
    ],
  };
  return (
    <Box>
      <HeroSection {...heroData} sectionProps={heroData} />

      <Container maxWidth="xl" sx={{ p: 4 }}>
        {displayArticles ? (
          <>
            <Grid
              ref={wrapperRef}
              container
              columns={{ lg: 3, xs: 1, sm: 2 }}
              spacing={4}
            >
              {displayArticles?.map((article: any) => (
                <ArticleCard article={article.data} key={article._id} />
              ))}
            </Grid>
            <Pagination
              data={articles}
              props={{
                sx: {
                  "& .MuiPaginationItem-root:hover, & .MuiPaginationItem-root.Mui-selected, , & .MuiPaginationItem-root.Mui-selected:hover":
                    {
                      backgroundColor: "transparent",
                      color: "primary.main",
                    },
                  "& .MuiPaginationItem-root": {
                    fontWeight: "500",
                  },
                },
                hideNextButton: true,
                hidePrevButton: true,
              }}
              wrapper={wrapperRef}
            />
          </>
        ) : null}
        <Box
          sx={{
            borderRadius: "40px",
            background: "#0A489A",
            boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.20)",
            padding: 6,
            mt: 6,
          }}
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box
            sx={{
              flex: 1,
              mr: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <Typography color="white" variant="h2">
              Avec Direct Propriétaire
            </Typography>
            <Typography
              color="white"
              variant="h3"
              fontWeight="400"
              style={{ fontSize: "20px" }}
            >
              Créez vous-même votre site internet temporaire pour{" "}
              <Typography component="span" fontSize="inherit" fontWeight="700">
                vendre/louer votre bien immobilier
              </Typography>
            </Typography>
            <Box>
              <Button
                variant="contained"
                href="/creer-compte"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  fontStyle: "italic",
                  fontSize: 20,
                  "&:hover": {
                    backgroundColor: "primary",
                    color: "white",
                  },
                }}
              >
                Créer votre site dès maintenant !
              </Button>
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <img src={image} alt="" style={{ width: "100%" }} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Blog;
