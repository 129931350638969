import { IconButton } from "@mui/material";
import { memo, MouseEventHandler } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import Image from "next/image";

// eslint-disable-next-line react/display-name
const Item = memo(
  ({
    description,
    fullscreen, // fullscreen version of img
    handleImageLoaded,
    isFullscreen,
    onImageError,
    original,
    originalAlt,
    originalHeight,
    originalWidth,
    originalTitle,
    sizes,
    srcSet,
    loading,
    type,
    checkFullscreen,
  }: any) => {
    const itemSrc = isFullscreen ? fullscreen || original : original;
    console.log(isFullscreen);
    return type === "image" ? (
      <>
        <img
          className={
            checkFullscreen
              ? "image-gallery-image-fullscreen image-gallery-image"
              : "image-gallery-image"
          }
          src={itemSrc}
          alt={originalAlt}
          srcSet={srcSet}
          height={originalHeight}
          width={originalWidth}
          sizes={sizes}
          title={originalTitle}
          onError={onImageError}
          loading={loading}
        />
        {description && (
          <p
            className="image-gallery-description"
            style={{ whiteSpace: "pre-wrap", marginTop: "0" }}
          >
            {description}
          </p>
        )}
      </>
    ) : (
      <div>
        <video
          src={itemSrc}
          controls
          key={itemSrc}
          className={
            checkFullscreen
              ? "image-gallery-image-fullscreen image-gallery-image"
              : "image-gallery-image"
          }
        />
        {description && (
          <p
            className="image-gallery-description"
            style={{
              whiteSpace: "pre-wrap",
              marginTop: "0",
            }}
          >
            {description}
          </p>
        )}
      </div>
    );
  }
);

// eslint-disable-next-line react/display-name
export const LeftNav = memo(
  ({
    onClick,
    disabled,
  }: {
    onClick: MouseEventHandler<HTMLElement>;
    disabled: boolean;
  }) => {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        style={{
          backgroundColor: "white",
          padding: "8px",
          borderRadius: "90px",
          color: "var(--primary-color)",
          left: "15px",
        }}
        className="image-gallery-icon image-gallery-left-nav"
        aria-label="Previous Slide"
      >
        <KeyboardArrowLeftIcon />
      </button>
    );
  }
);

// eslint-disable-next-line react/display-name
export const RightNav = memo(
  ({
    onClick,
    disabled,
  }: {
    onClick: MouseEventHandler<HTMLElement>;
    disabled: boolean;
  }) => {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        style={{
          backgroundColor: "white",
          padding: "8px",
          borderRadius: "90px",
          color: "var(--primary-color)",
          right: "15px",
        }}
        className="image-gallery-icon image-gallery-right-nav"
        aria-label="Next Slide"
      >
        <KeyboardArrowRightIcon />
      </button>
    );
  }
);

export default Item;
