import React, { useState, ChangeEvent, Dispatch, SetStateAction } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Stack,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectUser } from "../store/authSlice";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CircleIcon from "@mui/icons-material/Circle";
import { setMessage } from "../store/errorSuccessSlice";
import { postReponse } from "../requests/amplify";

type Props = {
  owner: string;
  annonceId: string;
  buyer?: string;
  setInterested: Dispatch<SetStateAction<boolean>>;
  setAlreadyExists: Dispatch<SetStateAction<boolean>>;
};

const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const QCM = ({
  owner,
  annonceId,
  buyer,
  setInterested,
  setAlreadyExists,
}: Props) => {
  const [answers, setAnswers] = useState<any>({
    nom: "",
    emailAcheteur: "",
    dejaCredit: "",
    accordBanque: "",
    partEmprunt: "",
    region: "",
    message: "",
  });
  const [options, setOptions] = useState<any>([
    "Terrasse accueillante",
    "Terrain de pétanque",
    "Piscine",
    "Jardin potager",
  ]);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  let disabled =
    !answers.emailAcheteur.match(mailformat) ||
    !answers.nom ||
    !answers.dejaCredit ||
    !answers.accordBanque ||
    !answers.partEmprunt ||
    !answers.region;

  const submit = () => {
    const submission = {
      ...answers,
      ordreImportance: [...options],
    };
    if (buyer !== undefined) {
      submission.nom = user?.nomComplet;
      submission.emailAcheteur = user.email;
      submission.buyerId = buyer;
    }

    submission.ownerId = owner;
    submission.annonceId = annonceId;
    submission.rdvVirtuel = "";
    submission.rdvSurplace = "";
    submission.toujoursInteresse = "";
    submission.remarques = "";
    submission.dispoVirtuelle = false;
    submission.demandeRetour = false;
    submission.dispoSurplace = false;
    submission.dateRetour = "";

    postReponse({ data: submission, ownerId: owner }).then((res) => {
      if (res === "ok") {
        dispatch(
          setMessage({
            message: "Vos informations ont été transmises au propriétaire",
            type: "success",
          })
        );
        setAnswers({
          nom: "",
          emailAcheteur: "",
          dejaCredit: "",
          accordBanque: "",
          partEmprunt: "",
          region: "",
          message: "",
        });
        setInterested(false);
      } else if (res === "error") {
        dispatch(
          setMessage({
            message: "Une erreur s'est produite, veuillez réeesayer",
            type: "error",
          })
        );
      } else {
        setAlreadyExists(true);
        setAnswers({
          nom: "",
          emailAcheteur: "",
          dejaCredit: "",
          accordBanque: "",
          partEmprunt: "",
          region: "",
          message: "",
        });
        setInterested(false);
      }
    });
  };

  const changeRadio = (event: ChangeEvent<HTMLInputElement>, prop: string) => {
    setAnswers(
      (state: {
        nom: string;
        emailAcheteur: string;
        dejaCredit: string;
        accordBanque: string;
        partEmprunt: string;
        region: string;
        message: string;
      }) => ({ ...state, [prop]: event.target.value })
    );
  };

  const changeOptionsOrder = (index: number, direction: string) => {
    const to = direction === "moins" ? index - 1 : index + 1;
    const opt = [...options];
    const item = opt.splice(index, 1)[0];
    opt.splice(to, 0, item);
    setOptions(opt);
  };

  return (
    <Box>
      <Typography sx={{ fontSize: "1.5rem", fontWeight: "700" }}>
        Pour envoyer votre demande, merci de répondre au petit questionnaire
        suivant :
      </Typography>
      <Typography color="primary.main">
        *Vos réponses seront visibles seulement du propriétaire.
      </Typography>
      <Stack>
        {buyer === undefined ? (
          <>
            <FormControl sx={{ mt: 2.5 }}>
              <TextField
                label="Nom complet"
                sx={{
                  "& label": { fontSize: "1rem" },
                  "& fieldset > legend ": { fontSize: "calc(0.75 * 1.4rem)" },
                  "& input": { fontSize: "1rem" },
                }}
                variant="outlined"
                value={answers.nom}
                onChange={(e) =>
                  setAnswers(
                    (state: {
                      nom: string;
                      emailAcheteur: string;
                      dejaCredit: string;
                      accordBanque: string;
                      partEmprunt: string;
                      region: string;
                      message: string;
                    }) => ({ ...state, nom: e.target.value })
                  )
                }
              />
            </FormControl>
            <FormControl sx={{ mt: 1.5 }}>
              <TextField
                label="Adresse email"
                variant="outlined"
                sx={{
                  "& label": { fontSize: "1rem" },
                  "& fieldset > legend ": { fontSize: "calc(0.75 * 1.4rem)" },
                  "& input": { fontSize: "1rem" },
                }}
                value={answers.emailAcheteur}
                onChange={(e) =>
                  setAnswers(
                    (state: {
                      nom: string;
                      emailAcheteur: string;
                      dejaCredit: string;
                      accordBanque: string;
                      partEmprunt: string;
                      region: string;
                      message: string;
                    }) => ({ ...state, emailAcheteur: e.target.value })
                  )
                }
              />
            </FormControl>
          </>
        ) : null}
        <FormControl sx={{ mt: 3 }}>
          <FormLabel
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.2rem",
            }}
          >
            Êtes-vous déjà engagé dans un crédit ?
          </FormLabel>
          <RadioGroup
            row
            value={answers.dejaCredit}
            onChange={(e) => changeRadio(e, "dejaCredit")}
          >
            <FormControlLabel
              value="oui"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "700" } }}
              control={
                <Radio sx={{ "&.Mui-checked": { color: "dark.main" } }} />
              }
              label="Oui"
            />
            <FormControlLabel
              value="non"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "700" } }}
              control={
                <Radio sx={{ "&.Mui-checked": { color: "dark.main" } }} />
              }
              label="Non"
            />
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ mt: 3 }}>
          <FormLabel
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.2rem",
            }}
          >
            Avez-vous eu une confirmation de votre partenaire financier
            (banque…) ?
          </FormLabel>
          <RadioGroup
            row
            value={answers.accordBanque}
            onChange={(e) => changeRadio(e, "accordBanque")}
          >
            <FormControlLabel
              value="pasBesoin"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "700" } }}
              control={
                <Radio sx={{ "&.Mui-checked": { color: "dark.main" } }} />
              }
              label="Pas besoin"
            />
            <FormControlLabel
              value="oui"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "700" } }}
              control={
                <Radio sx={{ "&.Mui-checked": { color: "dark.main" } }} />
              }
              label="Oui"
            />
            <FormControlLabel
              value="non"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "700" } }}
              control={
                <Radio sx={{ "&.Mui-checked": { color: "dark.main" } }} />
              }
              label="Non"
            />
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ mt: 3 }}>
          <FormLabel
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.2rem",
            }}
          >
            Allez-vous solliciter un concours bancaire ? ou À combien
            évaluez-vous la part de votre emprunt ?
          </FormLabel>
          <RadioGroup
            row
            value={answers.partEmprunt}
            onChange={(e) => changeRadio(e, "partEmprunt")}
          >
            <FormControlLabel
              value="moins40"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "700" } }}
              control={
                <Radio sx={{ "&.Mui-checked": { color: "dark.main" } }} />
              }
              label="Moins de 40%"
            />
            <FormControlLabel
              value="50"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "700" } }}
              control={
                <Radio sx={{ "&.Mui-checked": { color: "dark.main" } }} />
              }
              label="50%"
            />
            <FormControlLabel
              value="80"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "700" } }}
              control={
                <Radio sx={{ "&.Mui-checked": { color: "dark.main" } }} />
              }
              label="80%"
            />
            <FormControlLabel
              value="100"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "700" } }}
              control={
                <Radio sx={{ "&.Mui-checked": { color: "dark.main" } }} />
              }
              label="100%"
            />
          </RadioGroup>
        </FormControl>
        <Typography color="primary.main" fontSize="0.9rem">
          *Choisissez la réponse la plus proche.
        </Typography>
        <FormControl sx={{ mt: 3 }}>
          <FormLabel
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.2rem",
            }}
          >
            Êtes-vous dans la région ?
          </FormLabel>
          <RadioGroup
            row
            value={answers.region}
            onChange={(e) => changeRadio(e, "region")}
          >
            <FormControlLabel
              value="oui"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "700" } }}
              control={
                <Radio sx={{ "&.Mui-checked": { color: "dark.main" } }} />
              }
              label="Oui"
            />
            <FormControlLabel
              value="non"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "700" } }}
              control={
                <Radio sx={{ "&.Mui-checked": { color: "dark.main" } }} />
              }
              label="Non, il s’agit d’une mutation"
            />
          </RadioGroup>
        </FormControl>
        <Typography color="primary.main" fontSize="0.9rem">
          *Avec cette question, nous voulons nous assurer que vous connaissez le
          coin ou se situe le bien.
        </Typography>
        <Typography
          sx={{
            mt: 3,
            color: "primary.main",
            fontWeight: "700",
            fontSize: "1.2rem",
          }}
        >
          Sélectionnez par ordre d’importance pour vous (3 choix) :
        </Typography>
        {options.map((option: any, index: number) => (
          <Box display="flex" key={index} alignItems="center" color="dark">
            <IconButton
              color="inherit"
              disabled={index === 0 ? true : false}
              onClick={() => changeOptionsOrder(index, "moins")}
              sx={{
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.18)",
                m: 1,
                mr: 0.2,
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              color="inherit"
              disabled={index === options.length - 1 ? true : false}
              onClick={() => changeOptionsOrder(index, "plus")}
              sx={{
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.18)",
                m: 1,
                mr: 2.5,
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              color="inherit"
              disableRipple={true}
              sx={{
                pr: 1.8,
                borderRadius: "38px",
                boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.18)",
                "&:hover": {
                  cursor: "default",
                },
              }}
            >
              <CircleIcon fontSize="small" />
              <Typography sx={{ ml: 1, fontWeight: "700" }}>
                {option}
              </Typography>
            </IconButton>
          </Box>
        ))}
        <FormControl sx={{ mt: 3 }}>
          <TextField
            sx={{
              "& label": { fontSize: "1rem" },
              "& fieldset > legend ": { fontSize: "calc(0.75 * 1.4rem)" },
              "& textarea": { fontSize: "1rem" },
            }}
            multiline
            rows={4}
            label="Un message pour le vendeur ?"
            variant="outlined"
            value={answers.message}
            onChange={(e) =>
              setAnswers(
                (state: {
                  nomComplet: string;
                  email: string;
                  dejaCredit: string;
                  accordBanque: string;
                  partEmprunt: string;
                  region: string;
                  message: string;
                }) => ({ ...state, message: e.target.value })
              )
            }
          />
        </FormControl>
        <Button
          disabled={disabled}
          variant="contained"
          sx={{ mt: 2, fontSize: "1.5rem" }}
          onClick={() => submit()}
        >
          Envoyer
        </Button>
      </Stack>
    </Box>
  );
};

export default QCM;
