import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { selectAllAnnonces } from "../../store/annonceSlice";
import {
  Theme,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  Chip,
  useTheme,
  OutlinedInput,
} from "@mui/material";

type Props = {
  localisation: string[];
  setLocalisation: Dispatch<SetStateAction<string[]>>;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
  name: string,
  localisation: readonly string[],
  theme: Theme
) {
  return {
    fontWeight:
      localisation.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const Localisation = ({ localisation, setLocalisation }: Props) => {
  const theme = useTheme();
  const annonces = useSelector(selectAllAnnonces);
  const [localisations, setLocalisations] = useState<string[]>([]);

  useEffect(() => {
    let local: string[] = [];
    annonces.forEach((annonce) => {
      if (!local.includes(annonce.data.data.informations.localisation.data)) {
        local.push(annonce.data.data.informations.localisation.data);
      }
    });
    setLocalisations(local);
  }, [annonces]);

  const handleChange = (event: SelectChangeEvent<typeof localisations>) => {
    const {
      target: { value },
    } = event;
    setLocalisation(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl sx={{ width: 250, height: "64.983px" }}>
      <InputLabel id="demo-multiple-chip-label">Localisation</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={localisation}
        onChange={handleChange}
        input={
          <OutlinedInput
            id="select-multiple-chip"
            label="Localisation"
            inputProps={{
              sx: {
                lineHeight: "2em",
              },
            }}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "no-wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {localisations.map((local) => (
          <MenuItem
            key={local}
            value={local}
            style={getStyles(local, localisation, theme)}
          >
            {local}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Localisation;
