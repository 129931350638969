import { createSlice } from "@reduxjs/toolkit";

export interface Pagination {
  currentPage: number;
  total: number;
  numberPerPage: number;
  totalPages: number;
}

const initialState: Pagination = {
  currentPage: 1,
  total: 0,
  numberPerPage: 9,
  totalPages: 1,
};

export const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
      const totalPages = action.payload / state.numberPerPage;
      if (totalPages < 1) {
        state.totalPages = 1;
      } else {
        state.totalPages = Math.ceil(totalPages);
      }
    },
    setNumberPerPage: (state, action) => {
      state.numberPerPage = action.payload;
      const totalPages = state.total / action.payload;
      if (totalPages < 1) {
        state.totalPages = 1;
      } else {
        state.totalPages = Math.ceil(totalPages);
      }
    },
  },
});

export const { setCurrentPage, setTotal, setNumberPerPage } =
  paginationSlice.actions;
export const selectPaginationProp = (prop: string) => (state: any) =>
  state.pagination[prop];

export default paginationSlice.reducer;
