import React from "react";
import { Typography, Button, Container } from "@mui/material";
import { stripeCheckout } from "../requests/amplify";
import { useDispatch } from "react-redux";
import { setMessage } from "../store/errorSuccessSlice";
import { setLoading } from "../store/uiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../store/authSlice";

type Props = {};

const PaymentRequired = (props: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const getStripeUrl = async () => {
    dispatch(setLoading({ isLoading: true }));
    const data = await stripeCheckout({ email: user.data.email, type: "site" });
    if (data !== "error") {
      const json = await (data as Response).json();
      dispatch(setLoading({ isLoading: false }));
      window.location.href = json.sessionId;
    } else {
      dispatch(
        setMessage({
          type: "error",
          message: "Une erreur s'est produite, veuillez réessayer",
        })
      );
    }
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 5, textAlign: "center" }}>
      <Typography textAlign="center" mb={6}>
        L'accès à notre service est payant, veuillez cliquer sur le bouton
        ci-dessous pour procéder au paiement
      </Typography>
      <Button
        variant="contained"
        onClick={() => getStripeUrl()}
        style={{ margin: "auto" }}
      >
        Procéder au paiement
      </Button>
    </Container>
  );
};

export default PaymentRequired;
