import {
  Box,
  Grid,
  Stack,
  Typography,
  IconButton,
  Button,
  Link as MuiLink,
  Container,
} from "@mui/material";
import React from "react";
import logo from "../images/direct-proprietaire-logo-noir-achat-immobilier.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link as NavLink } from "react-router-dom";
// import Image from "next/image";
// import Link from "next/link";

type Props = {};

const Footer = (props: Props) => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "dark.main",
        color: "white",
        p: 4,
        marginTop: "auto",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 1, sm: 2, md: 3 }}
          width="100%"
          mx="auto"
        >
          <Grid item xs={1}>
            <Stack
              direction="column"
              p={2}
              spacing={2}
              sx={{ alignItems: "left" }}
            >
              <img
                alt="Logo Direct Propriétaire"
                src={logo}
                // width={200}
                style={{ width: "200px" }}
              />
              <Typography
                variant="body2"
                component={NavLink}
                to="/cgv"
                sx={{
                  m: 4,
                  fontWeight: "400",
                  color: "white",
                  textDecoration: "none",
                  "&:hover": { color: "white", textDecoration: "none" },
                  "&:focus": { color: "white", textDecoration: "none" },
                }}
              >
                CGVU
              </Typography>
              <Typography
                variant="body2"
                component={NavLink}
                to="/politique-confidentialite"
                sx={{
                  m: 4,
                  fontWeight: "400",
                  color: "white",
                  textDecoration: "none",
                  "&:hover": { color: "white", textDecoration: "none" },
                  "&:focus": { color: "white", textDecoration: "none" },
                }}
              >
                Politique de confidentialité
              </Typography>
              <Typography
                variant="body2"
                component={NavLink}
                to="/mentions-legales"
                sx={{
                  m: 4,
                  fontWeight: "400",
                  color: "white",
                  textDecoration: "none",
                  "&:hover": { color: "white", textDecoration: "none" },
                  "&:focus": { color: "white", textDecoration: "none" },
                }}
              >
                Mentions légales
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={1}>
            <Stack direction="column" p={2} spacing={2}>
              <Typography variant="subtitle1" component="p">
                Informations
              </Typography>
              <Box color={"#FCFCFC78"}></Box>
              {/* <Typography
                variant="body2"
                component={NavLink}
                to="/#contactez-nous"
                sx={{
                  m: 4,
                  fontWeight: "400",
                  color: "white",
                  textDecoration: "none",
                  "&:hover": { color: "white", textDecoration: "none" },
                  "&:focus": { color: "white", textDecoration: "none" },
                }}
              >
                Nos sites en ligne
              </Typography> */}
              <Typography
                variant="body2"
                component={NavLink}
                to="/assistance"
                sx={{
                  m: 4,
                  fontWeight: "400",
                  color: "white",
                  textDecoration: "none",
                  "&:hover": { color: "white", textDecoration: "none" },
                  "&:focus": { color: "white", textDecoration: "none" },
                }}
              >
                Renseignements
              </Typography>
              <Typography
                variant="body2"
                component={NavLink}
                to="/blog"
                sx={{
                  m: 4,
                  fontWeight: "400",
                  color: "white",
                  textDecoration: "none",
                  "&:hover": { color: "white", textDecoration: "none" },
                  "&:focus": { color: "white", textDecoration: "none" },
                }}
              >
                Blog
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={1}>
            <Stack p={2} spacing={2}>
              <Typography variant="subtitle1" component="p">
                Contacts
              </Typography>
              <Stack direction="row" spacing={2} alignSelf="flex-start">
                <IconButton
                  sx={{
                    backgroundColor: "dark.main",
                    borderColor: "white",
                    border: 1,
                    borderRadius: 1,
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  href={"https://www.facebook.com/groups/directproprietaire"}
                  target="_blank"
                >
                  <FacebookIcon sx={{ color: "inherit" }} />
                </IconButton>

                {/* <IconButton
                  sx={{
                    backgroundColor: "dark.main",
                    borderColor: "white",
                    border: 1,
                    borderRadius: 1,
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  href={"#"}
                >
                  <InstagramIcon sx={{ color: "inherit" }} />
                </IconButton>

                <IconButton
                  sx={{
                    backgroundColor: "dark.main",
                    borderColor: "white",
                    border: 1,
                    borderRadius: 1,
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  href={"#"}
                >
                  <LinkedInIcon sx={{ color: "inherit" }} />
                </IconButton> */}
              </Stack>
              <Box>
                <Button
                  variant="outlined"
                  href="/#contactez-nous"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: 58,
                  }}
                >
                  Contactez-nous
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </Container>
    </Box>
  );
};

export default Footer;
