// import Link from "next/link";
import {
  AppBar,
  Box,
  Link as MuiLink,
  Toolbar,
  Button,
  IconButton,
  Typography,
  Drawer,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { disconnect, selectConnected } from "../store/authSlice";
import { useDispatch } from "react-redux";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import logo from "../images/direct-proprietaire-logo-blanc-achat-immobilier.png";
// import Image from "next/image";
import { Link } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MenuIcon from "@mui/icons-material/Menu";

type Props = {};

const Header = (props: Props) => {
  const connected = useSelector(selectConnected);
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "white", zIndex: 1 }}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
            my: 1.3,
          }}
        >
          <MuiLink component={Link} to="/">
            <Box maxWidth="300px">
              <img src={logo} width={"200px"} alt="logo Direct Propriétaire" />
            </Box>
          </MuiLink>
          <Box display={{ xs: "none", md: "flex" }} alignItems="center">
            <>
              <MuiLink
                variant="h5"
                component={Link}
                to="/annonces"
                sx={{
                  m: 2,
                  fontWeight: "400",
                  color: "dark.main",
                  textDecoration: "none",
                  "&:hover": { color: "dark.main", textDecoration: "none" },
                  "&:focus": { color: "dark.main", textDecoration: "none" },
                }}
              >
                Annonces
              </MuiLink>
              <MuiLink
                variant="h5"
                component={Link}
                to="/coaching"
                display="inline-block"
                sx={{
                  maxWidth: { md: "85px", lg: "200px" },
                  m: 2,
                  fontWeight: "400",
                  color: "dark.main",
                  textDecoration: "none",
                  "&:hover": { color: "dark.main", textDecoration: "none" },
                  "&:focus": { color: "dark.main", textDecoration: "none" },
                }}
              >
                Coaching Immobilier
              </MuiLink>
              <MuiLink
                variant="h5"
                component={Link}
                to="/blog"
                sx={{
                  m: 2,
                  fontWeight: "400",
                  color: "dark.main",
                  textDecoration: "none",
                  "&:hover": { color: "dark.main", textDecoration: "none" },
                  "&:focus": { color: "dark.main", textDecoration: "none" },
                }}
              >
                Blog
              </MuiLink>
              <MuiLink
                variant="h5"
                component={Link}
                to="/assistance"
                sx={{
                  m: 2,
                  fontWeight: "400",
                  color: "dark.main",
                  textDecoration: "none",
                  "&:hover": { color: "dark.main", textDecoration: "none" },
                  "&:focus": { color: "dark.main", textDecoration: "none" },
                }}
              >
                Renseignements
              </MuiLink>

              {!connected ? (
                <>
                  <IconButton
                    component={Link}
                    to="/connexion"
                    disableRipple={true}
                    sx={{
                      fontWeight: "400",
                      color: "dark.main",
                      textDecoration: "none",
                      mr: 2,
                      "&:hover": {
                        color: "dark.main",
                        textDecoration: "none",
                        backgroundColor: "inherit",
                      },
                      "&:focus": { color: "dark.main", textDecoration: "none" },
                    }}
                  >
                    <AccountCircleOutlinedIcon sx={{ mr: 1 }} />
                    <Typography variant="h5" sx={{ fontWeight: "400" }}>
                      Se connecter
                    </Typography>
                  </IconButton>
                </>
              ) : null}
            </>
            {connected ? (
              <>
                <MuiLink
                  variant="h5"
                  component={Link}
                  to="/tableau-de-bord"
                  sx={{
                    m: 2,
                    fontWeight: "400",
                    color: "dark.main",
                    textDecoration: "none",
                    "&:hover": { color: "dark.main", textDecoration: "none" },
                    "&:focus": { color: "dark.main", textDecoration: "none" },
                  }}
                >
                  Tableau de bord
                </MuiLink>
                <MuiLink
                  variant="h5"
                  component={Link}
                  to="/mon-compte"
                  sx={{
                    m: 2,
                    fontWeight: "400",
                    color: "dark.main",
                    textDecoration: "none",
                    "&:hover": { color: "dark.main", textDecoration: "none" },
                    "&:focus": { color: "dark.main", textDecoration: "none" },
                  }}
                >
                  Mon compte
                </MuiLink>
                <IconButton
                  sx={{ color: "dark.main" }}
                  onClick={() => {
                    dispatch(disconnect());
                    window.location.href = "/";
                  }}
                >
                  <PowerSettingsNewIcon color="inherit" />
                </IconButton>
              </>
            ) : null}
          </Box>
          <Box
            display={{ xs: "flex", md: "none" }}
            alignItems="center"
            justifyContent="flex-start"
          >
            <IconButton
              size="large"
              edge="start"
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#535A5D",
                textTransform: "uppercase",
                ml: 1,
              }}
              onClick={() => setOpenMenu(true)}
            >
              <MenuIcon />
              <Typography sx={{ fontSize: "12px" }}>Menu</Typography>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={"right"}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        sx={{
          zIndex: 499,
          width: 300,
          [`& .MuiDrawer-paper`]: {
            width: 300,
            boxSizing: "border-box",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={300}
          mt={10}
        >
          <MuiLink
            variant="h5"
            component={Link}
            to="/assistance"
            sx={{
              m: 2,
              fontWeight: "400",
              color: "dark.main",
              textDecoration: "none",
              "&:hover": { color: "dark.main", textDecoration: "none" },
              "&:focus": { color: "dark.main", textDecoration: "none" },
            }}
          >
            Renseignements
          </MuiLink>
          <MuiLink
            variant="h5"
            component={Link}
            to="/blog"
            sx={{
              m: 2,
              fontWeight: "400",
              color: "dark.main",
              textDecoration: "none",
              "&:hover": { color: "dark.main", textDecoration: "none" },
              "&:focus": { color: "dark.main", textDecoration: "none" },
            }}
          >
            Blog
          </MuiLink>
          {!connected ? (
            <>
              <IconButton
                component={Link}
                to="/connexion"
                disableRipple={true}
                sx={{
                  fontWeight: "400",
                  color: "dark.main",
                  textDecoration: "none",
                  mr: 2,
                  "&:hover": {
                    color: "dark.main",
                    textDecoration: "none",
                    backgroundColor: "inherit",
                  },
                  "&:focus": { color: "dark.main", textDecoration: "none" },
                }}
              >
                <AccountCircleOutlinedIcon sx={{ mr: 1 }} />
                <Typography variant="h5" sx={{ fontWeight: "400" }}>
                  Se connecter
                </Typography>
              </IconButton>
            </>
          ) : null}
        </Box>
        {connected ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={300}
          >
            <MuiLink
              variant="h5"
              component={Link}
              to="/tableau-de-bord"
              sx={{
                m: 2,
                fontWeight: "400",
                color: "dark.main",
                textDecoration: "none",
                "&:hover": { color: "dark.main", textDecoration: "none" },
                "&:focus": { color: "dark.main", textDecoration: "none" },
              }}
            >
              Tableau de bord
            </MuiLink>
            <MuiLink
              variant="h5"
              component={Link}
              to="/mon-compte"
              sx={{
                m: 2,
                fontWeight: "400",
                color: "dark.main",
                textDecoration: "none",
                "&:hover": { color: "dark.main", textDecoration: "none" },
                "&:focus": { color: "dark.main", textDecoration: "none" },
              }}
            >
              Mon compte
            </MuiLink>
            <IconButton
              sx={{ color: "dark.main" }}
              onClick={() => {
                dispatch(disconnect());
                window.location.href = "/";
              }}
            >
              <PowerSettingsNewIcon color="inherit" />
            </IconButton>
          </Box>
        ) : null}
      </Drawer>
    </>
  );
};

export default Header;
