import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl, Stack, Typography } from "@mui/material";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";

const options = [
  "none",
  "underline",
  "overline",
  "line-through",
  "underline overline",
  "underline line-through",
  "overline line-through",
  "overline line-through underline",
];

export default function TextDecorationLineInputSelect(props: any) {
  return (
    <FormControl size="small">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        {...props}
        renderValue={() => (
          <FormatUnderlinedIcon sx={{ verticalAlign: "text-bottom" }} />
        )}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Typography sx={{ textDecorationLine: option }}>
              Mon texte
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
