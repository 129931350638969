import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import PresentationImage from "./PresentationImage";
import PresentationText from "./PresentationText";
import { useDispatch } from "react-redux";
import { withSectionWrapper } from "../SectionWrapper";
import { withSectionItemWrapper } from "../SectionItemWrapper";
import { getPresentationItem } from "../../config";
import { setAnnonceProp } from "../../store/annonceSlice";
import { useLocation, useParams } from "react-router-dom";
// import { useRouter } from "next/router";

const SectionItem = ({
  layout,
  index,
  path,
  item,
}: {
  layout: string;
  index: number;
  path: string[];
  item: { images: any; description: any; more: any; showImageOnly: any };
}) => {
  console.log("SectionItem", { layout, index, path, item });
  // const { display = true } = item.image;
  const left =
    layout === "images-left" || (layout === "alternate" && index % 2 === 1) ? (
      <PresentationImage
        // src={item.image.data}
        path={[...path, "images"]}
        // layout={item.image.layout}
        // altText={item.image.altText}
        // filter={item.image.filter}
        images={item.images}
      />
    ) : (
      <PresentationText
        {...item.description}
        more={item?.more}
        path={[...path, "description"]}
      />
    );
  const right =
    layout === "images-right" || (layout === "alternate" && index % 2 === 0) ? (
      <PresentationImage
        // src={item.image.data}
        path={[...path, "images"]}
        // layout={item.image.layout}
        // altText={item.image.altText}
        // filter={item.image.filter}
        images={item.images}
      />
    ) : (
      <PresentationText
        {...item.description}
        more={item?.more}
        path={[...path, "description"]}
      />
    );
  // if (!!item.showImageOnly) {
  //   return (
  //     <Box padding="12px">
  //       <PresentationImage
  //         src={item.image.data}
  //         path={[...path, "image"]}
  //         layout={item.image.layout}
  //         altText={item.image.altText}
  //         filter={item.image.filter}
  //       />
  //     </Box>
  //   );
  // }
  return (
    <>
      {/* {display ? ( */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 2 }}>
        <Grid xs={1}>
          <div style={{ height: "100%" }}>{left}</div>
        </Grid>
        <Grid xs={1}>
          <div style={{ height: "100%" }}>{right}</div>
        </Grid>
      </Grid>
      {/* ) : (
        <Box padding="12px">
          <PresentationText
            more={item?.more}
            {...item.description}
            path={[...path, "description"]}
          />
        </Box>
      )} */}
    </>
  );
};

const FinalItem = withSectionItemWrapper(SectionItem);

const Presentation = (props: {
  data: {
    items: any[];
    layout?: string;
    respLayout?: boolean;
    backgroundColor?: string;
    display?: boolean;
  };
  pageIndex?: string;
  layout?: string;
}) => {
  const location = useLocation();
  // const annonceId = location.query.annonceId;
  const { annonceId } = useParams();
  const dispatch = useDispatch();
  const showAddItemButton = location.pathname.includes("/modification/");
  const theme = useTheme();
  const isSingleColumn = !useMediaQuery(theme.breakpoints.up("sm"));
  const respLayout = props.data?.respLayout ?? true;
  const displayedLayout =
    isSingleColumn && respLayout
      ? "images-right"
      : props.data?.layout ?? "alternate";
  const addItem = () =>
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", "presentation", "items"],
        value: [
          ...props.data.items,
          getPresentationItem(props.data.items.length + 1),
        ],
      })
    );
  console.log("Presentation", {
    isSingleColumn,
    displayedLayout,
    ...props,
  });
  if (
    !location.pathname.includes("/modification/") &&
    props.data.display !== undefined &&
    !props.data.display
  ) {
    return <></>;
  }
  return (
    <Box sx={{ p: 2 }}>
      <Grid mt={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1 }}>
        {props.data?.items.map((service: any, index: any) => (
          <Grid xs={1} key={index}>
            <FinalItem
              pageIndex={annonceId}
              path={["presentation", "items", index.toString()]}
              inputType="PresentationItemInput"
              defaultItemProps={{ backgroundColor: "transparent" }}
              itemProps={service.itemProps || {}}
              item={service}
              items={JSON.parse(JSON.stringify(props.data.items))}
              index={index}
              layout={displayedLayout}
            />
          </Grid>
        ))}
      </Grid>
      {showAddItemButton ? (
        <Box display="flex" justifyContent="center" pt={4}>
          <Button variant="contained" onClick={addItem}>
            Ajouter un élément
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default withSectionWrapper(Presentation);
