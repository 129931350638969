import axios from "axios";
import { formioRequest } from "./formioRequest";
import store from "../store/store";
import { disconnect } from "../store/authSlice";

export const getRdv = async (rdvId: string) => {
  let res;
  try {
    res = await formioRequest({
      url: `/650aee5a4a8a916f7a2dfff4/submission/${rdvId}`,
      method: "GET",
      headers: {
        "x-jwt-token": localStorage.getItem("token") as string,
      },
    });
    if (res.status === 200) {
      return res.data;
    } else if (res.status === 440) {
      store.dispatch(disconnect());
    } else {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};

export const getRdvsByOwner = async (ownerId: string) => {
  try {
    const res = await formioRequest({
      url: `/650aee5a4a8a916f7a2dfff4/submission?data.vendeurId=${ownerId}`,
      method: "GET",
      headers: {
        "x-jwt-token": localStorage.getItem("token") as string,
      },
    });
    if (res.status === 200) {
      return res.data;
    } else if (res.status === 440) {
      store.dispatch(disconnect());
    } else {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};
