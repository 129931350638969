import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser, setConnected } from "../../store/authSlice";
import { useDispatch } from "react-redux";
import { setMessage } from "../../store/errorSuccessSlice";
import { changeUserInfos } from "../../requests/user";
import {
  FormControl,
  TextField,
  Button,
  Stack,
  Box,
  Typography,
} from "@mui/material";

type Props = {};

const ChangeInfos = (props: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [infos, setInfos] = useState({ nom: "", email: "" });
  const [error, setError] = useState("");

  useEffect(() => {
    if (user !== undefined) {
      setInfos({ nom: user.data.nom, email: user.data.email });
    }
  }, [user]);

  const changeInfos = () => {
    setError("");
    const currentUser = JSON.parse(JSON.stringify(user));
    currentUser.data.nom = infos.nom;
    currentUser.data.email = infos.email;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (infos.email.match(mailformat)) {
      if (infos.nom.length < 3) {
        changeUserInfos(currentUser).then((res) => {
          if (res === "ok") {
            dispatch(setConnected(currentUser));
            dispatch(
              setMessage({
                message: "Modification enregistrée !",
                type: "success",
              })
            );
          } else {
            dispatch(
              setMessage({
                message: "Une erreur est survenue veuillez réessayer",
                type: "error",
              })
            );
          }
        });
      } else {
        setError("Veuillez renseigner un nom valide");
      }
    } else {
      setError("Veuillez renseigner une adresse email valide");
    }
  };

  return (
    <>
      <Stack spacing={2} mt={4}>
        {error ? <Typography>{error}</Typography> : null}
        <FormControl>
          <TextField
            value={infos.nom}
            onChange={(e) =>
              setInfos((state) => ({ ...state, nom: e.target.value }))
            }
            label="nom complet"
          />
        </FormControl>
        <FormControl>
          <TextField
            value={infos.email}
            onChange={(e) =>
              setInfos((state) => ({ ...state, email: e.target.value }))
            }
            label="email"
          />
        </FormControl>
        <Box>
          <Button onClick={changeInfos} variant="contained">
            Enregistrer
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default ChangeInfos;
