import { Container, Grid, Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Annonce, selectAllAnnonces } from "../store/annonceSlice";
import AnnonceCard from "../components/annonces/AnnonceCard";
import Localisation from "../components/annonces/Localisation";
import Pieces from "../components/annonces/Pieces";
import Chambres from "../components/annonces/Chambres";
import Prix from "../components/annonces/Prix";
import Surface from "../components/annonces/Surface";
import TypeDeBien from "../components/annonces/TypeDeBien";
import Pagination from "../components/Pagination";
import usePagination from "../hooks/usePagination";

type Props = {};

const Annonces = (props: Props) => {
  const annonces = useSelector(selectAllAnnonces);
  const [filteredAnnonce, setFilteredAnnonce] = useState<Annonce[]>([]);
  const [localisation, setLocalisation] = useState<string[]>([]);
  const [pieces, setPieces] = useState<string[]>([]);
  const [chambres, setChambres] = useState<string[]>([]);
  const [prixRange, setPrixRange] = useState<number[]>([0, 100000]);
  const [surface, setSurface] = useState<number[]>([0, 100]);
  const [typeDeBien, setTypeDeBien] = useState<string[]>([]);
  const wrapperRef = useRef(null);
  const displayedAnnonce = usePagination(filteredAnnonce);

  useEffect(() => {
    const localisationFilter = annonces.filter((annonce) =>
      localisation.length === 0
        ? true
        : localisation.includes(
            annonce.data.data.informations.localisation.data
          )
        ? true
        : false
    );

    const typeFilter = localisationFilter.filter((annonce) =>
      typeDeBien.length === 0
        ? true
        : typeDeBien.includes(annonce.data.data.informations.typeBien)
        ? true
        : false
    );

    const pieceFilter = typeFilter.filter((annonce) =>
      pieces.length === 0
        ? true
        : pieces.includes(
            annonce.data.data.informations.nombrePieces.data.toString()
          )
        ? true
        : false
    );

    const chambreFilter = pieceFilter.filter((annonce) =>
      chambres.length === 0
        ? true
        : chambres.includes(
            annonce.data.data.informations.nombreChambres.data.toString()
          )
        ? true
        : false
    );

    const prixRangeFilter = chambreFilter.filter(
      (annonce) =>
        prixRange[0] <= annonce.data.data.informations.prix.data &&
        annonce.data.data.informations.prix.data <= prixRange[1]
    );

    const surfaceFilter = prixRangeFilter.filter(
      (annonce) =>
        surface[0] <= annonce.data.data.informations.surface.data &&
        annonce.data.data.informations.surface.data <= surface[1]
    );

    setFilteredAnnonce(surfaceFilter);
  }, [localisation, pieces, chambres, prixRange, surface, typeDeBien]);

  return (
    <Container maxWidth="xl">
      {/* tri */}
      <Box display={"flex"} p={2}>
        <Localisation
          localisation={localisation}
          setLocalisation={setLocalisation}
        />
        <TypeDeBien typeDeBien={typeDeBien} setTypeDeBien={setTypeDeBien} />
        <Pieces pieces={pieces} setPieces={setPieces} />
        <Chambres chambres={chambres} setChambres={setChambres} />
        <Prix prixRange={prixRange} setPrixRange={setPrixRange} />
        <Surface surface={surface} setSurface={setSurface} />
      </Box>
      {/* annonce Card */}
      <Grid
        ref={wrapperRef}
        container
        columns={{ lg: 3, xs: 1, sm: 2 }}
        spacing={4}
      >
        {displayedAnnonce?.map((annonce) => (
          <AnnonceCard key={annonce._id} annonce={annonce} />
        ))}
      </Grid>
      <Pagination
        data={annonces}
        props={{
          sx: {
            "& .MuiPaginationItem-root:hover, & .MuiPaginationItem-root.Mui-selected, , & .MuiPaginationItem-root.Mui-selected:hover":
              {
                backgroundColor: "transparent",
                color: "primary.main",
              },
            "& .MuiPaginationItem-root": {
              fontWeight: "500",
            },
          },
          hideNextButton: true,
          hidePrevButton: true,
        }}
        wrapper={wrapperRef}
      />
    </Container>
  );
};

export default Annonces;
