import { useEffect, useState } from "react";
import Presentation from "../../components/AnnonceComponents/Presentation";
import HeroSection from "../../components/AnnonceComponents/HeroSection";
import Informations from "../../components/AnnonceComponents/Informations";
import { selectAnnonceById } from "../../store/annonceSlice";
import { useSelector } from "react-redux";
import { EntityId } from "@reduxjs/toolkit";
import QCM from "../../components/QCM";
import { Box, Paper, Backdrop, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";
// import { useRouter } from "next/router";

type Props = {
  // pageProps: any;
  // site: any;
};

const Annonce = (props: Props) => {
  const [interested, setInterested] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const pageState = useSelector((state: any) =>
    selectAnnonceById(state, annonceId as EntityId)
  );

  return pageState ? (
    <>
      <HeroSection
        sectionProps={pageState.data.data.hero}
        {...pageState.data.data.hero}
        refAnnonce={pageState.data.ref}
      />
      <Presentation
        anchor="qui-sommes-nous"
        data={pageState.data.data.presentation}
        sectionProps={pageState.data.data.presentation}
        defaultSectionProps={{ backgroundColor: "white" }}
      />
      <Informations
        anchor="informations"
        data={pageState.data.data.informations}
        sectionProps={pageState.data.data.informations}
        setInterested={setInterested}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          // overflow: "auto",
        }}
        open={interested}
      >
        <Paper
          sx={{
            px: 3,
            pb: 3,
            width: "80%",
            my: 2,
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            position="sticky"
            top="0"
            pt={1}
            sx={{ backgroundColor: "#fff", zIndex: "10000000" }}
          >
            <ClearIcon
              onClick={() => setInterested(false)}
              sx={{ "&:hover": { cursor: "pointer" } }}
            />
          </Box>
          <QCM
            owner={pageState.owner}
            annonceId={pageState?._id}
            // buyer={user?._id}
            setInterested={setInterested}
            setAlreadyExists={setAlreadyExists}
          />
        </Paper>
      </Backdrop>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          // overflow: "auto",
        }}
        open={alreadyExists}
      >
        <Paper
          sx={{
            px: 3,
            pb: 3,
            width: "80%",
            my: 2,
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            position="sticky"
            top="0"
            pt={1}
            sx={{ backgroundColor: "#fff", zIndex: "10000000" }}
          >
            <ClearIcon
              onClick={() => setAlreadyExists(false)}
              sx={{ "&:hover": { cursor: "pointer" } }}
            />
          </Box>
          <Typography textAlign="center">
            Vous avez déjà envoyé ces informations au vendeur
          </Typography>
        </Paper>
      </Backdrop>
    </>
  ) : null;
};

export default Annonce;
