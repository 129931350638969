import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectAnnonceProp, setAnnonceProp } from "../../store/annonceSlice";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useParams } from "react-router-dom";
// import { useRouter } from "next/router";

type Props = {
  data: string[];
  path: string[];
  label: string;
};

const SelectInput = ({ data, path, label }: Props) => {
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const dispatch = useDispatch();
  const props: any = useSelector(selectAnnonceProp(path.slice(1), {}));

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1)],
        value: event.target.value,
      })
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props}
        label={label}
        onChange={handleChange}
      >
        {data.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
