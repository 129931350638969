import { areIntervalsOverlapping, differenceInMinutes } from "date-fns";

export const createDispos = (
  creneaux: { date: string; heureDebut: string; heureFin: string }[]
) => {
  let dispos: any[] = [];
  creneaux?.forEach((c: any, index: number) => {
    let startDate = new Date(c.date);

    startDate.setHours(c.heureDebut?.split(":")[0]);
    startDate.setMinutes(c.heureDebut?.split(":")[1]);

    let endDate = new Date(c.date);

    endDate.setHours(c.heureFin?.split(":")[0]);
    endDate.setMinutes(c.heureFin?.split(":")[1]);

    dispos.push({
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      creneauIndex: index,
    });
  });

  return dispos;
};

export const createRdvs = (raw: any, source: string) => {
  let rdvs: any[] = [];
  raw?.forEach((rdv: any) => {
    const startDate = new Date(rdv.data.debut);
    const endDate = new Date(rdv.data.fin);
    rdvs.push({
      id: rdvs.length + 1,
      allDay: false,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      title:
        source === "vendeur"
          ? `Visite ${rdv.data.type === "virtuelle" ? "visio" : "sur place"}`
          : "Indisponible",
      color: "#ddd",
      borderColor: "#ddd",
      textColor: "#000",
      disponible: false,
      extendedProps: {
        rdvId: rdv._id,
      },
    });
  });
  return rdvs;
};

export const createEvents = (
  dispos: any,
  rdvs: any,
  duree: { hours: number; minutes: number },
  type: "vendeur" | "acheteur"
) => {
  let events: any[] = [...rdvs];

  dispos?.forEach((dispo: any) => {
    const alreadyExists = events.some((event: any) =>
      areIntervalsOverlapping(
        { start: new Date(dispo.start), end: new Date(dispo.end) },
        { start: new Date(event.start), end: new Date(event.end) }
      )
    );

    if (!alreadyExists) {
      events.push({
        id: events.length + 1,
        allDay: false,
        start: new Date(dispo.start).toISOString(),
        end: new Date(dispo.end).toISOString(),
        title: "Disponible",
        color: "#9dbedf",
        borderColor: "#9dbedf",
        textColor: "#000",
        disponible: true,
        extendedProps: {
          creneauIndex: dispo.creneauIndex,
        },
      });
    } else {
      const filter = events.filter((event: any) =>
        areIntervalsOverlapping(
          { start: new Date(dispo.start), end: new Date(dispo.end) },
          { start: new Date(event.start), end: new Date(event.end) }
        )
      );
      filter.sort(
        (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
      );
      // if visit start !== provider availibity
      if (
        new Date(dispo.start).getTime() !== new Date(filter[0].start).getTime()
      ) {
        const minutes = differenceInMinutes(
          new Date(filter[0].start).getTime(),
          new Date(dispo.start).getTime()
        );
        const hours = minutes / 60;
        if (type === "acheteur") {
          //if enough time for a visit before the one scheduled
          if (
            parseFloat(duree.hours + "." + duree.minutes) <=
            parseFloat(hours + "." + (minutes - hours * 60))
          ) {
            events.push({
              id: events.length + 1,
              allDay: false,
              start: new Date(dispo.start).toISOString(),
              end: new Date(filter[0].start).toISOString(),
              title: "Disponible",
              color: "#9dbedf",
              borderColor: "#9dbedf",
              textColor: "#000",
              disponible: true,
            });
          }
        } else if (minutes >= 15) {
          events.push({
            id: events.length + 1,
            allDay: false,
            start: new Date(dispo.start).toISOString(),
            end: new Date(filter[0].start).toISOString(),
            title: "Disponible",
            color: "#9dbedf",
            borderColor: "#9dbedf",
            textColor: "#000",
            disponible: true,
            extendedProps: {
              creneauIndex: dispo.creneauIndex,
            },
          });
        }
      }

      // if visit end !== provider availibity
      if (
        new Date(dispo.end).getTime() !== new Date(filter.at(-1).end).getTime()
      ) {
        const minutes = differenceInMinutes(
          new Date(dispo.end).getTime(),
          new Date(filter.at(-1).end).getTime()
        );
        const hours = minutes / 60;
        if (type === "acheteur") {
          //if enough time for a visit before the one scheduled
          if (
            parseFloat(duree.hours + "." + duree.minutes) <=
            parseFloat(hours + "." + (minutes - hours * 60))
          ) {
            events.push({
              id: events.length + 1,
              allDay: false,
              start: new Date(filter.at(-1).end).toISOString(),
              end: new Date(dispo.end).toISOString(),
              title: "Disponible",
              color: "#9dbedf",
              borderColor: "#9dbedf",
              textColor: "#000",
              disponible: true,
            });
          }
        } else if (minutes >= 15) {
          events.push({
            id: events.length + 1,
            allDay: false,
            start: new Date(filter.at(-1).end).toISOString(),
            end: new Date(dispo.end).toISOString(),
            title: "Disponible",
            color: "#9dbedf",
            borderColor: "#9dbedf",
            textColor: "#000",
            disponible: true,
            extendedProps: {
              creneauIndex: dispo.creneauIndex,
            },
          });
        }
      }

      // if time between 2 visits
      filter.forEach((event: any, index: number) => {
        const minutes = differenceInMinutes(
          new Date(filter[index + 1]?.start),
          new Date(event.end)
        );
        const hours = minutes / 60;
        if (type === "acheteur") {
          if (
            parseFloat(duree.hours + "." + duree.minutes) <=
            parseFloat(hours + "." + (minutes - hours * 60))
          ) {
            events.push({
              id: events.length + 1,
              allDay: false,
              start: new Date(event.end).toISOString(),
              end: new Date(filter[index + 1]?.start).toISOString(),
              title: "Disponible",
              color: "#9dbedf",
              borderColor: "#9dbedf",
              textColor: "#000",
              disponible: true,
            });
          }
        } else if (minutes >= 15) {
          events.push({
            id: events.length + 1,
            allDay: false,
            start: new Date(event.end).toISOString(),
            end: new Date(filter[index + 1]?.start).toISOString(),
            title: "Disponible",
            color: "#9dbedf",
            borderColor: "#9dbedf",
            textColor: "#000",
            disponible: true,
            extendedProps: {
              creneauIndex: dispo.creneauIndex,
            },
          });
        }
      });
    }
  });

  return events;
};
