import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import serreMain from "../images/coaching/Hero-Image-Coaching.png";
import pointsForts from "../images/coaching/points-forts.png";
import planActions from "../images/coaching/plan-actions.png";
import demarches from "../images/coaching/demarches.png";
import coach from "../images/coaching/Photo-coach.png";
import whatsApp from "../images/coaching/whatsapp.png";
import visio from "../images/coaching/visioconference.png";
import micro from "../images/coaching/microphone.png";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import TwitterIcon from "react-share/lib/TwitterIcon";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../store/uiSlice";
import { stripeCheckout } from "../requests/amplify";
import { setMessage } from "../store/errorSuccessSlice";

type Props = {};

const Coaching = (props: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const getStripeUrl = async (type: "1h" | "2h" | "3h" | "4h" | "5h") => {
    dispatch(setLoading({ isLoading: true }));
    const data = await stripeCheckout({ type });
    if (data !== "error") {
      const json = await (data as Response).json();
      dispatch(setLoading({ isLoading: false }));
      window.location.href = json.sessionId;
    } else {
      dispatch(
        setMessage({
          type: "error",
          message: "Une erreur s'est produite, veuillez réessayer",
        })
      );
    }
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ my: 2 }}>
        <Box
          display="flex"
          alignItems={"center"}
          sx={{ py: 6 }}
          flexDirection={{ xs: "column-reverse", sm: "row" }}
        >
          <Box
            flex={1}
            display={"flex"}
            flexDirection="column"
            style={{ gap: "24px" }}
            sx={{ pr: { xs: 0, sm: 10 } }}
          >
            <Typography
              variant="h1"
              style={{ fontSize: "3rem" }}
              textAlign={{ xs: "center", sm: "left" }}
            >
              Coaching Immobilier
            </Typography>
            <Typography variant="body2" textAlign="justify">
              L'objectif d'une séance de coaching immobilier est de vous accompagner dans votre démarche de vente d'un bien immobilier, afin d'accélérer le délai de transaction immobilière et de vendre dans les meilleures conditions de marché possibles.
            </Typography>
            <Typography variant="body2" textAlign="justify">
              La séance de coaching peut consister par exemple à la rédaction de votre annonce immobilière, la mise en valeur des points forts et qualités de votre bien (la localisation, les prestations, les commodités, la proximité des transports en commun, les extérieurs : présence d'un balcon, terrasse, etc...), et également identifier les faiblesses de votre vente immobilière (trop d'encombrement des pièces, des réparations et travaux à effectuer, une absence d'extérieur, une estimation trop haute, etc...).
            </Typography>
          </Box>
          <Box flex={1} sx={{ maxWidth: { xs: "50%", sm: "100%" } }}>
            <img src={serreMain} alt="" style={{ width: "100%" }} />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent={"space-between"}
          sx={{ gap: "20px", py: 6 }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box flex={1} textAlign={{ xs: "center", sm: "left" }}>
            <Box width="124px" sx={{ mx: { xs: "auto", sm: 0 } }}>
              <img src={pointsForts} alt="" style={{ width: "100%" }} />
            </Box>
            <Box>
              <Typography variant="subtitle2" component="p">
                Identifier les points forts
              </Typography>
              <Typography variant="body2">
                Diagnostic des points forts et des faiblesses du bien immobilier
                en vente
              </Typography>
            </Box>
          </Box>
          <Box flex={1} textAlign={{ xs: "center", sm: "left" }}>
            <Box width="124px" sx={{ mx: { xs: "auto", sm: 0 } }}>
              <img src={demarches} alt="" style={{ width: "100%" }} />
            </Box>
            <Box>
              <Typography variant="subtitle2" component="p">
                État des démarches
              </Typography>
              <Typography variant="body2">
                État des démarches de vente accomplies
              </Typography>
            </Box>
          </Box>
          <Box flex={1} textAlign={{ xs: "center", sm: "left" }}>
            <Box width="124px" sx={{ mx: { xs: "auto", sm: 0 } }}>
              <img src={planActions} alt="" style={{ width: "100%" }} />
            </Box>
            <Box>
              <Typography variant="subtitle2" component="p">
                Plan d’actions
              </Typography>
              <Typography variant="body2">
                Avis, et recommandations d'actions à mener pour accélerer la
                vente du bien immobilier
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          sx={{ py: 6 }}
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box flex={1}>
            <img src={coach} alt="" style={{ width: "100%" }} />
          </Box>
          <Box flex={{ xs: 1, md: 2 }} sx={{ pl: { md: 20, sm: 5, xs: 0 } }}>
            <Typography
              variant="h1"
              component="p"
              style={{ fontSize: "3rem" }}
              textAlign={{ xs: "center", sm: "left" }}
            >
              Notre Coach Immobilier
            </Typography>
            <Typography variant="body2" textAlign="justify">
              Je suis Raynald HAAG, coach immobilier, je serai là pour vous accompagner dans toutes les étapes de votre vente immobilière. Je mettrai à votre disposition toute mon énergie et mes compétences de professionnel expérimenté de l'immobilier (20 ans d'expérience) pour accélérer votre transaction immobilière. Je suis également blogueur immobilier, vous pouvez consulter mes articles sur ce site Internet.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ py: 6 }}>
          <Typography
            variant="h2"
            component="p"
            textAlign="center"
            style={{ fontSize: "2.5rem" }}
          >
            Forfait séance de coaching immobilier
          </Typography>

          <Typography
            variant="body2"
            textAlign="center"
            mt={1.5}
            maxWidth="795px"
            sx={{ mx: "auto", pb: 6 }}
          >
            Les forfaits des séances de coaching suivants peuvent être
            fractionnés en plusieurs fois par exemple 2 fois 1H ou 3 fois 1H
          </Typography>
          <Box
            display={"flex"}
            justifyContent={{ md: "space-between", xs: "center" }}
            sx={{ gap: "20px", rowGap: "40px" }}
            flexWrap={"wrap"}
          >
            <Box
              flex={1}
              minWidth={"151px"}
              maxWidth={{ xs: "170px", sm: "192px", md: "500px" }}
            >
              <Typography
                component="span"
                sx={{
                  backgroundColor: "primary.main",
                  color: "#FFF",
                  fontStyle: "italic",
                  mb: 1,
                  px: 0.5,
                }}
              >
                Forfait séance
              </Typography>
              <Typography sx={{ pt: 1 }} style={{ fontSize: "1.3rem" }}>
                1h de coaching immobilier à 199€
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  padding: "6px 8px",
                  mt: 1,
                  fontSize: "0.9rem",
                  fontWeight: "500",
                }}
                onClick={() => getStripeUrl("1h")}
              >
                Réserver
              </Button>
            </Box>
            <Box
              flex={1}
              minWidth={"151px"}
              maxWidth={{ xs: "170px", sm: "192px", md: "500px" }}
            >
              <Typography
                component="span"
                sx={{
                  backgroundColor: "primary.main",
                  color: "#FFF",
                  fontStyle: "italic",
                  mb: 1,
                  px: 0.5,
                }}
              >
                Forfait séance
              </Typography>
              <Typography sx={{ pt: 1 }} style={{ fontSize: "1.3rem" }}>
                2h de coaching immobilier à 299€
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  padding: "6px 8px",
                  mt: 1,
                  fontSize: "0.9rem",
                  fontWeight: "500",
                }}
                onClick={() => getStripeUrl("2h")}
              >
                Réserver
              </Button>
            </Box>
            <Box
              flex={1}
              minWidth={"151px"}
              maxWidth={{ xs: "170px", sm: "192px", md: "500px" }}
            >
              <Typography
                component="span"
                sx={{
                  backgroundColor: "primary.main",
                  color: "#FFF",
                  fontStyle: "italic",
                  mb: 1,
                  px: 0.5,
                }}
              >
                Forfait séance
              </Typography>
              <Typography sx={{ pt: 1 }} style={{ fontSize: "1.3rem" }}>
                3h de coaching immobilier à 399€
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  padding: "6px 8px",
                  mt: 1,
                  fontSize: "0.9rem",
                  fontWeight: "500",
                }}
                onClick={() => getStripeUrl("3h")}
              >
                Réserver
              </Button>
            </Box>
            <Box
              flex={1}
              minWidth={"151px"}
              maxWidth={{ xs: "170px", sm: "192px", md: "500px" }}
            >
              <Typography
                component="span"
                sx={{
                  backgroundColor: "primary.main",
                  color: "#FFF",
                  fontStyle: "italic",
                  mb: 1,
                  px: 0.5,
                }}
              >
                Forfait séance
              </Typography>
              <Typography sx={{ pt: 1 }} style={{ fontSize: "1.3rem" }}>
                4h de coaching immobilier à 499€
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  padding: "6px 8px",
                  mt: 1,
                  fontSize: "0.9rem",
                  fontWeight: "500",
                }}
                onClick={() => getStripeUrl("4h")}
              >
                Réserver
              </Button>
            </Box>
            <Box
              flex={1}
              minWidth={"151px"}
              maxWidth={{ xs: "170px", sm: "192px", md: "500px" }}
            >
              <Typography
                component="span"
                sx={{
                  backgroundColor: "primary.main",
                  color: "#FFF",
                  fontStyle: "italic",
                  mb: 1,
                  px: 0.5,
                }}
              >
                Forfait séance
              </Typography>
              <Typography sx={{ pt: 1 }} style={{ fontSize: "1.3rem" }}>
                5h de coaching immobilier à 599€
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  padding: "6px 8px",
                  mt: 1,
                  fontSize: "0.9rem",
                  fontWeight: "500",
                }}
                onClick={() => getStripeUrl("5h")}
              >
                Réserver
              </Button>
            </Box>
          </Box>
          <Box textAlign="center" pt={10}>
            <Button
              href="/#contactez-nous"
              sx={{
                color: "#FFF",
                borderRadius: "48px",
                backgroundColor: "primary.dark",
                padding: "24px 28px",
                fontSize: "1.2rem",
                "&:hover": {
                  color: "primary.dark",
                  borderColor: "primary.dark",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            >
              Réservez dès maintenant
            </Button>
          </Box>
        </Box>
        <Box mt={4} textAlign={"right"}>
          <Typography>Partager sur :</Typography>
          <LinkedinShareButton
            url={`https://www.direct-proprietaire.fr${location.pathname}`}
          >
            <LinkedinIcon size={40} round={true} />
          </LinkedinShareButton>
          <FacebookShareButton
            url={`https://www.direct-proprietaire.fr${location.pathname}`}
          >
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>
          <TwitterShareButton
            url={`https://www.direct-proprietaire.fr${location.pathname}`}
          >
            <TwitterIcon size={40} round={true} bgStyle={{ fill: "#0f1419" }} />
          </TwitterShareButton>
        </Box>
      </Container>
      <Box sx={{ backgroundColor: "primary.main", pt: 6, pb: 4 }}>
        <Box maxWidth={"md"} sx={{ mx: "auto", p: 2 }}>
          <Typography
            color="white"
            textAlign={"center"}
            fontSize={"1.5rem"}
            fontWeight="400"
            pb={2}
          >
            Les séances de coaching immobilier sont réalisées à distance et
            exclusivement par téléphone, visioconférence, whatsApp
          </Typography>
          <Box display={"flex"} justifyContent={"center"} sx={{ gap: "40px" }}>
            <Box width="80px">
              <img src={micro} alt="" style={{ width: "100%" }} />
            </Box>
            <Box width="80px">
              <img src={visio} alt="" style={{ width: "100%" }} />
            </Box>
            <Box width="80px">
              <img src={whatsApp} alt="" style={{ width: "100%" }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Coaching;
