import {
  Alert,
  Box,
  Collapse,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import { useHover } from "usehooks-ts";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HeightIcon from "@mui/icons-material/Height";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
// import { useRouter } from "next/router";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { selectUiProp, setSelected } from "../store/uiSlice";
import { selectAnnonceProp, setAnnonceProp } from "../store/annonceSlice";
import { useLocation } from "react-router-dom";

const widthMenuItems = (handleClose: any, maxWidth: string) =>
  [
    ["Largeur maximale du contenu"],
    ["xs", "444 pixels"],
    ["sm", "600 pixels"],
    ["md", "900 pixels"],
    ["lg", "1200 pixels"],
    ["xl", "1536 pixels"],
    [false, "Pleine largeur"],
  ].map(([w, label], index) =>
    index === 0 ? (
      <MenuItem key={(w || label) as string} disabled>
        {w}
      </MenuItem>
    ) : (
      <MenuItem
        key={(w || label) as string}
        selected={maxWidth === w}
        onClick={handleClose(w)}
      >
        {label}
      </MenuItem>
    )
  );

const backgroundColorMenuItems = (
  handleClose: any,
  getPaletteColor: any,
  backgroundColor: any
) =>
  [
    ["Couleur de fond"],
    ["transparent", "Transparent"],
    ["white", "Blanc"],
    ["black"],
    ["text.primary"],
    ["text.secondary"],
    ["background.default"],
    ["primary.dark"],
    ["primary.main"],
    ["primary.light"],
  ].map(([c, label], index) =>
    index === 0 ? (
      <MenuItem key={c} disabled>
        {c}
      </MenuItem>
    ) : label ? (
      <MenuItem
        key={c}
        selected={backgroundColor === c}
        onClick={handleClose(c)}
      >{`${backgroundColor === c ? "✓ " : ""}${label}`}</MenuItem>
    ) : (
      <MenuItem
        key={c}
        color={c}
        onClick={handleClose(c)}
        selected={backgroundColor === c}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            width: "100%",
          }}
        >
          {backgroundColor === c ? <CheckRoundedIcon /> : null}
          <Box
            sx={{
              width: "100%",
              height: 24,
            }}
            style={{
              backgroundColor: getPaletteColor(c),
              color: getPaletteColor(c),
            }}
          />
        </Stack>
      </MenuItem>
    )
  );

const toRGB = (rgba: any) => {
  const [r, g, b, a] = rgba.slice(5, -1).split(",");
  return `rgb(${(255 - r) * (1 - a)},${(255 - g) * (1 - a)},${
    (255 - b) * (1 - a)
  })`;
};

type OwnProps = {
  children: any;
  backgroundColor?: string;
  pageIndex?: string;
  path?: string[];
  inputType?: string;
  sectionProps?: any;
  defaultSectionProps?: any;
  anchor: string;
  contentScale: number;
};

type Props = OwnProps & {
  handleSectionSelect: any;
  sectionProps: any;
  pageId: any;
  setSectionDisplay: any;
  setSectionMaxWidth: any;
  setSectionFill: any;
};

const SectionWrapper = (props: Props) => {
  const {
    children,
    handleSectionSelect,
    sectionProps,
    setSectionDisplay,
    setSectionMaxWidth,
    setSectionFill,
    inputType,
    anchor,
    contentScale,
    pageIndex,
  } = props;
  const { backgroundColor = "transparent", isSelected } = sectionProps;
  const hoverRef = useRef(null);
  const theme = useTheme();
  const isHover = useHover(hoverRef);
  const [menu, setMenu] = useState("");
  console.log("SectionWrapper", props);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const router = useRouter();
  const location = useLocation();

  if (!location.pathname.includes("/modification/") || !inputType) {
    return (
      <Box
        sx={{
          backgroundColor,
        }}
      >
        {anchor ? (
          <a
            className={
              !location.pathname.includes("/modification/")
                ? "custom_anchor"
                : "anchor"
            }
            id={anchor}
          ></a>
        ) : null}
        <Container disableGutters maxWidth={sectionProps.maxWidth}>
          {children}
        </Container>
      </Box>
    );
  }

  if (
    !location.pathname.includes("/modification/") &&
    sectionProps?.display === false
  ) {
    return null;
  }

  const getPaletteColor = (color: string) => {
    switch (color) {
      case "background.default":
        return theme.palette.background.default;
      case "primary.main":
        return theme.palette.primary.main;
      case "primary.light":
        return theme.palette.primary.light;
      case "primary.dark":
        return theme.palette.primary.dark;
      case "text.primary":
        return toRGB(theme.palette.text.primary);
      case "text.secondary":
        return toRGB(theme.palette.text.secondary);
      case "black":
      case "white":
        return theme.palette.common[color];
    }
  };

  const open = Boolean(anchorEl);
  const handleMenuClick =
    (menu: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenu(menu);
      setAnchorEl(event.currentTarget);
    };
  const handleClose = (value: any) => () => {
    menu === "width" &&
      value !== undefined &&
      setSectionMaxWidth(pageIndex, value);
    menu === "fill" && value !== undefined && setSectionFill(pageIndex, value);
    setAnchorEl(null);
  };
  console.log({ anchor, sectionProps });
  return (
    <Box
      ref={hoverRef}
      sx={{
        backgroundColor,
        position: "relative",
        borderColor:
          isHover || sectionProps.display === false || isSelected
            ? "primary.main"
            : backgroundColor,
        borderWidth:
          isHover || sectionProps.display === false || isSelected ? 2 : 0,
        borderStyle: "dashed",
        minHeight: 42,
      }}
    >
      {anchor ? (
        <a
          className={
            !location.pathname.includes("/modification/")
              ? "custom_anchor"
              : "anchor"
          }
          id={anchor}
        ></a>
      ) : null}
      <Container disableGutters maxWidth={sectionProps.maxWidth}>
        {children}
      </Container>
      {sectionProps.display === false ? (
        <Box
          sx={{
            bottom: 0,
            width: "100%",
            backgroundColor: "rgba(255,255,255,0.80)",
            position: "absolute",
            top: 0,
            p: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            transform: `scale(${1 / contentScale})`,
            transformOrigin: "50% 50%",
          }}
        >
          <Alert severity="info">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Cette section est désactivée et n'apparaîtra pas sur votre site
          </Alert>
        </Box>
      ) : null}
      {isHover || open || sectionProps.display === false ? (
        <Stack
          direction="row"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            height: 40,
            color: "white",
            transform: `scale(${1 / contentScale})`,
            transformOrigin: "top right",
          }}
        >
          <Collapse
            in={sectionProps.display !== false}
            orientation="horizontal"
          >
            <IconButton
              sx={{
                borderRadius: 0,
                backgroundColor: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "inherit",
                },
              }}
              color="white"
              onClick={handleMenuClick("fill")}
            >
              <FormatColorFillIcon />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: 0,
                backgroundColor: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "inherit",
                },
              }}
              color="white"
              onClick={handleMenuClick("width")}
            >
              <HeightIcon sx={{ transform: "rotate(90deg)" }} />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose(undefined)}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              {menu === "width"
                ? widthMenuItems(handleClose, sectionProps.maxWidth)
                : backgroundColorMenuItems(
                    handleClose,
                    getPaletteColor,
                    sectionProps.backgroundColor
                  )}
            </Menu>
            <IconButton
              sx={{
                borderRadius: 0,
                backgroundColor: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "inherit",
                },
              }}
              color="white"
              onClick={() => {
                handleSectionSelect();
                //   router.push(`#${anchor}`);
              }}
            >
              <EditIcon />
            </IconButton>
          </Collapse>
          <IconButton
            sx={{
              borderRadius: 0,
              backgroundColor: "primary.dark",
              "&:hover": {
                backgroundColor: "primary.light",
                color: "inherit",
              },
            }}
            color="white"
            onClick={() => {
              setSectionDisplay(pageIndex, sectionProps.display === false);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Stack>
      ) : null}
    </Box>
  );
};

const mapDispatchToProps = (
  dispatch: any,
  { path, inputType, pageIndex }: OwnProps
) => {
  const location = window.location;
  return location.pathname.includes("/modification/") && path
    ? {
        handleSectionSelect: () => dispatch(setSelected({ path, inputType })),
        setSectionDisplay: (pageId: string, value: boolean) =>
          dispatch(
            setAnnonceProp({
              id: pageId,
              path: ["data", ...path, "display"],
              value: value,
            })
          ),
        setSectionMaxWidth: (pageId: string, value: boolean) =>
          dispatch(
            setAnnonceProp({
              id: pageId,
              path: ["data", ...path, "maxWidth"],
              value: value,
            })
          ),
        setSectionFill: (pageId: string, value: string) =>
          dispatch(
            setAnnonceProp({
              id: pageId,
              path: ["data", ...path, "backgroundColor"],
              value: value,
            })
          ),
      }
    : {};
};

const definedProps = (obj: {}) =>
  Object.fromEntries(Object.entries(obj).filter(([_k, v]) => v !== undefined));

const mapStateToProps = (state: any, props: OwnProps) => {
  console.log("SectionWrapper - mapStateToProps", { props });
  const { display, maxWidth, backgroundColor } = props.path
    ? (selectAnnonceProp(props.path, {})(state) as any)
    : props.sectionProps;
  console.log(`read props for path ${props.path} :`, {
    display,
    maxWidth,
    backgroundColor,
  });
  const userSectionProps = definedProps({ display, maxWidth, backgroundColor });
  const { defaultSectionProps = {} } = props;
  const contentScale = selectUiProp("contentScale")(state) as number;
  // const pageId = selectCurrentPageId(state);
  // console.log("SectionWrapper", { userSectionProps });
  return {
    //   pageId,
    contentScale,
    sectionProps: {
      display: true,
      maxWidth: false,
      backgroundColor: "transparent",
      ...defaultSectionProps,
      ...userSectionProps,
      ...(props.path
        ? {
            isSelected:
              state.ui.selected?.path.join(".") === props.path.join("."),
          }
        : {}),
    },
  };
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(SectionWrapper);

export const withSectionWrapper =
  (Component: any) =>
  // eslint-disable-next-line react/display-name
  ({
    pageIndex,
    path,
    inputType,
    sectionProps,
    defaultSectionProps,
    anchor,
    contentScale,
    ...rest
  }: any) =>
    (
      // @ts-ignore
      <Connected
        {...{
          pageIndex,
          path,
          inputType,
          sectionProps,
          anchor,
          defaultSectionProps,
          contentScale,
        }}
      >
        <Component {...rest} {...{ pageIndex }} />
      </Connected>
    );

export default Connected;
