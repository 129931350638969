import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { Box, FormControl, Popover, Popper, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { useState } from 'react';

const labels = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p'
]

export default function TypoComponentInputSelect(props: SelectProps<Variant>) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    console.log({ open })
    return (
        <FormControl size="small">
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ maxWidth: 70 }}
                {...props}
                renderValue={(value) => <>
                    <Typography onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose} variant={value} style={{ fontSize: 21.67 }}>{value}</Typography>
                    <Popper open={open} anchorEl={anchorEl} placement='top-start' sx={{ zIndex: 1000 }}>
                        <Box sx={{ border: 1, p: 1, bgcolor: 'background.default', mb: 1 }}>
                            Sémantique du texte
                        </Box>
                    </Popper>
                </>}
            >
                {labels.map((key) => <MenuItem key={key} value={key}><Typography variant={key as Variant}>{key}</Typography></MenuItem>)}
            </Select>
        </FormControl>
    );
}

