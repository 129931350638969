"use client";
import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import image from "../images/direct-proprietaire-contact-commercial-achat-immobilier.png";
import { useDispatch } from "react-redux";
import { setMessage as setAlertMessage } from "../store/errorSuccessSlice";
// import Image from "next/image";

type Props = {};
const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const openApiURL =
  "https://48heklt6di.execute-api.eu-west-1.amazonaws.com/dev/directmail/contact";

const Contact = (props: Props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const sendEmail = (props: { message: string; name: string; email: string }) =>
    fetch(openApiURL, {
      method: "POST",
      body: JSON.stringify({
        message: props.message,
        email: props.email,
        name: props.name,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            setAlertMessage({ type: "success", message: "Message envoyé !" })
          );
          setName("");
          setEmail("");
          setMessage("");
        } else {
          dispatch(
            setAlertMessage({
              type: "error",
              message: "Une erreur s'est produite",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setAlertMessage({
            type: "error",
            message: "Une erreur s'est produite",
          })
        );
      });

  let disabled = !email.match(mailformat) || !name || !message;
  return (
    <Box id="contactez-nous" sx={{ pt: 8 }}>
      <Container maxWidth="xl" sx={{ display: "flex" }}>
        <Box
          display="flex"
          width="100%"
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Box
            flex={1}
            width="100%"
            // p={2}
            pr={{ xs: 0, md: 6 }}
            sx={{ alignSelf: "center" }}
          >
            {/* <Grid container columns={{ xs: 1, md: 2 }} spacing={4}>
        <Grid item xs={1}> */}
            <Stack
              direction="column"
              spacing={2}
              sx={{
                backgroundColor: "white",
                p: 5,
                borderRadius: 4,
                mb: 10,
                // ml: { xs: 4, md: 6 },
                // mr: { xs: 4, md: 0 },
              }}
            >
              <TextField
                autoComplete="name"
                required
                label="Nom"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                autoComplete="email"
                inputMode="email"
                required
                label="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                required
                label="Message"
                multiline
                minRows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Box textAlign="center">
                <Button
                  variant="outlined"
                  disabled={disabled}
                  onClick={() => sendEmail({ message, email, name })}
                  sx={{
                    fontSize: "1.2rem",
                    display: "inline",
                    borderRadius: "48px",
                  }}
                >
                  Envoyer
                </Button>
              </Box>
            </Stack>
          </Box>
          <Box flex={1} ml={2}>
            {/* <Grid item xs={1}> */}
            <Stack
              spacing={2}
              sx={{ mb: 2 }}
              textAlign={{ xs: "center", md: "left" }}
              // sx={{ height: "100%", ml: { xs: 2, md: 0 } }}
            >
              <Box>
                <Typography variant="h3" component="h2" color="primary" pb={2}>
                  Une question ?
                </Typography>
                <Typography variant="h3" component="h2">
                  N’hésitez-pas à nous contacter !
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "70%" },
                  position: "relative",
                  minHeight: 200,
                  height: "60%",
                  // m: { xs: "auto" },
                }}
              >
                <img
                  src={image}
                  alt="contact"
                  style={{ width: "45%" }}
                  // fill
                  // style={{ objectFit: "contain" }}
                />
              </Box>
            </Stack>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;
