// import Image from "next/image";
import HeroSection from "../components/HeroSection";
import PourquoiCreer from "../components/PourquoiCreer";
import Steps from "../components/Steps";
import CreerSiteMaintenant from "../components/CreerSiteMaintenant";
import Contact from "../components/Contact";
import scenario1 from "../images/direct-proprietaire-immobilier-scenario-1B.png";
import scenario2 from "../images/direct-proprietaire-immobilier-scenario-2.png";
import scenario3 from "../images/direct-proprietaire-immobilier-scenario-3.png";
import scenario4 from "../images/direct-proprietaire-immobilier-scenario-4.png";
import scenario5 from "../images/direct-proprietaire-immobilier-scenario-5.png";
import scenario6 from "../images/direct-proprietaire-immobilier-scenario-6.png";
import SectionPrix from "../components/SectionPrix";

export default function Home() {
  const steps = [
    {
      titre:
        "1. Nous mettons à votre disposition un site temporaire dont vous pourrez communiquer l'adresse sur le boncoin et les réseaux sociaux",
      description:
        "Mettez-y des photos, un descriptif du bien, les différentes commodités et avantages du bien en vente (école, forêt, centre commercial, zone d'activité)",
      image: scenario1,
      alt: "illustration leboncoin vers herve.immo",
    },
    {
      titre:
        "2. Construisez un questionnaire qui sera soumis à l'ensemble des locataires ou acheteurs",
      image: scenario2,
      alt: "illustration questionnaire herve.immo",
    },
    {
      titre:
        "3. Des documents peuvent être échangés via le site sans jamais donner son email",
      image: scenario3,
      alt: "illustration échange de documents",
    },
    {
      titre:
        "4. Programmez automatiquement un rdv dans le calendrier de vos disponibilités en fonction des résultats de ce QCM",
      image: scenario4,
      alt: "illustration prise de rendez-vous",
    },
    {
      titre:
        "5. Pour ensuite échanger par visio le jour J et faire visiter via votre téléphone toujours sans faire circuler vos données (mails, numéro de teléphone et adresse ).",
      image: scenario5,
      alt: "illustration visite du bien en visio",
    },
    {
      titre:
        "6. Après un délai de réflexion de part et d'autre, un agenda de visite sur place aura lieu",
      image: scenario6,
      alt: "illustration visite du bien sur place",
    },
  ];
  return (
    <main>
      <HeroSection />
      <PourquoiCreer />
      <Steps steps={steps} />
      <SectionPrix />
      <Contact />
    </main>
  );
}
