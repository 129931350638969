import { FormControl, Menu, MenuItem, Button } from "@mui/material";
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { selectAllAnnonces } from "../../store/annonceSlice";
import Slider from "@mui/material/Slider";

type Props = {
  prixRange: number[];
  setPrixRange: Dispatch<SetStateAction<number[]>>;
};

const Prix = ({ prixRange, setPrixRange }: Props) => {
  const annonces = useSelector(selectAllAnnonces);
  const [maxPrice, setMaxPrice] = useState(100000);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    let max = 0;
    annonces.forEach((annonce) => {
      if (annonce.data.data.informations.prix.data > max) {
        max = annonce.data.data.informations.prix.data;
      }
    });
    setMaxPrice(max);
  }, [annonces]);

  useEffect(() => {
    setPrixRange((state) => [state[0], maxPrice]);
  }, [maxPrice]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setPrixRange(newValue as number[]);
  };

  return (
    <FormControl sx={{ width: 250 }}>
      <Button
        onClick={handleClick}
        disableRipple={true}
        sx={{
          height: "64.983px",
          backgroundColor: "white",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: !!!anchorEl ? "#0000003b" : "primary",
          borderRadius: "4px",
          "&:hover": {
            borderColor: "#000",
            backgroundColor: "white",
          },
          justifyContent: "flex-start",
          padding: "16.5px 32px 16.5px 14px",
          color: "#00000099",
          fontWeight: "700",
          fontSize: "1rem",
        }}
      >
        Prix : {Intl.NumberFormat("fr-FR").format(prixRange[0])}€ -{" "}
        {Intl.NumberFormat("fr-FR").format(prixRange[1])}€
      </Button>

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          disableRipple={true}
          disableTouchRipple={true}
          sx={{
            minWidth: "300px",
            "&:hover": {
              backgroundColor: "transparent",
            },
            cursor: "default",
          }}
        >
          <Slider
            value={prixRange}
            onChange={handleChange}
            valueLabelDisplay="on"
            max={maxPrice}
            min={0}
            step={5000}
            sx={{ width: "85%", mt: 5, ml: 2 }}
          />
        </MenuItem>
      </Menu>
    </FormControl>
  );
};

export default Prix;
