import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import image from "../images/direct-proprietaire-rapide-achat-immobilier-contact.png";

type Props = {};

const Assistance = (props: Props) => {
  return (
    <Container maxWidth="lg" sx={{ my: 2 }}>
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
        <Box flex={1}>
          <img src={image} alt="" style={{ width: "100%" }} />
        </Box>
        <Stack spacing={2} sx={{ flex: 1, alignSelf: "center" }}>
          <Typography variant="h1">Assistance</Typography>
          <Box>
            <Typography variant="body1" color="primary">
              Téléphone :
            </Typography>
            <Typography variant="subtitle1" component="p" fontWeight={"400"}>
              06 18 16 20 26
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="primary">
              Email :
            </Typography>
            <Typography variant="subtitle1" component="p" fontWeight={"400"}>
              raynald.haag@gmail.com
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default Assistance;
