import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import { ThemeProvider } from "@mui/material";
import themes from "./theme";
import App from "./App";
import createEmotionCache from "./createEmotionCache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";

// if (localStorage.getItem("formioToken")) {
//   const formio = new Formio(
//     `${Formio.getProjectUrl()}/form/64a8152a05a248a308dfbd6d`
//   );
//   formio.canSubmit().then((canSubmit: any) => {
//     if (canSubmit) {
//       store.dispatch(setConnected(Formio.getUser()));
//     }
//   });
// }
const emotionCache = createEmotionCache();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
console.log(themes.default);
root.render(
  // <React.StrictMode>
  <CacheProvider value={emotionCache}>
    <Provider store={store}>
      <ThemeProvider theme={themes.default}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </CacheProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
