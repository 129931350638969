import React, { useEffect, useRef, useState } from "react";
import {
  delArticle,
  getArticles,
  postArticle,
  putArticle,
} from "../../requests/articles";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../store/errorSuccessSlice";
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  useTheme,
  Backdrop,
  Paper,
  Typography,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import sanitizeHtml from "sanitize-html";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { deleteDoc, renderDoc, uploadImage } from "../../requests/s3";
import ArticleCard from "./ArticleCard";
import { selectAllArticles, setAllArticles } from "../../store/articleSlice";
import Pagination from "../Pagination";
import useArticles from "../../hooks/useArticles";
import ClearIcon from "@mui/icons-material/Clear";

type Props = {};

const Articles = (props: Props) => {
  const dispatch = useDispatch();
  const articles = useSelector(selectAllArticles);
  const displayArticles = useArticles();
  const [action, setAction] = useState<null | "add" | "update" | "delete">(
    null
  );
  const [article, setArticle] = useState<{
    titre: string;
    content: string;
    image: any;
  }>({ titre: "", content: "", image: "" });
  const [articleId, setArticleId] = useState<null | string>();
  const [file, setFile] = useState<any>([]);
  const wrapperRef = useRef(null);
  const theme = useTheme();
  const primaryMain = theme.palette.primary.main;
  const primaryLight = theme.palette.primary.light;
  const primaryDark = theme.palette.primary.dark;
  const textPrimary = theme.palette.text.primary;
  const textSecondary = theme.palette.text.secondary;

  const colors = [
    "000000",
    "",
    textPrimary.replace("#", ""),
    "",
    textSecondary.replace("#", ""),
    "",
    "ffffff",
    "",
    primaryDark.replace("#", ""),
    "",
    primaryMain.replace("#", ""),
    "",
    primaryLight.replace("#", ""),
    "",
  ];

  useEffect(() => {
    if (articles.length === 0) {
      getArticles().then((res) => {
        if (res !== "error") {
          dispatch(setAllArticles(res));
        } else {
          dispatch(
            setMessage({ type: "error", message: "Une erreur s'est produite" })
          );
        }
      });
    }
  }, [articles]);

  useEffect(() => {
    if (article.image) {
      setFile([{ ...article.image }]);
    }
  }, [article]);

  const handleRichEditorChange = (event: any) => {
    setArticle((state) => ({
      ...state,
      content: sanitizeHtml(event, {
        allowedAttributes: {
          span: ["style"],
          p: ["style"],
          li: ["style"],
          h1: ["style"],
          h2: ["style"],
          h3: ["style"],
          h4: ["style"],
          h5: ["style"],
          h6: ["style"],
        },
        parseStyleAttributes: false,
      }),
    }));
  };

  const save = () => {
    const array = article.titre
      .split(/[-\s'?!.,;:()"]/g)
      .filter((str) => str.length > 3);
    let slug = array.join("-");
    slug = slug.replaceAll("é", "e");
    slug = slug.replaceAll("è", "e");
    slug = slug.replaceAll("ê", "e");
    slug = slug.replaceAll("à", "a");
    slug = slug.replaceAll("â", "a");
    slug = slug.replaceAll("ù", "u");
    let image;

    if (article.titre === "") {
      dispatch(
        setMessage({ type: "error", message: "Le titre est obligatoire" })
      );
      return;
    }
    if (article.image === "") {
      dispatch(
        setMessage({ type: "error", message: "L'image est obligatoire" })
      );
      return;
    }

    if (article.image.imageUrl !== undefined && article.image.imageUrl !== "") {
      if (article.image.imageUrl.startsWith("https://")) {
        image = {
          ...article.image,
          imageUrl: `direct-proprietaire/blog/${article.image.name}`,
        };
      } else {
        image = { ...article.image };
      }
    } else {
      image = {
        ...article.image,
        imageUrl: `direct-proprietaire/blog/${article.image.name}`,
      };
    }

    if (action === "add") {
      postArticle({
        slug,
        titre: article.titre,
        content: article.content,
        image: image,
      }).then((res) => {
        if (res !== "error") {
          dispatch(
            setMessage({ type: "success", message: "Article enregistré !" })
          );
          setAction(null);
          setArticle({ titre: "", content: "", image: "" });
          setArticleId(null);
          setFile([]);
          dispatch(setAllArticles([]));
        } else {
          dispatch(
            setMessage({ type: "error", message: "Une erreur s'est produite" })
          );
        }
      });
    } else if (action === "update") {
      putArticle({
        data: {
          slug,
          titre: article.titre,
          content: article.content,
          image: image,
        },
        articleId: articleId as string,
      }).then((res) => {
        if (res !== "error") {
          dispatch(
            setMessage({ type: "success", message: "Article enregistré !" })
          );
          setAction(null);
          setArticle({ titre: "", content: "", image: "" });
          setArticleId(null);
          setFile([]);
          dispatch(setAllArticles([]));
        } else {
          dispatch(
            setMessage({ type: "error", message: "Une erreur s'est produite" })
          );
        }
      });
    }
  };

  const updateFiles = async (incommingFiles: any) => {
    setFile(incommingFiles);

    const res = await uploadImage({
      fileName: incommingFiles[0].file.name,
      file: incommingFiles[0],
      type: "blog",
    });

    setArticle((state) => ({
      ...state,
      image: { ...incommingFiles[0], imageUrl: "" },
    }));
  };

  const removeFile = (id: string | number | undefined) => {
    const fileToDelete = file.filter((x: any) => x.id === id);
    if (fileToDelete[0].imageUrl !== undefined) {
      let filePath = fileToDelete[0].imageUrl.split("amazonaws.com/");
      filePath = filePath[1].split("?");
      deleteDoc(filePath[0]);
    } else {
      deleteDoc(fileToDelete[0].url);
    }
    setFile(file.filter((x: any) => x.id !== id));
    setArticle((state) => ({ ...state, image: "" }));
  };

  const deleteArticle = () => {
    delArticle(articleId as string).then((res) => {
      if (res !== "error") {
        removeFile(article.image.id);
        dispatch(setAllArticles([]));
        dispatch(
          setMessage({ type: "success", message: "Article supprimé !" })
        );
        setAction(null);
        setArticleId(null);
        setArticle({ titre: "", content: "", image: "" });
      } else {
        dispatch(
          setMessage({ type: "error", message: "Une erreur s'est produite" })
        );
      }
    });
  };

  return (
    <>
      <Box sx={{ mb: 4 }}>
        {action === null ? (
          <>
            <Button variant="contained" onClick={() => setAction("add")}>
              Nouvel article
            </Button>
            <Grid
              ref={wrapperRef}
              container
              columns={{ md: 3, xs: 1, sm: 2 }}
              spacing={4}
              sx={{ mt: 2 }}
            >
              {displayArticles?.map((article: any) => (
                <ArticleCard
                  article={article.data}
                  setArticle={setArticle}
                  setAction={setAction}
                  key={article._id}
                  setArticleId={setArticleId}
                  id={article._id}
                />
              ))}
            </Grid>
            <Pagination
              data={articles}
              props={{
                sx: {
                  "& .MuiPaginationItem-root:hover, & .MuiPaginationItem-root.Mui-selected, , & .MuiPaginationItem-root.Mui-selected:hover":
                    {
                      backgroundColor: "transparent",
                      color: "primary.main",
                    },
                  "& .MuiPaginationItem-root": {
                    fontWeight: "500",
                  },
                },
                hideNextButton: true,
                hidePrevButton: true,
              }}
              wrapper={wrapperRef}
            />
          </>
        ) : null}
        {action === "add" || action === "update" ? (
          <>
            <FormControl sx={{ width: "100%", mb: 2 }}>
              <TextField
                label="Titre de l'article"
                fullWidth
                value={article.titre}
                onChange={(e) =>
                  setArticle((state) => ({ ...state, titre: e.target.value }))
                }
              />
            </FormControl>
            <Dropzone
              style={{ marginBottom: "16px" }}
              onChange={updateFiles}
              value={file}
              maxFiles={1}
              accept="image/*, video/*"
              localization="FR-fr"
              header={false}
              footer={false}
              label="Déposez vos fichiers ici, ou cliquez pour parcourir"
            >
              {file.map((f: any, index: number) => {
                return (
                  <FileMosaic key={f.id} {...f} preview onDelete={removeFile} />
                );
              })}
            </Dropzone>
            <Editor
              tinymceScriptSrc="/assets/libs/tinymce/tinymce.min.js"
              value={article.content}
              onEditorChange={handleRichEditorChange}
              init={{
                statusbar: false,
                menubar: false,
                plugins: "lists",
                toolbar: [
                  "bold italic underline alignleft aligncenter alignright alignjustify bullist numlist forecolor h2 h3 h4 h5 h6",
                ],
                language: "fr_FR",
                language_url: "/assets/libs/tinymce/fr_FR.js",
                color_map_foreground: colors,
                color_cols_foreground: 4,
                custom_colors: false,
              }}
            />
            <Box my={4} textAlign={"right"}>
              <Button
                variant="contained"
                onClick={() => {
                  setAction(null);
                  setArticle({ titre: "", content: "", image: "" });
                  setArticleId(null);
                }}
                sx={{ mr: 2 }}
              >
                Annuler
              </Button>
              <Button variant="contained" onClick={() => save()}>
                Enregistrer
              </Button>
            </Box>
          </>
        ) : null}
      </Box>
      <Backdrop
        open={action === "delete" ? true : false}
        sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
      >
        <Paper sx={{ p: 4 }}>
          <Box display="flex" justifyContent="flex-end">
            <ClearIcon
              onClick={() => {
                setAction(null);
                setArticleId(null);
                setArticle({ titre: "", content: "", image: "" });
              }}
              sx={{ "&:hover": { cursor: "pointer" } }}
            />
          </Box>
          <Typography my={4}>
            Êtes-vous sûr de vouloir supprimer cet article ?
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Button
              onClick={() => {
                setAction(null);
                setArticleId(null);
                setArticle({ titre: "", content: "", image: "" });
              }}
            >
              Annuler
            </Button>
            <Button onClick={() => deleteArticle()}>Supprimer</Button>
          </Box>
        </Paper>
      </Backdrop>
    </>
  );
};

export default Articles;
