import React, { useEffect, useState } from "react";
import { getRdv } from "../../requests/rdv";
import { Typography, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

type RdvFormatted = {
  date: string;
  heureDebut: string;
  heureFin: string;
};

type Rdv = {
  id: string;
  fin: string;
  debut: string;
  type: "virtuelle" | "sur place";
  acheteurId: string;
  vendeurId: string;
};

const Historique = ({
  created,
  message,
  visio,
  interest,
  remarques,
  dateRetour,
  surplace,
  dispoVirtuelle,
  demandeRetour,
  dispoSurplace,
}: {
  created: string;
  message: string;
  visio: string | Rdv;
  interest: string;
  remarques: string;
  dateRetour?: string;
  surplace: string | Rdv;
  dispoVirtuelle?: boolean;
  demandeRetour?: boolean;
  dispoSurplace?: boolean;
}) => {
  const [rdvVisio, setRdvVisio] = useState<RdvFormatted>();
  const [rdvSurplace, setRdvSurplace] = useState<RdvFormatted>();
  const [visioTime, setVisioTime] = useState<number>(0);
  const [surplaceTime, setSurplaceTime] = useState<number>(0);

  useEffect(() => {
    if (visio !== "" && typeof visio !== "string") {
      const { rdvFormatted, time } = formatRdv(visio);
      setRdvVisio(rdvFormatted);
      setVisioTime(time);
    }
  }, [visio]);

  useEffect(() => {
    if (surplace !== "" && typeof surplace !== "string") {
      const { rdvFormatted, time } = formatRdv(surplace);
      setRdvSurplace(rdvFormatted);
      setSurplaceTime(time);
    }
  }, [surplace]);

  const formatRdv = (rdv: Rdv) => {
    const date = new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(new Date(rdv.debut));
    const heureDebut = new Date(rdv.debut).toLocaleString("fr-FR", {
      timeZone: "Europe/Paris",
      hour: "2-digit",
      minute: "2-digit",
    });
    const heureFin = new Date(rdv.fin).toLocaleString("fr-FR", {
      timeZone: "Europe/Paris",
      hour: "2-digit",
      minute: "2-digit",
    });

    const timeDate = new Date(date);
    timeDate.setHours(parseInt(heureFin.split(":")[0] as string));
    timeDate.setMinutes(parseInt(heureFin.split(":")[1] as string));

    return {
      rdvFormatted: { date, heureDebut, heureFin },
      time: timeDate.getTime(),
    };
  };

  return (
    <Box fontSize={"0.9rem"} flex={{ xs: 1, md: 6 }} mt={{ xs: 4, md: 0 }}>
      <Box display="flex">
        <CheckCircleOutlineIcon
          color="primary"
          sx={{ mr: 2, opacity: "0.6" }}
        />
        <Box>
          <Typography
            fontSize="inherit"
            color="primary"
            mb={1.2}
            sx={{ opacity: "0.6" }}
          >
            Demande envoyée le{" "}
            {new Intl.DateTimeFormat("fr-FR", {
              day: "numeric",
              month: "long",
              year: "numeric",
            }).format(new Date(created))}
          </Typography>

          {message ? (
            <Typography variant="body2" fontSize="inherit" mb={1.2}>
              <Typography
                component="span"
                color="primary"
                fontWeight="700"
                fontSize="inherit"
              >
                Message :{" "}
              </Typography>
              {message}
            </Typography>
          ) : null}
          {!rdvVisio && dispoVirtuelle ? (
            <Typography>
              Vous avez envoyé vos disponibilités pour une visite en visio
            </Typography>
          ) : null}
        </Box>
      </Box>
      {rdvVisio ? (
        <Box display="flex" mb={1.2}>
          {visioTime < new Date().getTime() ? (
            <CheckCircleOutlineIcon color="primary" sx={{ mr: 2 }} />
          ) : (
            <RadioButtonUncheckedIcon sx={{ mr: 2, color: "#C850C0" }} />
          )}
          <Typography
            fontSize="inherit"
            sx={{
              color:
                visioTime < new Date().getTime() ? "primary.main" : "#C850C0",
            }}
          >
            Visite par visio programmée le {rdvVisio.date} de{" "}
            {rdvVisio.heureDebut.split(":")[0]}h
            {rdvVisio.heureDebut.split(":")[1]} à{" "}
            {rdvVisio.heureFin.split(":")[0]}h{rdvVisio.heureFin.split(":")[1]}
          </Typography>
        </Box>
      ) : null}
      {demandeRetour && !dateRetour ? (
        <Typography>
          Vous avez envoyé une demande de retour suite à la visite en visio
        </Typography>
      ) : null}

      {dateRetour ? (
        <Box display="flex">
          <CheckCircleOutlineIcon color="primary" sx={{ mr: 2 }} />
          <Box>
            <Typography color="primary" fontSize="inherit" mb={1.2}>
              Retour envoyé le{" "}
              {new Intl.DateTimeFormat("fr-FR", {
                day: "numeric",
                month: "long",
                year: "numeric",
              }).format(new Date(dateRetour))}
            </Typography>
            <Typography variant="body2" fontSize="inherit" mb={1.2}>
              <Typography
                component="span"
                color="primary"
                fontWeight="700"
                fontSize="inherit"
              >
                Toujours intéressé :{" "}
              </Typography>
              {interest}
            </Typography>
            <Typography variant="body2" fontSize="inherit" mb={1.2}>
              <Typography
                component="span"
                color="primary"
                fontWeight="700"
                fontSize="inherit"
              >
                Remarques :{" "}
              </Typography>
              {remarques}
            </Typography>
          </Box>
        </Box>
      ) : null}
      {!rdvSurplace && dispoSurplace ? (
        <Typography>
          Vous avez envoyé vos disponibilités pour une visite sur place
        </Typography>
      ) : null}

      {rdvSurplace ? (
        <Box display="flex">
          {surplaceTime < new Date().getTime() ? (
            <CheckCircleOutlineIcon color="primary" sx={{ mr: 2 }} />
          ) : (
            <RadioButtonUncheckedIcon sx={{ mr: 2, color: "#C850C0" }} />
          )}
          <Typography
            fontSize="inherit"
            sx={{
              color:
                surplaceTime < new Date().getTime()
                  ? "primary.main"
                  : "#C850C0",
            }}
          >
            Visite sur place programmée le {rdvSurplace.date} de{" "}
            {rdvSurplace.heureDebut.split(":")[0]}h
            {rdvSurplace.heureDebut.split(":")[1]} à{" "}
            {rdvSurplace.heureFin.split(":")[0]}h
            {rdvSurplace.heureFin.split(":")[1]}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default Historique;
