import { useDispatch, useSelector } from "react-redux";
import { selectPaginationProp } from "../store/paginationSlice";
import { selectAllArticles, setAllArticles } from "../store/articleSlice";
import { useEffect, useState } from "react";
import { renderDoc } from "../requests/s3";
import { addSeconds, parseISO } from "date-fns";

const useArticles = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector(selectPaginationProp("currentPage"));
  const numberPerPage = useSelector(selectPaginationProp("numberPerPage"));
  const articles = useSelector(selectAllArticles);
  const [displayArticles, setDisplayArticles] = useState<any[]>([]);

  useEffect(() => {
    if (articles) {
      const arr = articles.slice(
        (currentPage - 1) * numberPerPage,
        currentPage * numberPerPage
      );
      if (arr.length > 0) {
        if (
          arr.some((art) => !art.data.image.imageUrl.startsWith("https://")) // if no aws url for at least one of the images, get all the urls for the 6 articles
        ) {
          getImages(arr).then((response) => {
            const result = arr.map((article: any) => {
              const filter = response.find((r) => r.id === article.data.slug);
              let art = JSON.parse(JSON.stringify(article));
              art.data.image = { ...art.data.image, imageUrl: filter.image };
              return art;
            });
            let updatedArticles = JSON.parse(JSON.stringify(articles));
            updatedArticles = updatedArticles.toSpliced(
              (currentPage - 1) * numberPerPage,
              numberPerPage,
              ...result
            );
            dispatch(
              setAllArticles(JSON.parse(JSON.stringify(updatedArticles)))
            );
            setDisplayArticles(result);
          });
        } else {
          if (arr.some((art) => checkExpire(art.data.image.imageUrl))) {
            newUrl(arr).then((response) => {
              const result = arr.map((article: any) => {
                const filter = response.find((r) => r.id === article.data.slug);
                let art = JSON.parse(JSON.stringify(article));
                art.data.image = { ...art.data.image, imageUrl: filter.image };
                return art;
              });
              let updatedArticles = JSON.parse(JSON.stringify(articles));
              updatedArticles = updatedArticles.toSpliced(
                (currentPage - 1) * numberPerPage,
                numberPerPage,
                ...result
              );
              dispatch(
                setAllArticles(JSON.parse(JSON.stringify(updatedArticles)))
              );
              setDisplayArticles(result);
            });
          } else {
            setDisplayArticles(arr);
          }
        }
      } else {
        setDisplayArticles(arr);
      }
    }
  }, [articles, currentPage, numberPerPage]);

  const getImages = async (articles: any) => {
    const result = await Promise.all(
      articles.map(async (article: any) => {
        const res = await renderDoc(article.data.image.imageUrl);
        const buffer = {
          id: article.data.slug,
          image: (res as { url: string; data: string }).url,
        };
        return buffer;
      })
    );

    return result;
  };
  const checkExpire = (url: string) => {
    const params = new URLSearchParams(url);
    const creationDate = parseISO(params.get("X-Amz-Date") as string);
    const expiresInSecs = Number(params.get("X-Amz-Expires") as string);

    const expiryDate = addSeconds(creationDate, expiresInSecs);
    const isExpired = expiryDate < new Date();
    return isExpired;
  };

  const newUrl = async (articles: any) => {
    const result = await Promise.all(
      articles.map(async (article: any) => {
        const res = await renderDoc(
          `direct-proprietaire/blog/${article.data.image.name}`
        );
        const buffer = {
          id: article.data.slug,
          image: (res as { url: string; data: string }).url,
        };
        return buffer;
      })
    );
    return result;
  };

  return displayArticles;
};

export default useArticles;
