import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import {
  Box,
  Backdrop,
  Paper,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Container,
  Grid,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { areIntervalsOverlapping, differenceInMinutes } from "date-fns";
import { getDisponibilites, getRdvs, newRdv } from "../../requests/amplify";
import { setLoading } from "../../store/uiSlice";
import { setMessage } from "../../store/errorSuccessSlice";
import { useParams, useSearchParams } from "react-router-dom";
import {
  createDispos,
  createEvents,
  createRdvs,
} from "../../util/createEvents";
import { getOneAnnonce } from "../../requests/annonces";
// import { useRouter } from "next/router";

type Props = {};

const CalendrierDispos = (props: Props) => {
  // const router = useRouter();
  // const vendeurId = router.query.vendeurId;
  const [searchParams] = useSearchParams();
  const { vendeurId } = useParams();
  const dispatch = useDispatch();
  const [raw, setRaw] = useState<any>();
  const [dispos, setDispos] = useState<any>();
  const [timeSlots, setTimeSlots] = useState<any>();
  const [openBooking, setOpenBooking] = useState(false);
  const [selectedTimeslot, setSelectedTimeSlot] = useState<null | Date>();
  const [booked, setBooked] = useState(false);
  const [rdvs, setRdvs] = useState<any[]>([]);
  const [alreadyBooked, setAlreadyBooked] = useState(false);
  const [titre, setTitre] = useState("");
  const [annonceId, setAnnnonceId] = useState("");
  const [alreadyExists, setAlreadyExists] = useState(false);

  useEffect(() => {
    const dispos = async () => {
      return await getDisponibilites({
        reponseId: searchParams.get("id") as string,
        email: searchParams.get("email") as string,
        ownerId: vendeurId as string,
        type:
          searchParams.get("virtuelle") === "true" ? "virtuelle" : "sur place",
      });
    };
    dispos().then((res) => {
      if (res === "error" || (res as Response).status === 500) {
        return dispatch(
          setMessage({
            type: "error",
            message: "Une erreur est survenue, rafraichissez la page",
          })
        );
      } else if ((res as Response).status === 400) {
        (res as Response).json().then((result) => {
          if (result?.message === "already exists") {
            setAlreadyExists(true);
          }
        });
      } else if ((res as Response).status === 200) {
        (res as Response).json().then((result) => {
          setRaw(result?.dispos);
          setRdvs(result?.rdvs);
          setAnnnonceId(result?.annonceId);
        });
      }
    });
  }, [vendeurId]);

  useEffect(() => {
    if (annonceId) {
      getOneAnnonce(annonceId).then((res) => {
        if (res !== "error") {
          setTitre(res.data.titre);
        } else {
          setMessage({
            type: "error",
            message: "Une erreur est survenue, rafraichissez la page",
          });
        }
      });
    }
  }, [annonceId]);

  useEffect(() => {
    if (raw) {
      setDispos([]);
      const visitHour =
        searchParams.get("virtuelle") === "true"
          ? parseInt(raw.dureeVisiteVirtuelle?.split(":")[0])
          : parseInt(raw.dureeVisitePlace?.split(":")[0]);
      const visitMinute =
        searchParams.get("virtuelle") === "true"
          ? parseInt(raw.dureeVisiteVirtuelle?.split(":")[1])
          : parseInt(raw.dureeVisitePlace?.split(":")[1]);

      const dispos: any[] = createDispos(raw.creneaux);
      const eventRdvs: any[] = createRdvs(rdvs, "acheteur");
      // raw.creneaux?.forEach((c: any) => {
      //   let startDate = new Date(c.date);

      //   startDate.setHours(c.heureDebut?.split(":")[0]);
      //   startDate.setMinutes(c.heureDebut?.split(":")[1]);
      //   let endDate = new Date(c.date);
      //   endDate.setHours(c.heureFin?.split(":")[0]);
      //   endDate.setMinutes(c.heureFin?.split(":")[1]);

      //   dispos.push({
      //     start: startDate.toISOString(),
      //     end: endDate.toISOString(),
      //   });
      // });

      // rdvs?.forEach((rdv: any) => {
      //   const startDate = new Date(rdv.data.debut);
      //   const endDate = new Date(rdv.data.fin);
      //   events.push({
      //     id: events.length + 1,
      //     allDay: false,
      //     start: startDate.toISOString(),
      //     end: endDate.toISOString(),
      //     title: "Indisponible",
      //     color: "#ddd",
      //     borderColor: "#ddd",
      //     textColor: "#000",
      //     disponible: false,
      //   });
      // });

      const events: any[] = createEvents(
        dispos,
        eventRdvs,
        { hours: visitHour, minutes: visitMinute },
        "acheteur"
      );
      // dispos?.forEach((dispo: any) => {
      //   const alreadyExists = events.some((event: any) =>
      //     areIntervalsOverlapping(
      //       { start: new Date(dispo.start), end: new Date(dispo.end) },
      //       { start: new Date(event.start), end: new Date(event.end) }
      //     )
      //   );
      //   if (!alreadyExists) {
      //     events.push({
      //       id: events.length + 1,
      //       allDay: false,
      //       start: new Date(dispo.start).toISOString(),
      //       end: new Date(dispo.end).toISOString(),
      //       title: "Disponible",
      //       color: "#9dbedf",
      //       borderColor: "#9dbedf",
      //       textColor: "#000",
      //       disponible: true,
      //     });
      //   } else {
      //     const filter = events.filter((event: any) =>
      //       areIntervalsOverlapping(
      //         { start: new Date(dispo.start), end: new Date(dispo.end) },
      //         { start: new Date(event.start), end: new Date(event.end) }
      //       )
      //     );
      //     filter.sort(
      //       (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
      //     );
      //     // if visit start !== provider availibity
      //     if (
      //       new Date(dispo.start).getTime() !==
      //       new Date(filter[0].start).getTime()
      //     ) {
      //       const minutes = differenceInMinutes(
      //         new Date(filter[0].start).getTime(),
      //         new Date(dispo.start).getTime()
      //       );
      //       const hours = minutes / 60;
      //       //if enough time for a visit before the one scheduled
      //       if (
      //         parseFloat(visitHour + "." + visitMinute) <=
      //         parseFloat(hours + "." + (minutes - hours * 60))
      //       ) {
      //         events.push({
      //           id: events.length + 1,
      //           allDay: false,
      //           start: new Date(dispo.start).toISOString(),
      //           end: new Date(filter[0].start).toISOString(),
      //           title: "Disponible",
      //           color: "#9dbedf",
      //           borderColor: "#9dbedf",
      //           textColor: "#000",
      //           disponible: true,
      //         });
      //       }
      //     }

      //     // if visit end !== provider availibity
      //     if (
      //       new Date(dispo.end).getTime() !==
      //       new Date(filter.at(-1).end).getTime()
      //     ) {
      //       const minutes = differenceInMinutes(
      //         new Date(dispo.end).getTime(),
      //         new Date(filter.at(-1).end).getTime()
      //       );
      //       const hours = minutes / 60;

      //       //if enough time for a visit before the one scheduled
      //       if (
      //         parseFloat(visitHour + "." + visitMinute) <=
      //         parseFloat(hours + "." + (minutes - hours * 60))
      //       ) {
      //         events.push({
      //           id: events.length + 1,
      //           allDay: false,
      //           start: new Date(filter.at(-1).end).toISOString(),
      //           end: new Date(dispo.end).toISOString(),
      //           title: "Disponible",
      //           color: "#9dbedf",
      //           borderColor: "#9dbedf",
      //           textColor: "#000",
      //           disponible: true,
      //         });
      //       }
      //     }

      //     // if time between 2 visits
      //     filter.forEach((event: any, index: number) => {
      //       const minutes = differenceInMinutes(
      //         new Date(filter[index + 1]?.start),
      //         new Date(event.end)
      //       );
      //       const hours = minutes / 60;
      //       if (
      //         parseFloat(visitHour + "." + visitMinute) <=
      //         parseFloat(hours + "." + (minutes - hours * 60))
      //       ) {
      //         events.push({
      //           id: events.length + 1,
      //           allDay: false,
      //           start: new Date(event.end).toISOString(),
      //           end: new Date(filter[index + 1]?.start).toISOString(),
      //           title: "Disponible",
      //           color: "#9dbedf",
      //           borderColor: "#9dbedf",
      //           textColor: "#000",
      //           disponible: true,
      //         });
      //       }
      //     });
      //   }
      // });

      setDispos(events);
    }
  }, [raw, rdvs]);

  const displayTimeSlots = (info: any) => {
    if (info.event.title === "Disponible") {
      setTimeSlots(null);
      const visitHour =
        searchParams.get("virtuelle") === "true"
          ? parseInt(raw.dureeVisiteVirtuelle?.split(":")[0])
          : parseInt(raw.dureeVisitePlace?.split(":")[0]);
      const visitMinute =
        searchParams.get("virtuelle") === "true"
          ? parseInt(raw.dureeVisiteVirtuelle?.split(":")[1])
          : parseInt(raw.dureeVisitePlace?.split(":")[1]);
      const lastVisit = new Date(info.event.end);
      lastVisit.setHours(
        lastVisit.getHours() - visitHour,
        lastVisit.getMinutes() - visitMinute
      );
      let times: any[] = [];
      for (
        let i = info.event.start;
        i <= lastVisit;
        i.setMinutes(i.getMinutes() + 15)
      ) {
        times.push(new Date(i));
      }
      setTimeSlots(times);
      setOpenBooking(true);
    }
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>, slot: Date) => {
    setSelectedTimeSlot(slot);
  };

  const bookTimeslot = async () => {
    dispatch(setLoading({ isLoading: true }));
    const visitHour =
      searchParams.get("virtuelle") === "true"
        ? parseInt(raw.dureeVisiteVirtuelle?.split(":")[0])
        : parseInt(raw.dureeVisitePlace?.split(":")[0]);
    const visitMinute =
      searchParams.get("virtuelle") === "true"
        ? parseInt(raw.dureeVisiteVirtuelle?.split(":")[1])
        : parseInt(raw.dureeVisitePlace?.split(":")[1]);
    const startVisit = new Date(selectedTimeslot as Date);
    const endVisit = new Date(selectedTimeslot as Date);
    endVisit!.setHours(
      endVisit!.getHours() + visitHour,
      endVisit!.getMinutes() + visitMinute
    );

    // récupére les rdvs pour vérifier s'il y en a de nouveau

    const newRdvs = await getRdvs({
      reponseId: searchParams.get("id") as string,
      email: searchParams.get("email") as string,
      ownerId: vendeurId as string,
    });

    if (newRdvs === "error") {
      dispatch(
        setMessage({ type: "error", message: "Une erreur est survenue" })
      );
      return;
    } else {
      const overlap = newRdvs.rdvs.some((rdv: any) =>
        areIntervalsOverlapping(
          { start: new Date(startVisit), end: new Date(endVisit) },
          { start: new Date(rdv.data.debut), end: new Date(rdv.data.fin) }
        )
      );

      if (!overlap) {
        newRdv({
          debut: startVisit.toISOString(),
          fin: endVisit.toISOString(),
          vendeurId: vendeurId as string,
          acheteurEmail: searchParams.get("email") as string,
          reponseId: searchParams.get("id") as string,
          titre: titre,
          annonceId: annonceId,
          type:
            searchParams.get("virtuelle") === "true"
              ? "virtuelle"
              : "sur place",
        }).then((res) => {
          console.log(res.status);
          if (res.status === 200) {
            getRdvs({
              reponseId: searchParams.get("id") as string,
              email: searchParams.get("email") as string,
              ownerId: vendeurId as string,
            })
              .then((res) => setRdvs(res.rdvs))
              .then(() => {
                dispatch(setLoading({ isLoading: false }));
                setBooked(true);
                setOpenBooking(false);
                setTimeSlots(null);
                setSelectedTimeSlot(null);
              });
          } else {
            dispatch(setLoading({ isLoading: false }));
            dispatch(
              setMessage({ type: "error", message: "Une erreur est survenue" })
            );
          }
        });
      } else {
        setRdvs(newRdvs.rdvs);
        dispatch(setLoading({ isLoading: false }));
        setAlreadyBooked(true);
        setOpenBooking(false);
        setTimeSlots(null);
        setSelectedTimeSlot(null);
      }
    }
  };

  if (
    vendeurId === undefined ||
    searchParams.get("virtuelle") === null ||
    searchParams.get("email") === null ||
    searchParams.get("id") === null
  ) {
    return (
      <Container maxWidth="xl" sx={{ mt: 6, textAlign: "center" }}>
        <Typography>
          Une erreur est survenue. Si vous avez cliqué sur le lien reçu par
          email, contactez-nous.
        </Typography>
      </Container>
    );
  }

  if (alreadyExists) {
    return (
      <Container maxWidth="xl" sx={{ mt: 6, textAlign: "center" }}>
        <Typography>
          Vous avez déjà programmé une visite{" "}
          {searchParams.get("virtuelle") === "true" ? "virtuelle" : "sur place"}
          {/* eslint-disable-next-line react/no-unescaped-entities */}. Si vous
          souhaitez modifier l'horaire de cette visite, annulez la visite
          existante.
        </Typography>
      </Container>
    );
  }
  return (
    <>
      <Container maxWidth="xl" sx={{ height: "800px", pt: 4 }}>
        <FullCalendar
          plugins={[timeGridPlugin]}
          initialView="timeGridWeek"
          locale={frLocale}
          slotMinTime={"08:00:00"}
          slotMaxTime={"21:00:00"}
          slotDuration={"00:30:00"}
          allDaySlot={false}
          events={dispos}
          height={"auto"}
          timeZone="local"
          eventClick={displayTimeSlots}
          validRange={{
            start: new Date(),
          }}
        />
      </Container>
      <Backdrop
        open={openBooking}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Paper
          sx={{
            p: 2,
            maxWidth: "80%",
            width: "60%",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Typography textAlign="center">
            Le vendeur estime que la visite devrait durer{" "}
            {searchParams.get("virtuelle") === "true"
              ? parseInt(raw?.dureeVisiteVirtuelle?.split(":")[0])
              : parseInt(raw?.dureeVisitePlace?.split(":")[0])}
            h
            {searchParams.get("virtuelle") === "true"
              ? parseInt(raw?.dureeVisiteVirtuelle?.split(":")[1])
                  .toString()
                  .padStart(2, "0")
              : parseInt(raw?.dureeVisitePlace?.split(":")[1])
                  .toString()
                  .padStart(2, "0")}
          </Typography>
          <Box mt={3}>
            <ToggleButtonGroup
              value={selectedTimeslot}
              exclusive
              onChange={handleChange}
              sx={{
                flexWrap: "wrap",
                margin: "auto",
                justifyContent: "center",
                "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                  borderTopLeftRadius: "4px",
                  borderBottomLeftRadius: "4px",
                },
                "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
                  marginLeft: "-1px",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                },
              }}
            >
              {timeSlots?.map((t: Date, index: number) => (
                <ToggleButton
                  value={t}
                  key={index}
                  sx={{
                    m: "0.5rem",
                    width: "8rem",
                  }}
                >
                  <Typography>
                    {t.toLocaleTimeString("fr-FR", {
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Box
              mt={3}
              display={"flex"}
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="space-evenly"
            >
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "4.8rem",
                  px: "3rem",
                  py: "0.6rem",
                  mb: { xs: "1rem", sm: "0" },
                }}
                onClick={() => {
                  setOpenBooking(false);
                  setSelectedTimeSlot(null);
                }}
              >
                Annuler
              </Button>
              <Button
                variant="contained"
                sx={{ px: "3.5rem" }}
                onClick={bookTimeslot}
                disabled={selectedTimeslot ? false : true}
              >
                Valider
              </Button>
            </Box>
          </Box>
        </Paper>
      </Backdrop>
      <Backdrop
        open={booked}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Paper sx={{ p: 2 }}>
          <Box display="flex" justifyContent="flex-end">
            <ClearIcon
              onClick={() => setBooked(false)}
              sx={{ "&:hover": { cursor: "pointer" } }}
            />
          </Box>
          <Typography>
            Le rendez-vous a bien été fixé, vous allez recevoir une confirmation
            par email.
          </Typography>
        </Paper>
      </Backdrop>
      <Backdrop
        open={alreadyBooked}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Paper sx={{ p: 2 }}>
          <Box display="flex" justifyContent="flex-end">
            <ClearIcon
              onClick={() => setAlreadyBooked(false)}
              sx={{ "&:hover": { cursor: "pointer" } }}
            />
          </Box>
          <Typography>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Quelqu'un a été plus rapide, veuillez sélectionner un nouveau
            créneau
          </Typography>
        </Paper>
      </Backdrop>
    </>
  );
};

export default CalendrierDispos;
