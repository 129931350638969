// import Image from "next/image";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setSelected } from "../../store/uiSlice";
import ReactImageGallery from "react-image-gallery";
import Item, { LeftNav, RightNav } from "./ImageSlider";
import { renderDoc } from "../..//requests/s3";
import PlayImage from "../../images/Capture.png";

const PresentationImage = (props: {
  // path: string[];
  images: {
    image: {
      id: number;
      name: string;
      size: number;
      type: string;
      valid: boolean;
      imageUrl: string;
    };
    descritpion: string;
  }[];
  setSelected: any;
}) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [images, setImages] = useState<any>([]);
  const ref = useRef(null);
  // const props = useSelector(selectAnnonceProp(path.slice(1), []));

  useEffect(() => {
    const getImages = async (imageData: any) => {
      const data = await renderDoc(imageData.image.imageUrl);
      if (data !== "error") {
        if (data.data?.includes("image")) {
          return {
            original: data.url,
            thumbnail: data.url,
            type: "image",
            ...imageData,
          };
        } else if (data.data?.includes("video")) {
          return {
            original: data.url,
            thumbnail: PlayImage,
            type: "video",
            ...imageData,
          };
        }
      } else {
        return "error data";
      }
    };

    if (props.images?.length > 0) {
      const images = async () => {
        await Promise.all(props.images.map((p: any) => getImages(p)))
          .then((res) => setImages(res))
          .catch((err) => console.log(err));
      };
      images();
    } else {
      setImages([
        {
          original: "https://picsum.photos/600/250",
          thumbnail: "https://picsum.photos/600/250",
          type: "image",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu tristique massa. Nulla rhoncus ullamcorper luctus. Sed placerat quam massa, quis blandit massa feugiat ac. Quisque commodo purus vitae arcu placerat, sed scelerisque lorem aliquam. Nam tempor rutrum congue. In vehicula turpis sit amet nisi vulputate molestie. Morbi vel libero vehicula, mollis ex rhoncus, aliquet arcu. Morbi suscipit augue eu commodo interdum.",
          title: "test",
        },
        {
          original: "https://picsum.photos/600/300",
          thumbnail: "https://picsum.photos/600/300",
          type: "image",
          description: "Lorem ipsum",
          title: "test",
        },
      ]);
    }
  }, [props.images]);

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        minHeight: 200,
        height: "100%",
      }}
      className={
        fullscreen
          ? "image-gallery-image-fullscreen image-gallery-image"
          : "image-gallery-image"
      }
    >
      <ReactImageGallery
        ref={ref}
        items={images}
        onScreenChange={(e: any) => setFullscreen(e)}
        renderItem={(item: any) => (
          <Item {...item} checkFullscreen={fullscreen} />
        )}
        showPlayButton={false}
        startIndex={0}
        showIndex={true}
        renderLeftNav={(onClick: any, disabled: any) => (
          <LeftNav onClick={onClick} disabled={disabled} />
        )}
        renderRightNav={(onClick: any, disabled: any) => (
          <RightNav onClick={onClick} disabled={disabled} />
        )}
        onClick={() => {
          if (!fullscreen) {
            // @ts-ignore
            ref.current!.fullScreen();
            setFullscreen(true);
          }
        }}
      />
      {/* <img
      alt={props.altText ?? "image"}
      //   fill
      sizes="(max-width: 600px) 100vw, 50vw"
      src={props.src}
      // onClick={
      //   process.env.NEXT_PUBLIC_SHOW_ADMIN === "false"
      //     ? props.onStaticClick
      //     : props.setSelected
      // }
      style={{
        objectFit: "contain",
        filter: `${
          props.filter?.grayscale !== undefined
            ? `grayscale(${props.filter?.grayscale}%)`
            : ""
        } ${
          props.filter?.opacity !== undefined
            ? `opacity(${props.filter?.opacity}%)`
            : ""
        } ${
          props.filter?.brightness !== undefined
            ? `brightness(${props.filter?.brightness}%)`
            : ""
        } ${
          props.filter?.blur !== undefined
            ? `blur(${props.filter?.blur}px)`
            : ""
        }`,
      }}
    /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  setSelected: () =>
    dispatch(
      setSelected({
        path: ownProps.path.slice(0, -1),
        inputType: "PresentationItemInput",
      })
    ),
  //   onStaticClick: () => dispatch(showLightBox({ value: ownProps.src })),
});

export default connect(null, mapDispatchToProps)(PresentationImage);
