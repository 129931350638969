import {
  Box,
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import themes from "../../theme";
import { selectAnnonceProp, setAnnonceProp } from "../../store/annonceSlice";
import { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
// import { useRouter } from "next/router";

const ThemeItem = (props: any) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      border: props.selected ? "1px solid black" : "none",
      alignItems: "center",
    }}
  >
    <Radio value={props.theme} />
    {["main", "light", "dark"].map((c) => (
      <Box key={c} sx={{ flex: 1, justifyContent: "center", display: "flex" }}>
        <div
          style={{
            backgroundColor: props[c],
            width: 30,
            height: 30,
            borderRadius: 15,
          }}
        />
      </Box>
    ))}
  </Box>
);

const ThemePicker = ({
  setTheme,
}: {
  setTheme: Dispatch<SetStateAction<string>>;
}) => {
  const dispatch = useDispatch();
  const displayedTheme = "default";
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const props = useSelector(selectAnnonceProp(["theme"], {}));
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["theme"],
        value: (event.target as HTMLInputElement).value,
      })
    );
  };
  return (
    <Stack>
      <Typography
        component="h5"
        fontSize="1rem"
        color="white"
        sx={{
          textTransform: "uppercase",
          fontWeight: "700",
          backgroundColor: "secondary.main",
          // color: colorPrimary,
          p: 1.5,
        }}
      >
        Couleurs du site
      </Typography>
      <FormControl sx={{ p: 2 }}>
        <RadioGroup value={displayedTheme} onChange={handleChange}>
          <Stack spacing={1}>
            {Object.keys(themes).map((theme) => (
              <ThemeItem
                key={theme}
                {...themes[theme].palette.primary}
                theme={theme}
                selected={props.theme === theme}
              />
            ))}
          </Stack>
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default ThemePicker;
