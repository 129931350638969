import React from "react";
import image from "../images/direct-proprietaire-site-exemple-web.webp";
import { Container, Box, Typography, Button } from "@mui/material";

type Props = {};

const SectionPrix = (props: Props) => {
  return (
    <Container maxWidth="xl">
      <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }}>
        <Box
          flex={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={{ xs: "center", md: "flex-start" }}
          mr={{ xs: 0, md: 20 }}
        >
          <Typography color="primary" pb={1}>
            Une formule simple sans frais ni commissions
          </Typography>
          <Typography
            sx={{ fontSize: 28 }}
            textAlign={{ xs: "center", md: "left" }}
          >
            Payez uniquement pour la création de votre site
          </Typography>
          <Typography
            color="white"
            fontStyle="italic"
            sx={{ backgroundColor: "black", px: 0.5, mt: 2 }}
          >
            Offre de lancement
          </Typography>
          <Typography variant="body2">
            <Typography
              component="span"
              fontSize={64}
              sx={{ color: "#FFA400" }}
            >
              399€
            </Typography>
            TTC
          </Typography>
          <Box>
            <Button
              variant="contained"
              sx={{ fontSize: 20 }}
              href="/creer-compte"
            >
              Créer votre site dès maintenant
            </Button>
          </Box>
        </Box>
        <Box flex={1}>
          <img src={image} alt="" style={{ width: "100%" }} />
        </Box>
      </Box>
    </Container>
  );
};

export default SectionPrix;
