import { useEffect, useCallback, useRef } from "react";
import {
  useBeforeUnload,
  unstable_useBlocker as useBlocker,
} from "react-router-dom";

export const Prompt = ({
  hasUnsavedChanges,
}: {
  hasUnsavedChanges: boolean;
}) => {
  const onLocationChange = useCallback(
    (prop: any) => {
      if (hasUnsavedChanges) {
        return !window.confirm(
          "Vous avez des changements non-enregistrés, êtes-vous sûr de vouloir changer de page ?"
        );
      } else {
        return false;
      }
    },
    [hasUnsavedChanges]
  );
  console.log("prompt");
  usePrompt(onLocationChange, hasUnsavedChanges);
  useBeforeUnload(
    useCallback(
      (event) => {
        if (hasUnsavedChanges) {
          event.preventDefault();
          event.returnValue = "";
        }
      },
      [hasUnsavedChanges]
    ),
    { capture: true }
  );

  return null;
};

function usePrompt(
  onLocationChange: (prop: any) => boolean,
  hasUnsavedChanges: boolean
) {
  const blocker = useBlocker(hasUnsavedChanges ? onLocationChange : false);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);
}
