import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {};

const MentionsLegales = (props: Props) => {
  return (
    <Container maxWidth="xl">
      <Typography variant="h1" textAlign="center" mt={6}>
        Mentions Légales
      </Typography>
      <Typography variant="h2" mt={6} sx={{ fontStyle: "italic" }}>
        Identité
      </Typography>
      <Box sx={{ fontWeight: "400" }}>
        <Typography mt={2} variant="body2">
          DONNEE360 au capital de 100 €
        </Typography>
        <Typography mt={1} variant="body2">
          RCS AVIGNON Lyon B 847 936 150.
        </Typography>
        <Typography mt={1} variant="body2">
          Code NAF : 6190Z Autres activités de télécommunication
        </Typography>
        <address
          style={{ fontStyle: "normal", lineHeight: "2", marginTop: "8px" }}
        >
          Siège social : 254 rue Vendome 69006 Lyon
          <br />
          Contact :{" "}
          <Link to="/#contactez-nous">via ce formulaire de contact</Link>
          <br />
          email : lien@donnee360.fr
          <br />
        </address>
        <Typography mt={1} variant="body2">
          Directeur de la publication : Paul Muller
        </Typography>
        <Typography variant="h2" mt={6} sx={{ fontStyle: "italic" }}>
          Hébergement
        </Typography>
        <Typography mt={1} variant="body2">
          Ce site est hébergé chez Render Services Inc.
        </Typography>
        <address
          style={{ fontStyle: "normal", lineHeight: "2", marginTop: "8px" }}
        >
          <span style={{ fontWeight: "700" }}>Adresse :</span>
          <br />
          525 Brannan St Ste 300, San Francisco,
          <br />
          CA 94107,
          <br />
          United States
          <br />
          <span style={{ fontWeight: "700" }}>Téléphone :</span> 415-830-4762
          <br />
          <span style={{ fontWeight: "700" }}>Email :</span> legal@render.com
          <br />
        </address>
      </Box>
      <Typography variant="h2" mt={6} sx={{ fontStyle: "italic" }}>
        Propriété intellectuelle
      </Typography>
      <Typography mt={2} variant="body2" sx={{ lineHeight: "1.5" }}>
        Le contenu du site{" "}
        <Link to="https://direct-proprietaire.fr/">
          https://direct-proprietaire.fr/
        </Link>{" "}
        incluant notamment textes, images, photos, graphismes, logos sont
        protégés par des droits de propriété intellectuelle (droits d’auteur,
        marques déposées). Toutes utilisation, reproduction, diffusion,
        commercialisation, modification de toute ou partie du site 
        <Link to="https://direct-proprietaire.fr/">
          https://direct-proprietaire.fr/
        </Link>
        , sans autorisation de l’Éditeur, sont interdites conformément à
        l’article L-122-4 de la loi n°92-597 du 1er juillet 1992 relative au
        code de la propriété intellectuelle.
      </Typography>
      <Typography variant="h2" mt={6} sx={{ fontStyle: "italic" }}>
        Données personnelles du formulaire contact
      </Typography>
      <Typography mt={2} variant="body2" sx={{ lineHeight: "1.5" }}>
        <Link to="https://direct-proprietaire.fr/">
          https://direct-proprietaire.fr/
        </Link>
         utilise un formulaire sur son site internet pour recueillir vos
        demandes d’informations. Ce formulaire est un formulaire libre dont la
        non-utilisation n’a aucune conséquence sur votre utilisation du site.
        les informations recueillies sur ce formulaire (nom, prénom, date de
        naissance, email) sont enregistrées dans un fichier informatisé et
        sécurisé par notre délégué à la protection des données personnelles, M.
        Paul Muller, uniquement pour répondre à vos demandes d’informations via
        votre messagerie électronique. Les données collectées seront à la seule
        et unique disposition de 
        <Link to="https://direct-proprietaire.fr/">
          https://direct-proprietaire.fr/
        </Link>
        . Elles seront conservées par le responsable du traitement des données.
      </Typography>
      <Typography mt={2} variant="body2" sx={{ lineHeight: "1.5" }}>
        Vous pouvez accéder aux données vous concernant, les rectifier, demander
        leur effacement, ou exercer votre droit à la limitation du traitement de
        vos données conformément à la loi 178-17 du 6 janvier 1978 relative à
        l’informatique, aux fichiers et aux libertés, en contactant le DPO via{" "}
        <Link to="/#contactez-nous">ce formulaire de contact</Link>.
      </Typography>
      <Typography mt={2} variant="body2" sx={{ lineHeight: "1.5" }}>
        La collecte et le traitement de ces données personnelles repose bien
        évidemment sur votre consentement actif qui reste libre, spécifique,
        éclairé et univoque comme base légale conformément aux articles 4 et 7
        du RGPD. Vous pouvez à tout moment retirer votre consentement au
        traitement de vos données, vous y opposer ou exercer votre droit à la
        portabilité de vos données. Consulter le site Cnil.fr pour plus d’infos.
        Pour exercer ce droit, vous pouvez contacter notre délégué à la
        protection des données ou nous transmettre votre demande en précisant
        votre identité et vos coordonnées par courriel via{" "}
        <Link to="/#contactez-nous">ce formulaire de contact</Link> ou courrier
        postal à l’adresse :
      </Typography>
      <address
        style={{ fontStyle: "normal", lineHeight: "2", marginTop: "8px" }}
      >
        Donnée360
        <br />
        Paul Muller
        <br />
        254 rue Vendome
        <br />
        69006 Lyon
        <br />
      </address>
      <Typography mt={2} variant="body2" sx={{ lineHeight: "1.5" }}>
        En cas de difficultés, de réponse insatisfaisante ou d’absence de
        réponse après nous avoir contactés, vous avez la possibilité de déposer
        une plainte en ligne auprès de la CNIL 
        <Link to="https://www.cnil.fr/fr/plaintes">
          https://www.cnil.fr/fr/plaintes
        </Link>
         ou par courrier postal en écrivant à : CNIL – Service des Plaintes – 3
        Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07.
      </Typography>
      <Typography variant="h2" mt={6} sx={{ fontStyle: "italic" }}>
        Nom et coordonnées du ou des médiateurs de la consommation
      </Typography>
      <Typography mt={2} variant="body2" sx={{ lineHeight: "1.5" }}>
        Conformément à l’article L. 612-1 du Code de la consommation, dans un
        délai d’un an à compter de sa réclamation écrite, le consommateur, sous
        réserve de l’article L.152-2 du code de la consommation, a la faculté
        d’introduire une demande de résolution amiable par voie de médiation,
        auprès de :    
      </Typography>
      <address
        style={{
          fontStyle: "normal",
          lineHeight: "2",
          marginTop: "8px",
          fontWeight: "400",
          marginBottom: "80px",
        }}
      >
        SAS Médiation Solution
        <br />
        222 chemin de la bergerie
        <br />
        01800 Saint Jean de Niost
        <br />
        site :{" "}
        <Link to="https://www.sasmediationsolution-conso.fr">
          https://www.sasmediationsolution-conso.fr
        </Link>
        <br />
        email : contact@sasmediationsolution-conso.fr
        <br />
      </address>
    </Container>
  );
};

export default MentionsLegales;
