import { Box, Container, Grid, Stack, Typography } from "@mui/material";
// import Image, { StaticImageData } from "next/image";

type Props = {
  steps: {
    titre: string;
    description?: string;
    image: string;
    alt: string;
  }[];
};

const Steps = ({ steps }: Props) => {
  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Container maxWidth="xl" sx={{ py: 10 }}>
        <Typography
          variant="h3"
          component="h2"
          color="primary"
          textAlign="center"
          pb={2}
        >
          Simple et efficace
        </Typography>
        <Typography textAlign="center" variant="h2" component="h3">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Mode d'emploi
        </Typography>
        <Grid container columns={{ xs: 1, sm: 2, md: 3 }} spacing={4}>
          {steps.map(
            (
              step: {
                titre: string;
                description?: string;
                image: string;
                alt: string;
              },
              index: number
            ) => (
              <Grid item key={index} xs={1}>
                <Stack spacing={2}>
                  {/* <Box
                    sx={{
                      width: "100%",
                      position: "relative",
                      minHeight: 400,
                      height: "100%",
                    }}
                  > */}
                  <img
                    src={step.image}
                    alt={step.alt}
                    style={{ width: "100%" }}
                    // fill
                    // style={{ objectFit: "contain" }}
                  />
                  {/* </Box> */}
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{ lineHeight: "normal" }}
                  >
                    {step.titre}
                  </Typography>
                  {step.description ? (
                    <Typography variant="body2">{step.description}</Typography>
                  ) : null}
                </Stack>
              </Grid>
            )
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default Steps;
