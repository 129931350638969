import {
  Breakpoint,
  Theme,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";

export const createEditTheme = ({
  primary,
  themeScale,
}: {
  primary: string;
  themeScale: any;
}) =>
  responsiveFontSizes(
    createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 601 * themeScale,
          md: 901 * themeScale,
          lg: 1201 * themeScale,
          xl: 1537 * themeScale,
        },
      },
      typography: {
        fontFamily: ["Poppins", "Roboto", "sans-serif"].join(","),
        h1: {
          fontSize: "3.75rem",
          fontWeight: 700,
        },
        h2: {
          fontSize: "2rem",
          fontWeight: 700,
        },
        h3: {
          fontSize: "1.5rem",
          fontWeight: 700,
        },
        h4: {
          fontSize: "1rem",
          fontWeight: 700,
        },
        h5: {
          fontSize: "1rem",
          fontWeight: 300,
        },
        h6: {
          fontSize: "0.8rem",
          fontWeight: 300,
        },
        body1: {
          fontWeight: 700,
          fontSize: "1rem",
        },
        body2: {
          fontWeight: 400,
          fontSize: "1rem",
        },
        subtitle1: {
          fontSize: "1.4rem",
          fontWeight: 700,
        },
        subtitle2: {
          fontWeight: 700,
          fontSize: "1.8rem",
        },
      },
      palette: {
        dark: {
          main: "#1E1E1E",
        },
        primary: {
          main: primary,
        },
        // primary: {
        //   light: "#3D67FF",
        //   main: "#4158D0",
        // },
        white: {
          main: "#FFF",
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
            outlined: ({ ownerState, theme }) => ({
              padding: "18px 20px",
              fontSize: "1rem",
              fontWeight: "700",
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: "white",
              },
            }),
            contained: ({ ownerState, theme }) => ({
              borderRadius: "48px",
              padding: "10px 24px",
              borderWidth: "2px",
              fontWeight: "700",
              // ...(ownerState.color !== "inherit" &&
              //   ownerState.color !== undefined && {
              //     borderColor: theme.palette[ownerState.color].main,
              //   }),
            }),
          },
        },
      },
    }),
    {
      breakpoints: ["xs", "sm", "md", "lg", "xl"].slice(0, 3) as Breakpoint[],
      factor: 2,
    }
  );

const createCustomTheme = ({ primary }: { primary: string }) =>
  responsiveFontSizes(
    createTheme({
      palette: {
        // contrastThreshold: 4.5,
        primary: {
          main: primary,
        },
        secondary: {
          main: "#5552FF",
        },
        dark: {
          main: "#1E1E1E",
        },
        background: { default: "rgb(243,243,243)" },
      },
      typography: {
        fontFamily: ["Poppins", "Roboto", "sans-serif"].join(","),
        h1: {
          fontSize: "3.75rem",
          fontWeight: 800,
        },
        h2: {
          fontSize: "2rem",
          fontWeight: 700,
        },
        h3: {
          fontSize: "1.5rem",
          fontWeight: 700,
        },
        h4: {
          fontSize: "1rem",
          fontWeight: 700,
        },
        h5: {
          fontSize: "1rem",
          fontWeight: 300,
        },
        h6: {
          fontSize: "0.8rem",
          fontWeight: 300,
        },
        body1: {
          fontWeight: 700,
          fontSize: "1rem",
        },
        body2: {
          fontWeight: 400,
          fontSize: "1rem",
        },
        subtitle1: {
          fontSize: "1.4rem",
          fontWeight: 700,
        },
        subtitle2: {
          fontWeight: 700,
          fontSize: "1.8rem",
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
            outlined: ({ ownerState, theme }) => ({
              padding: "18px 20px",
              fontSize: "1rem",
              fontWeight: "700",
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: "white",
              },
            }),
            contained: ({ ownerState, theme }) => ({
              borderRadius: "48px",
              padding: "10px 24px",
              borderWidth: "2px",
              fontWeight: "700",
              ...(ownerState.color !== "inherit" &&
                ownerState.color !== undefined && {
                  borderColor: theme.palette[ownerState.color].main,
                }),
            }),
          },
        },
      },
    }),
    {
      breakpoints: ["xs", "sm", "md", "lg", "xl"],
      factor: 2,
    }
  );

declare module "@mui/material/styles" {
  interface Palette {
    dark: Palette["primary"];
    white: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    dark?: PaletteOptions["primary"];
    white?: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    dark: true;
    white: true;
  }
}
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    dark: true;
    white: true;
  }
}

export type Themes = {
  default: Theme;
  [key: string]: Theme;
};
// Create a theme instance.
const themes: Themes = {
  default: createCustomTheme({ primary: "#0E67DD" }),
};

export default themes;
