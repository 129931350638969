import React, { useEffect } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./components/router";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { useDispatch } from "react-redux";

Amplify.configure(awsExports);

function App() {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const annonces = async () => await getAnnonces();
  //   annonces().then((res) => {
  //     if (res !== "ok") {
  //       dispatch(disconnect());
  //     }
  //   });
  // }, []);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
