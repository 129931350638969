import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import ChangePassword from "../components/dashboard/ChangePassword";
import ChangeInfos from "../components/dashboard/ChangeInfos";
import { StyledTab, StyledTabs } from "./Dashboard";

type Props = {};

const MonCompte = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 6 }}>
      <Typography variant="h1">Mon compte</Typography>

      <Box my={3}>
        <StyledTabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab label="Modifier mes informations" />
          <StyledTab label="Changer mon mot de passe" />
          {/* <StyledTab label="Supprimer mon compte" /> */}
        </StyledTabs>
      </Box>

      {selectedTab === 0 ? <ChangeInfos /> : null}
      {selectedTab === 1 ? <ChangePassword /> : null}
    </Container>
  );
};

export default MonCompte;
