import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Button, Collapse, Stack, Paper, Box, Toolbar } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { selectSelected, setSelected, setUiProp } from "../../store/uiSlice";
import { useSelector } from "react-redux";
import Wrapper from "../../components/RenderWrapper";
import HeroSection from "../../components/AnnonceComponents/HeroSection";
import Presentation from "../../components/AnnonceComponents/Presentation";
import { selectAnnonceById } from "../../store/annonceSlice";
import { EntityId } from "@reduxjs/toolkit";
import Editor from "../../components/EditorComponent/Editor";
import EditionBar from "../../components/EditorComponent/EditionBar";
import Informations from "../../components/AnnonceComponents/Informations";
import "react-image-gallery/styles/css/image-gallery.css";
import {
  unstable_useBlocker as useBlocker,
  useLocation,
  useParams,
} from "react-router-dom";
import { Prompt } from "../../components/LeavingPageUnsavedChanges";
import store from "../../store/store";
// import { useRouter } from "next/router";
const editorWidth = 400;

const CloseEditorPanelButton = () => {
  const dispatch = useDispatch();
  return (
    <Button
      color="primary"
      sx={{
        mb: 1,
        width: "270px",
      }}
      variant="contained"
      onClick={() => dispatch(setSelected(null))}
    >
      Fermer
    </Button>
  );
};

const EditorPanel = ({
  selected,
  pageIndex,
  setTheme,
}: {
  selected: { [key: string]: any };
  pageIndex: string | undefined;
  setTheme: Dispatch<SetStateAction<string>>;
}) => (
  <Collapse
    orientation="horizontal"
    in={!!selected?.path?.length}
    sx={{ zIndex: 499 }}
  >
    <div style={{ width: editorWidth }}>
      <Stack
        direction="column"
        spacing={2}
        sx={{ position: "relative", overflowX: "hidden" }}
      >
        <Paper
          sx={{
            position: "fixed",
            top: 90,
            right: 0,
            width: editorWidth,
            height: "calc(100% - 100px)",
            marginBottom: 1,
            overflowY: "auto",
            borderRadius: 0,
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          {selected?.path.length ? (
            <>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                sx={{ overflowX: "hidden" }}
              >
                <Editor
                  path={[
                    pageIndex,
                    selected.path,
                    selected.inputType,
                    selected.source,
                  ]}
                  theme={setTheme}
                />
                <Box mt={2} textAlign="center">
                  <CloseEditorPanelButton />
                </Box>
              </Box>
            </>
          ) : null}
        </Paper>
      </Stack>
    </div>
  </Collapse>
);

const PagePreview = ({
  originalTheme,
  pageState,
  pageIndex,
  refAnnonce,
}: // , site,  multipage, selectOptions
{
  originalTheme: string;
  pageState: any;
  pageIndex: string | undefined;
  refAnnonce: number;
}) => (
  <Wrapper originalTheme={originalTheme}>
    <>
      <HeroSection
        path={["hero"]}
        inputType="ParallaxInput"
        layers={pageState!.hero.layers}
        pageIndex={pageIndex}
        refAnnonce={refAnnonce}
      />
      <Presentation
        anchor="qui-sommes-nous"
        path={["presentation"]}
        inputType="PresentationInput"
        data={pageState!.presentation}
        pageIndex={pageIndex}
        defaultSectionProps={{ backgroundColor: "white" }}
      />
      <Informations
        anchor="informations"
        path={["informations"]}
        inputType="InformationsInput"
        data={pageState!.informations}
        pageIndex={pageIndex}
      />
    </>
  </Wrapper>
);

function Page(props: any) {
  const dispatch = useDispatch();
  const selected = useSelector(selectSelected);
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  dispatch(setUiProp({ prop: "currentAnnonceId", value: annonceId }));
  const [theme, setTheme] = useState("default");

  const pageState = useSelector((state: any) =>
    selectAnnonceById(state, annonceId as EntityId)
  );
  useEffect(() => {
    pageState?.data?.data.theme && setTheme(pageState?.data?.data.theme);
  }, [pageState]);

  return pageState ? (
    <>
      <Prompt hasUnsavedChanges={props.hasUnsavedChanges} />
      <Box
        position="fixed"
        sx={{
          height: "90px",
          top: 0,
          width: "100%",
          zIndex: 500,
          backgroundColor: "#f3f3f3",
        }}
      >
        {/* @ts-ignore */}
        <EditionBar setTheme={setTheme} />
      </Box>
      <Toolbar sx={{ height: 90 }} />
      <Stack
        direction="row"
        sx={{ width: "100%", display: "flex", overflow: "hidden" }}
      >
        <Stack
          direction="column"
          sx={{ display: "flex", flex: 1, overflowY: "auto" }}
        >
          <Box
            m={1.5}
            mt={0}
            mb={1}
            style={{ borderRadius: 8, border: "1px solid #00000033" }}
            sx={{
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <PagePreview
              pageIndex={annonceId as string}
              pageState={pageState?.data.data}
              refAnnonce={pageState?.data.ref}
              originalTheme={theme}
            />
          </Box>
        </Stack>
        <EditorPanel
          pageIndex={annonceId as string}
          {...{
            selected,
            setTheme: props.setTheme,
          }}
        />
      </Stack>
    </>
  ) : null;
}

const mapStateToProps = (state: any) => {
  return {
    hasUnsavedChanges: state.undoable.past.length > 0,
  };
};

export default connect(mapStateToProps, null)(Page);
