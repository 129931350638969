import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Container,
  styled,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AcheteursPotentiels from "../components/dashboard/AcheteursPotentiels";
import AjoutChangeAnnonce from "../components/dashboard/AjoutChangeAnnonce";
import Disponibilites from "../components/dashboard/Disponibilites";
import { selectUser, setConnected } from "../store/authSlice";
import Articles from "../components/dashboard/Articles";
import { useSearchParams } from "react-router-dom";
import { changeUserInfos } from "../requests/user";
import { setMessage } from "../store/errorSuccessSlice";
import { getCurrent } from "../util/getCurrent";
import DemandesArchivees from "../components/dashboard/DemandesArchivees";

type Props = {};
export const StyledTabs = styled(
  (props: {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
  }) => (
    <Tabs
      {...props}
      allowScrollButtonsMobile
      variant={"scrollable"}
      scrollButtons={"auto"}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  )
)({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
  },
  justifyContent: "space-between",
  "& div.Mui-disabled.MuiTabs-scrollButtons.MuiButtonBase-root": {
    display: "none",
  },
});

export const StyledTab = styled((props: { label: string }) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontSize: "1rem",
  fontWeight: "700",
  marginRight: "10px",
  "&.Mui-selected": {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "12px",
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.primary.light,
  },
}));

const Dashboard = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const user = useSelector(selectUser);
  const [paymentError, setPaymentError] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (searchParams.get("payment") !== null) {
      if (searchParams.get("payment") === "true" && user !== undefined) {
        const currentUser = JSON.parse(JSON.stringify(user));
        currentUser.data.paid = true;
        changeUserInfos(currentUser).then((res) => {
          if (res === "error") {
            dispatch(
              setMessage({
                type: "error",
                message: "Une erreur s'est produite, veuillez nous contacter",
              })
            );
          } else {
            getCurrent(localStorage.getItem("token") as string).then((res) => {
              if (res !== "error") {
                setSearchParams({});
                dispatch(
                  setConnected({
                    data: res,
                    token: localStorage.getItem("token") as string,
                  })
                );
              }
            });
          }
        });
      } else if (searchParams.get("payment") === "false") {
        setPaymentError(true);
        setSearchParams({});
      }
    }
  }, [searchParams, user]);

  if (paymentError) {
    return (
      <Container maxWidth="xl" sx={{ mt: 5, textAlign: "center" }}>
        <Typography textAlign="center" mb={6}>
          Une erreur s'est produite lors de votre paiement, cliquez sur le
          bouton pour réessayer
        </Typography>
        <Button
          style={{ margin: "auto" }}
          variant="contained"
          onClick={() => {
            setSearchParams({});
            window.location.reload();
          }}
        >
          Réessayer
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 6 }}>
      <Typography variant="h1">Tableau de bord</Typography>

      <Box my={3}>
        <StyledTabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab label="Demandes reçues" />
          <StyledTab label="Mofifier mon annonce" />
          <StyledTab label="Disponibilités" />
          <StyledTab label="Demandes archivées" />
          {user?.roles.includes("65099d264a8a916f7a2dfa86") ? (
            <StyledTab label="Blog" />
          ) : null}
        </StyledTabs>
      </Box>
      {selectedTab === 0 ? <AcheteursPotentiels /> : null}
      {selectedTab === 1 ? <AjoutChangeAnnonce /> : null}
      {selectedTab === 2 ? <Disponibilites /> : null}
      {selectedTab === 3 ? <DemandesArchivees /> : null}
      {selectedTab === 4 ? <Articles /> : null}
    </Container>
  );
};

export default Dashboard;
