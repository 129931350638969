import React from "react";
import { Container, Typography } from "@mui/material";

type Props = {};

const CGV = (props: Props) => {
  return (
    <Container maxWidth="xl" sx={{ fontWeight: "400" }}>
      <Typography variant="h1" textAlign="center" mt={6}>
        Conditions Générales de Ventes
      </Typography>

      <ol style={{ lineHeight: "2" }}>
        <li>
          Les Parties reconnaissent que l’exécution de la mission confiée à
          <span style={{ fontWeight: "700" }}>DONNÉE360</span> durera 1 an sous
          réserve que le Client ai fourni toutes les réponses aux questions de 
          <span style={{ fontWeight: "700" }}>DONNÉE360</span> et effectué le
          règlement.
        </li>
        <li>
          Toute demande de changement du Client, dès lors qu’elle entraîne une
          modification importante des Services confiés à{" "}
          <span style={{ fontWeight: "700" }}>DONNÉE360</span> devra faire
          l’objet d’un accord préalable des parties quant au surcoût de ces
          Services. Dans l’hypothèse où le Client annule sa commande avant que 
          <span style={{ fontWeight: "700" }}>DONNÉE360</span> ai commencé les
          Services, <span style={{ fontWeight: "700" }}>DONNÉE360</span> lui
          remboursera toutes sommes qui auraient pu être versées. Si
          l’annulation de la commande intervient après que{" "}
          <span style={{ fontWeight: "700" }}>DONNÉE360</span> ai commencé les
          Services, aucun remboursement ne pourra intervenir.
        </li>
        <li>
          Le Client n’acquiert aucun droit sur la méthode ou savoir-faire
          élaboré par <span style={{ fontWeight: "700" }}>DONNÉE360</span>
           susceptible d’être utilisé pour la réalisation des Services.{" "}
          <span style={{ fontWeight: "700" }}>DONNÉE360</span> pourra utiliser
          librement toute méthode ou savoir-faire qu’il a développé à l’occasion
          de la réalisation des Services, sous réserve de son obligation de
          confidentialité. Toute restriction de la méthode de 
          <span style={{ fontWeight: "700" }}>DONNÉE360</span> annulera la
          Garantie offerte.
        </li>
        <li>
          Le Client s’engage à répondre le plus rapidement possible aux demandes
          d’informations complémentaires émises par 
          <span style={{ fontWeight: "700" }}>DONNÉE360</span> . En cas
          contraire, le non-respect des délais de livraison ne saurait être
          imputable à <span style={{ fontWeight: "700" }}>DONNÉE360</span>.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>DONNÉE360</span> et le Client
          assurent le caractère confidentiel des termes de ce Contrat et de
          toute information obtenue dans le cadre de son application, et ce,
          pendant toute la durée du présent contrat ainsi que pendant deux (2)
          ans après son expiration.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>DONNÉE360</span> s’engage à
          exécuter les Services qui lui sont confiés avec tout le soin en usage
          dans sa profession, conformément aux règles de l’art et aux délais de
          livraison convenus. 
        </li>
        <li>
          En cas d’absence de respect par l’une ou l’autre des Parties de l’une
          ou l’autre des clauses du présent contrat, celui-ci sera résilié de
          plein droit trente (30) jours après l’envoi d’une mise en demeure par
          lettre recommandée avec avis de réception restée sans effet.
        </li>
        <li>
          Le présent Contrat est régi par le droit français. En cas de litige de
          toute nature ou de contestation relative à l’exécution de la commande,
          le tribunal de Avignon sera seul compétant.
        </li>
        <li>
          Le Client reconnaît avoir vérifié l’adéquation du service à ses
          besoins, fourni toutes les informations nécessaires et avoir reçu de
          <span style={{ fontWeight: "700" }}>DONNÉE360</span> toutes les
          informations et conseils qui lui étaient nécessaires pour souscrire au
          présent engagement en connaissance de cause.
        </li>
        <li>
          En cas de paiements échelonnés sur une période supérieure a
          l’engagement, le Client reconnaît l’engagement de paiement total de la
          prestation. Le client déclare avoir pris connaissance des conditions
          générales ci-dessus
        </li>
        <li>
          Le client déclare avoir pris connaissance des conditions générales
          ci-dessus.
        </li>
      </ol>
    </Container>
  );
};

export default CGV;
