import { createSlice } from "@reduxjs/toolkit";
// import { AppState } from "./store";
// import { autoSave } from "./thunks/autoSave";
// import { signOut } from "./user";

export interface UiProps {
  selected: {
    path: string[];
    inputType?: string;
    source?: string;
  } | null;
  loading: {
    isLoading: boolean;
    message?: string;
  };
  currentAnnonceId: null | string;
  //   currentPageId: null | string;
  //   currentMenuItem: string;
  currentDevice: string;
  contentScale: number;
  isSaving: boolean;
  //   showLightBox: string;
}

const initialState: UiProps = {
  selected: null,
  loading: {
    isLoading: false,
  },
  currentAnnonceId: null,
  //   currentPageId: null,
  //   currentMenuItem: "Accueil",
  currentDevice: "laptop",
  contentScale: 1,
  isSaving: false,
  //   showLightBox: '',
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setUiProp(state, action) {
      // @ts-ignore
      state[action.payload.prop] = action.payload.value;
    },
    setSaving(state, action) {
      // @ts-ignore
      state[action.payload.prop] = action.payload.value;
    },
    // showLightBox(state, action) {
    //   state.showLightBox = action.payload.value;
    // }
  },
  //   extraReducers: (builder) => {
  //     // Add reducers for additional action types here, and handle loading state as needed
  //     builder
  //       .addCase(autoSave.fulfilled, (state, _action) => {
  //         state.isSaving = false;
  //       })
  //       .addCase(autoSave.pending, (state, _action) => {
  //         state.isSaving = true;
  //       })
  //       .addCase(signOut, (state, _action) => initialState);
  //   },
});

export const {
  setSelected,
  setLoading,
  setUiProp,
  setSaving,
  //  showLightBox
} = uiSlice.actions;

export const selectSelected = (state: any) => state.ui.selected;

export const selectLoading = (state: any) => state.ui.loading;

// export const selectCurrentSiteId = (state: AppState) =>
//   process.env.NEXT_PUBLIC_SHOW_ADMIN === "true" ? (state.ui.currentSiteId || state.undoable.present.localSites.ids[0]) : process.env.NEXT_PUBLIC_SITE_ID as string;

export const selectCurrentAnnonceId = (state: any) =>
  state.ui.currentAnnonceId || state.undoable?.present?.annonces?.ids[0];

export const selectUiProp = (prop: string) => (state: any) => state.ui[prop];

export default uiSlice.reducer;
