import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type Props = {};

const Cancel = (props: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => navigate("/coaching"), 7000);
  }, []);
  return (
    <Box textAlign="center" mt={15}>
      Paiement non effectué, vous allez être redirigé...
    </Box>
  );
};

export default Cancel;
