import { createSlice } from "@reduxjs/toolkit";

export interface auth {
  connected: boolean;
  user: any;
  token: string;
}

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    connected: false,
    user: undefined,
    token: undefined,
  },
  reducers: {
    disconnect: (state) => {
      localStorage.removeItem("token");
      state.token = undefined;
      state.connected = false;
      state.user = undefined;
    },
    setConnected: (state, action) => {
      state.user = action.payload.data;
      state.token = action.payload.token;
      state.connected = true;
    },
  },
});

export const { disconnect, setConnected } = authSlice.actions;
export const selectConnected = (state: any) => state.auth.connected;
export const selectUser = (state: any) => state.auth.user;
export const selectToken = (state: any) => state.auth.token;
export default authSlice.reducer;
