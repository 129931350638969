import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ErrorSuccessSnackbar from "./ErrorSuccessSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "../store/uiSlice";
// import { useRouter } from "next/router";
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { getAllAnnonces } from "../requests/annonces";
import { setAllAnnonces } from "../store/annonceSlice";
import { getCurrent } from "../util/getCurrent";
import { disconnect, setConnected } from "../store/authSlice";
import { CartProvider } from "use-shopping-cart";

type Props = {};

const Loader = () => {
  const { isLoading, message } = useSelector(selectLoading);
  return (
    <Backdrop
      open={!!isLoading}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Paper sx={{ p: 2 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography>{message}</Typography>
          <CircularProgress />
        </Box>
      </Paper>
    </Backdrop>
  );
};

const Layout = (props: Props) => {
  const dispatch = useDispatch();
  // const router = useRouter();
  // const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      getCurrent(localStorage.getItem("token") as string).then((res) => {
        console.log(res);
        if (res !== "error") {
          dispatch(
            setConnected({
              data: res,
              token: localStorage.getItem("token") as string,
            })
          );
        } else {
          dispatch(disconnect());
        }
      });
    } else {
      dispatch(disconnect());
    }
  }, [location.pathname]);
  useEffect(() => {
    if (!location.pathname.includes("/tableau-de-bord")) {
      getAllAnnonces().then((res) => {
        if (res !== "error") {
          dispatch(setAllAnnonces(res));
        }
      });
    }
  }, [location.pathname]);

  if (location.pathname.includes("/modification")) {
    return (
      <main>
        <Outlet />
        <ErrorSuccessSnackbar />
        <Loader />
        <ScrollRestoration />
      </main>
    );
  }

  return (
    <>
      <Header />
      <main style={{ backgroundColor: "white" }}>
        {/* {children} */}
        <Outlet />
      </main>
      <Footer />
      <ErrorSuccessSnackbar />
      <Loader />
      <ScrollRestoration />
    </>
  );
};

export default Layout;
