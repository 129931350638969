import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { colorLight, colorPrimary } from "./CustomStyles";
import EditorAccordion from "./EditorAccordion";
import TextInput from "./TextInput";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import { useSelector, useDispatch } from "react-redux";
import { selectAnnonceProp, setAnnonceProp } from "../../store/annonceSlice";
import RichTextEditor from "./RichTextEditor";
import SelectInput from "./SelectInput";
import ElevatorIcon from "@mui/icons-material/Elevator";
import { ChangeEvent } from "react";
import { useParams } from "react-router-dom";
import DisplayInAnnonce from "./DisplayInAnnonce";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaymentIcon from "@mui/icons-material/Payment";
import ParkIcon from "@mui/icons-material/Park";
// import { useRouter } from "next/router";

const InformationsInput = ({ path }: { path: string[] }) => {
  const dispatch = useDispatch();
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const itemProps = useSelector(selectAnnonceProp([...path.slice(1)], {}));
  const classement = ["A", "B", "C", "D", "E", "F"];
  const { typeBien = "maison", ascenseur } = itemProps;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "typeBien"],
        value: event.target.value,
      })
    );
  };
  const handleChangeAscenseur = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === "true" ? true : false;
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "ascenseur", "data"],
        value: value,
      })
    );
  };
  return (
    <Stack>
      <Typography
        component="h5"
        fontSize="1rem"
        sx={{
          textTransform: "uppercase",
          fontWeight: "700",
          backgroundColor: colorLight,
          color: colorPrimary,
          p: 1.5,
        }}
      >
        Informations
      </Typography>
      <Stack spacing={1.5} style={{ padding: 10 }}>
        <FormControl>
          <RadioGroup value={typeBien} onChange={handleChange} row>
            <FormControlLabel
              value="appartement"
              control={<Radio />}
              label="Appartement"
            />
            <FormControlLabel
              value="maison"
              control={<Radio />}
              label="Maison"
            />
            <FormControlLabel
              value="atypique"
              control={<Radio />}
              label="Atypique"
            />
          </RadioGroup>
        </FormControl>
        <EditorAccordion name="Description" icon={<ChatRoundedIcon />}>
          <RichTextEditor path={[...path, "description"]} />
        </EditorAccordion>
        <EditorAccordion name="Localisation" icon={<ChatRoundedIcon />}>
          <TextInput
            path={[...path, "localisation"]}
            label="Localisation"
            defaults={{ color: "text.primary" }}
          />
        </EditorAccordion>
        <EditorAccordion name="Nombre de pièces" icon={<ChatRoundedIcon />}>
          <TextInput
            path={[...path, "nombrePieces"]}
            label="Nombre de pièces"
            defaults={{ color: "text.primary" }}
            type="number"
          />
        </EditorAccordion>
        <EditorAccordion name="Nombre de chambres" icon={<ChatRoundedIcon />}>
          <TextInput
            path={[...path, "nombreChambres"]}
            label="Nombre de chambres"
            defaults={{ color: "text.primary" }}
            type="number"
          />
        </EditorAccordion>
        <EditorAccordion name="Surface habitable" icon={<ChatRoundedIcon />}>
          <TextInput
            path={[...path, "surface"]}
            label="Surface habitable"
            defaults={{ color: "text.primary" }}
            type="number"
          />
        </EditorAccordion>
        <EditorAccordion name="Extérieur" icon={<ParkIcon />}>
          <DisplayInAnnonce
            path={[...path, "exterieur"]}
            label="Mon bien a un extérieur"
          />
          <TextInput
            path={[...path, "exterieur"]}
            label="Extérieur (m²)"
            defaults={{ color: "text.primary" }}
            type="number"
          />
        </EditorAccordion>
        <EditorAccordion name="Prix" icon={<ChatRoundedIcon />}>
          <TextInput
            path={[...path, "prix"]}
            label="Prix"
            defaults={{ color: "text.primary" }}
            type="number"
          />
        </EditorAccordion>
        <EditorAccordion
          name="Année de construction"
          icon={<CalendarMonthIcon />}
        >
          <DisplayInAnnonce
            path={[...path, "anneeConstruction"]}
            label="Afficher dans l'annonce"
          />
          <TextInput
            path={[...path, "anneeConstruction"]}
            label="Année de construction"
            defaults={{ color: "text.primary" }}
            type="number"
          />
        </EditorAccordion>
        <EditorAccordion name="Classe énergie" icon={<ChatRoundedIcon />}>
          <SelectInput
            path={[...path, "classeEnergie", "data"]}
            label="Classe énergie"
            data={classement}
          />
        </EditorAccordion>
        <EditorAccordion name="GES" icon={<ChatRoundedIcon />}>
          <SelectInput
            path={[...path, "ges", "data"]}
            label="GES"
            data={classement}
          />
        </EditorAccordion>
        <EditorAccordion
          name="Mode de chauffage principal"
          icon={<AcUnitOutlinedIcon />}
        >
          <TextInput
            path={[...path, "modeChauffage"]}
            label="Mode de chauffage principal"
            defaults={{ color: "text.primary" }}
          />
        </EditorAccordion>
        <EditorAccordion name="Taxe foncière" icon={<AccountBalanceIcon />}>
          <DisplayInAnnonce
            path={[...path, "taxeFonciere"]}
            label="Afficher dans l'annonce"
          />
          <TextInput
            path={[...path, "taxeFonciere"]}
            label="Taxe foncière"
            defaults={{ color: "text.primary" }}
            type="number"
          />
        </EditorAccordion>
        <EditorAccordion name="Charges annuelles" icon={<PaymentIcon />}>
          <DisplayInAnnonce
            path={[...path, "charges"]}
            label="Afficher dans l'annonce"
          />
          <TextInput
            path={[...path, "charges"]}
            label="Charges annuelles"
            defaults={{ color: "text.primary" }}
            type="number"
          />
        </EditorAccordion>
        {typeBien === "appartement" ? (
          <>
            <EditorAccordion name="Ascenseur" icon={<ElevatorIcon />}>
              <FormControl>
                <RadioGroup
                  value={ascenseur.data}
                  onChange={handleChangeAscenseur}
                  row
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </EditorAccordion>
            <EditorAccordion name="Étage" icon={<ElevatorIcon />}>
              <TextInput
                path={[...path, "etage"]}
                label="Étage"
                defaults={{ color: "text.primary" }}
                type="number"
              />
            </EditorAccordion>
          </>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default InformationsInput;
