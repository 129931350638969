import { disconnect } from "../store/authSlice";
import store from "../store/store";
import { formioRequest } from "./formioRequest";

export const getDisponibilites = async () => {
  let res;
  try {
    res = await formioRequest({
      url: `/650ae0154a8a916f7a2dffc7/submission`,
      method: "GET",
      headers: {
        "x-jwt-token": localStorage.getItem("token") as string,
      },
      // headers: {
      //   "x-jwt-token": store.getState().auth.token,
      // },
    });
    if (res.status === 200 && res.data.length > 0) {
      return res.data[0];
    } else if (res.status === 440) {
      store.dispatch(disconnect());
    } else {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};

export const putDisponibilites = async (dispos: any) => {
  try {
    const res = await formioRequest({
      url: `/650ae0154a8a916f7a2dffc7/submission/${dispos._id}`,
      method: "PUT",
      data: JSON.stringify({ data: dispos.data }),
      headers: {
        "x-jwt-token": localStorage.getItem("token") as string,
        "Content-Type": "application/json",
      },
      // headers: {
      //   "x-jwt-token": store.getState().auth.token,
      // },
    });
    if (res.status === 200) {
      return "ok";
    } else if (res.status === 440) {
      store.dispatch(disconnect());
    } else {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};

export const newDispos = async (token: string) => {
  try {
    const res = await formioRequest({
      url: `/650ae0154a8a916f7a2dffc7/submission`,
      method: "POST",
      data: JSON.stringify({
        data: {
          dureeVisiteVirtuelle: "00:00",
          dureeVisitePlace: "00:00",
          creneaux: [],
        },
      }),
      headers: {
        "x-jwt-token": token,
        "Content-Type": "application/json",
      },
      // headers: {
      //   "x-jwt-token": store.getState().auth.token,
      // },
    });
    if (res.status === 201) {
      return "ok";
    } else if (res.status === 440) {
      store.dispatch(disconnect());
    } else {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};
