import { useEffect, useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import {
  selectAllAnnonces,
  selectAnnonceById,
  selectAnnoncePropOutData,
  setAnnonceProp,
} from "../../store/annonceSlice";
import { useDispatch } from "react-redux";
// import Link from "next/link";
import {
  Button,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Box,
  Typography,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { selectUiProp, setLoading } from "../../store/uiSlice";
import { setMessage } from "../../store/errorSuccessSlice";
import { putAnnonce } from "../../requests/annonces";
import { Link } from "react-router-dom";
import { Dropzone } from "@files-ui/react";
import ImageInput from "../EditorComponent/ImageInput";

type Props = {};

const AjoutChangeAnnonce = ({}: Props) => {
  const dispatch = useDispatch();
  const titre: any = useSelector(selectAnnoncePropOutData(["titre"], ""));
  const published: any = useSelector(
    selectAnnoncePropOutData(["annoncePubliee"], false)
  );
  const id = useSelector(selectUiProp("currentAnnonceId"));
  const annonce = useSelector((state) => selectAnnonceById(state, id));

  const handleClick = async () => {
    dispatch(setLoading({ isLoading: true }));
    fetch("https://48heklt6di.execute-api.eu-west-1.amazonaws.com/dev/photo", {
      method: "POST",
      body: JSON.stringify({
        annonceId: id,
        type: "direct-proprietaire",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const linkSource = `data:image/png;base64,${res.image}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = "direct-proprietaire.png";
        downloadLink.click();
        dispatch(setLoading({ isLoading: false }));
      })
      .catch((err) => {
        dispatch(setLoading({ isLoading: false }));
        dispatch(
          setMessage({ type: "error", message: "Une erreur est survenue" })
        );
      });
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>, path: string) => {
    dispatch(
      setAnnonceProp({
        id: id,
        path: [path],
        value:
          path === "annoncePubliee" ? event.target.checked : event.target.value,
      })
    );
  };

  const handleSave = () => {
    dispatch(setLoading({ isLoading: true }));
    putAnnonce(id, annonce).then((res) => {
      if (res === "ok") {
        dispatch(setLoading({ isLoading: false }));
        dispatch(setMessage({ message: "Titre enregistré", type: "success" }));
      } else {
        dispatch(setLoading({ isLoading: false }));
        dispatch(
          setMessage({ message: "Une erreur est survenue", type: "error" })
        );
      }
    });
  };

  return (
    <Container maxWidth="lg">
      <Stack spacing={4}>
        <FormControl sx={{ mt: 2.5 }}>
          <FormLabel>Photo qui s'affiche lors de la recherche</FormLabel>
          <ImageInput
            path={[]}
            editAltText={false}
            editLayout={false}
            type="thumbnail"
          />
          <TextField
            label="Titre"
            sx={{
              mt: 4,
              "& label": { fontSize: "1.4rem" },
              "& fieldset > legend ": { fontSize: "calc(0.75 * 1.4rem)" },
              "& input": { fontSize: "1.4rem" },
            }}
            variant="outlined"
            value={titre}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(e, "titre")
            }
            helperText={
              <Typography>Exemple: Appartement 3 pièces Lyon</Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={published}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, "annoncePubliee")
                }
              />
            }
            label="Annonce publiée"
          />
          <Box textAlign="center">
            <Button variant="contained" onClick={handleSave} sx={{ flex: 1 }}>
              Enregistrer
            </Button>
          </Box>
        </FormControl>
        <Button variant="outlined" component={Link} to={`/modification/${id}`}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Modifier l'annonce
        </Button>
        <Button variant="outlined" onClick={handleClick}>
          Générer image
        </Button>
      </Stack>
    </Container>
  );
};

export default AjoutChangeAnnonce;
