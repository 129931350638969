import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { FormControl, Typography } from '@mui/material';
import FormatBoldIcon from "@mui/icons-material/FormatBold";

const options = [[100, "Mince"],
[200, "Ultra-léger"],
[300, "Léger"],
[400, "Normal"],
[500, "Moyen"],
[600, "Semi-gras"],
[700, "Gras"],
[800, "Extra-gras"],
[900, "Noir"],
[950, "Extra-noir"]]

export default function FontweightInputSelect(props: SelectProps) {
    return (
        <FormControl size="small">
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                {...props}
                renderValue={() => <FormatBoldIcon sx={{ verticalAlign: "text-bottom" }} />}
            >
                {options.map((option) => <MenuItem key={option[1]} value={option[0]}><Typography sx={{ fontWeight: option[0] }}>{option[1]}</Typography></MenuItem>)}
            </Select>
        </FormControl>
    );
}

