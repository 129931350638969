import { Stack, Typography } from "@mui/material";
import { colorLight, colorPrimary } from "./CustomStyles";
import EditorAccordion from "./EditorAccordion";
import TextInput from "./TextInput";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import TextFormating from "./TextFormating";
import { useSelector, useDispatch } from "react-redux";
import { selectAnnonceProp, setAnnonceProp } from "../../store/annonceSlice";
import GridImage from "./GridImage";
import { useParams } from "react-router-dom";
// import { useRouter } from "next/router";

const PresentationItemInput = ({ path }: { path: string[] }) => {
  const dispatch = useDispatch();
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const itemProps = useSelector(selectAnnonceProp([...path.slice(1)], {}));
  const { showImageOnly = false } = itemProps;

  const setShowImageOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "showImageOnly"],
        value: event.target.checked,
      })
    );
  };

  const handleHrefChange = (e: any) =>
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "more", "href"],
        value: e.target.value,
      })
    );
  const data = [
    {
      image: null,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu tristique massa. Nulla rhoncus ullamcorper luctus.",
    },
  ];

  return (
    <Stack>
      <Typography
        component="h5"
        fontSize="1rem"
        sx={{
          textTransform: "uppercase",
          fontWeight: "700",
          backgroundColor: colorLight,
          color: colorPrimary,
          p: 1.5,
        }}
      >
        Pièces
      </Typography>
      <Stack spacing={1.5} style={{ padding: 10 }}>
        <EditorAccordion name="Titre principal" icon={<ChatRoundedIcon />}>
          <TextInput
            path={[...path, "description", "title"]}
            label="Titre"
            defaults={{ color: "primary.main" }}
          />
          <TextFormating
            path={[...path, "description", "title"]}
            defaultAlign="left"
            defaultWeight="700"
          />
        </EditorAccordion>
        <EditorAccordion name="Sous-titre" icon={<ChatRoundedIcon />}>
          <TextInput
            path={[...path, "description", "subtitle"]}
            label="Sous-titre"
            defaults={{ color: "text.primary" }}
          />
          <TextFormating
            path={[...path, "description", "subtitle"]}
            defaultAlign="left"
            defaultWeight="700"
          />
        </EditorAccordion>
        <EditorAccordion name="Description" icon={<ChatRoundedIcon />}>
          <TextInput
            path={[...path, "description", "text"]}
            inputProps={{ multiline: true, maxRows: 5 }}
            label="Description"
            defaults={{ color: "text.secondary" }}
          />
          <TextFormating
            path={[...path, "description", "text"]}
            defaultAlign="left"
            defaultWeight="400"
          />
        </EditorAccordion>
        <EditorAccordion name="Images" icon={<InsertPhotoOutlinedIcon />}>
          <GridImage path={[...path, "images"]} />
          {/* <FormControlLabel
            control={
              <CustomSwitch
                checked={showImageOnly}
                onChange={setShowImageOnly}
              />
            }
            label="Masquer le texte"
            sx={{ mb: 1, ml: "-3px" }}
          /> */}
          {/* <DisplayElement path={[...path.slice(1), "image"]} element="image" /> */}
        </EditorAccordion>
        {/* <EditorAccordion name="Bouton" icon={<AddIcon />}>
          <DisplayElement path={[...path.slice(1), "more"]} element="button" />
          <TextField
            sx={{ mt: 2 }}
            value={itemProps.more?.href || ""}
            label="Lien personnalisé"
            onChange={handleHrefChange}
          />
        </EditorAccordion> */}
      </Stack>
    </Stack>
  );
};

export default PresentationItemInput;
