/* eslint-disable no-lone-blocks */
import React, { useEffect, useRef, useState } from "react";
import { getReponses } from "../../requests/reponses";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../store/errorSuccessSlice";
import AcheteurCard from "./AcheteurCard";
import { selectUser } from "../../store/authSlice";
import Pagination from "../Pagination";
import { Box } from "@mui/material";
import usePagination from "../../hooks/usePagination";
import FilterReponses from "./FilterReponses";
import useReponsesFilter from "../../hooks/useReponsesFilter";
import { getRdv } from "../../requests/rdv";
import { setLoading } from "../../store/uiSlice";

type Props = {};

const AcheteursPotentiels = (props: Props) => {
  const dispatch = useDispatch();
  const [reponsesData, setReponsesData] = useState<any>([]);
  const [reponses, setReponses] = useState<any>([]);
  const [filters, setFilters] = useState<string[]>([]);
  const user = useSelector(selectUser);
  const filteredReponses = useReponsesFilter(filters, reponses);
  const wrapperRef = useRef(null);
  const displayReponses = usePagination(filteredReponses);

  useEffect(() => {
    if (user !== undefined) {
      getReponses(user?._id)
        .then((res) => {
          if (res !== "error") {
            setReponsesData(res);
          } else {
            dispatch(
              setMessage({
                type: "error",
                message: "Une erreur est survenue",
              })
            );
          }
        })
        .catch(() =>
          dispatch(
            setMessage({
              type: "error",
              message: "Une erreur est survenue",
            })
          )
        );
    }
  }, [user]);

  useEffect(() => {
    dispatch(setLoading({ isLoading: true }));
    if (reponsesData.length > 0) {
      const reponsesAvecRdv = reponsesData.map((reponse: any) => {
        //if prop is id getRdv
        if (
          reponse?.data.rdvVirtuel !== "" &&
          typeof reponse.data.rdvVirtuel === "string"
        ) {
          getRdv(reponse.data.rdvVirtuel).then((res) => {
            if (res !== "error") {
              reponse.data.rdvVirtuel = { id: res._id, ...res.data };
            }
          });
        }
        if (
          reponse?.data.rdvSurplace !== "" &&
          typeof reponse.data.rdvSurplace === "string"
        ) {
          getRdv(reponse.data.rdvSurplace).then((res) => {
            if (res !== "error") {
              reponse.data.rdvSurplace = { id: res._id, ...res.data };
            }
          });
        }
        return reponse;
      });
      setTimeout(() => {
        setReponses(reponsesAvecRdv);
        dispatch(setLoading({ isLoading: false }));
      }, 1000);
    } else {
      dispatch(setLoading({ isLoading: false }));
    }
  }, [reponsesData]);

  return reponses?.length > 0 ? (
    <>
      <FilterReponses checked={filters} setChecked={setFilters} />
      <Pagination
        data={reponses}
        props={{
          sx: {
            "& .MuiPaginationItem-root:hover, & .MuiPaginationItem-root.Mui-selected, , & .MuiPaginationItem-root.Mui-selected:hover":
              {
                backgroundColor: "transparent",
                color: "primary.main",
              },
            "& .MuiPaginationItem-root": {
              fontWeight: "500",
            },
          },
          hideNextButton: true,
          hidePrevButton: true,
        }}
        wrapper={wrapperRef}
        numberPerPage={5}
      />
      <Box ref={wrapperRef}>
        {displayReponses.map((reponse: any, index: number) => (
          <AcheteurCard
            reponse={reponse}
            key={reponse._id}
            ownerId={user?._id}
            index={index}
            setReponses={setReponses}
            // render={render}
          />
        ))}
      </Box>
      <Pagination
        data={reponses}
        props={{
          sx: {
            "& .MuiPaginationItem-root:hover, & .MuiPaginationItem-root.Mui-selected, , & .MuiPaginationItem-root.Mui-selected:hover":
              {
                backgroundColor: "transparent",
                color: "primary.main",
              },
            "& .MuiPaginationItem-root": {
              fontWeight: "500",
            },
            mb: 5,
          },
          hideNextButton: true,
          hidePrevButton: true,
        }}
        wrapper={wrapperRef}
        numberPerPage={5}
      />
    </>
  ) : null;
};

// const ImageDisplay = ({ url }: { url: string }) => {
//   return (
//     <TransformWrapper>
//       {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
//         <>
//           <div className="tools">
//             <button onClick={() => zoomIn()}>+</button>
//             <button onClick={() => zoomOut()}>-</button>
//             <button onClick={() => resetTransform()}>x</button>
//           </div>
//           <TransformComponent>
//             <img src={url} style={{ maxWidth: "700px" }} alt="document" />
//           </TransformComponent>
//         </>
//       )}
//     </TransformWrapper>
//   );
// };

// const [doc, setDoc] = useState<any>();
// const [url, setUrl] = useState<string>("");
// const [displayDoc, setDisplayDoc] = useState(false);

//   useEffect(() => {
//     const user = JSON.parse(localStorage.getItem("formioUser") as string);
//     setUser(user);
//   }, [localStorage.getItem("formioUser")]);
// const render = async (path: string) => {
//   const doc = await renderDoc(path);
//   if (doc !== "error") {
//     setDoc(doc.data);
//     setUrl(doc.url);
//     setDisplayDoc(true);
//   } else if (doc === "error") {
//     dispatch(
//       setMessage({
//         message: "L'utilisateur a supprimé ce document",
//         type: "error",
//       })
//     );
//     // setType("error");
//     // setMessage("L'utilisateur a supprimé ce document");
//   }
// };

{
  /* <Backdrop open={displayDoc}>
        <Paper sx={{ p: 2 }}>
          <Box display="flex" justifyContent="flex-end">
            <ClearIcon
              onClick={() => setDisplayDoc(false)}
              sx={{ "&:hover": { cursor: "pointer" } }}
            />
          </Box>
          {doc === "application/pdf" ? (
            <PdfReader url={url} />
          ) : doc?.includes("image") ? (
            <ImageDisplay url={url} />
          ) : null}
        </Paper>
      </Backdrop> */
}

export default AcheteursPotentiels;
