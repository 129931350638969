import {
  S3Client,
  ListObjectsV2Command,
  GetObjectCommand,
  DeleteObjectCommand,
  PutObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const accessKey = process.env.REACT_APP_ACCESSKEY || "";
const secret = process.env.REACT_APP_SECRET || "";
const s3Configuration = {
  credentials: {
    accessKeyId: accessKey,
    secretAccessKey: secret,
  },
  region: "eu-west-1",
};

const S3client = new S3Client(s3Configuration);

export const getDocs = async (ownerId: string) => {
  const params = {
    Bucket: "form-io-eu-west-1",
    Prefix: `direct-proprietaire/mes-docs/${ownerId}`,
  };

  const command = new ListObjectsV2Command(params);

  const response = await S3client.send(command);

  return response.Contents;
};

export const renderDoc = async (path: string) => {
  const command = new GetObjectCommand({
    Bucket: "form-io-eu-west-1",
    Key: path,
  });

  try {
    const url = await getSignedUrl(S3client, command, { expiresIn: 10800 });
    const data = await S3client.send(command);
    return { url, data: data.ContentType };
  } catch (error) {
    console.log("sdfg", error);
    return "error";
  }
};

export const deleteDoc = async (path: string) => {
  const command = new DeleteObjectCommand({
    Bucket: "form-io-eu-west-1",
    Key: path,
  });

  try {
    await S3client.send(command);
    return "ok";
  } catch (err) {
    console.error(err);
  }
};

export const uploadImage = async ({
  fileName,
  file,
  type = "annonce",
  annonceId,
}: {
  fileName: string;
  file: any;
  type?: string;
  annonceId?: string;
}) => {
  const body = await file.file.arrayBuffer();
  if (type === "annonce") {
    const command = new PutObjectCommand({
      Bucket: "form-io-eu-west-1",
      Key: `direct-proprietaire/annonces/${annonceId}/${fileName}`,
      Body: body,
      ContentType: file.type,
    });
    try {
      await S3client.send(command);
      return `direct-proprietaire/annonces/${annonceId}/${fileName}`;
    } catch (err) {
      console.error(err);
    }
  } else if (type === "blog") {
    const command = new PutObjectCommand({
      Bucket: "form-io-eu-west-1",
      Key: `direct-proprietaire/blog/${fileName}`,
      Body: body,
      ContentType: file.type,
    });
    console.log("sdfg", fileName);
    try {
      await S3client.send(command);
      return `direct-proprietaire/blog/${fileName}`;
    } catch (err) {
      console.error(err);
    }
  }
};

export const checkFile = async (annonceId: string, name: string) => {
  const params = {
    Bucket: "form-io-eu-west-1",
    Prefix: `direct-proprietaire/annonces/${annonceId}/${name}`,
  };

  const command = new ListObjectsV2Command(params);

  const response = await S3client.send(command);
  return response;
};
