import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  colorLight,
  colorPrimary,
  CustomLabel,
  CustomRadio,
} from "./CustomStyles";
import { selectAnnonceProp, setAnnonceProp } from "../../store/annonceSlice";
import { useParams } from "react-router-dom";
// import { useRouter } from "next/router";

type Props = {
  path: string[];
};

const PresentationInput = ({ path }: Props) => {
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const { layout, respLayout }: any = useSelector(
    selectAnnonceProp(path.slice(1))
  );
  const dispatch = useDispatch();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "layout"],
        value: (event.target as HTMLInputElement).value,
      })
    );
  };
  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "respLayout"],
        value: (event.target as HTMLInputElement).checked,
      })
    );
  };
  return (
    <Stack>
      <Typography
        component="h5"
        fontSize="1rem"
        sx={{
          textTransform: "uppercase",
          fontWeight: "700",
          backgroundColor: colorLight,
          color: colorPrimary,
          p: 1.5,
        }}
      >
        Paramètres section
      </Typography>
      <FormControl sx={{ p: 1.5 }}>
        <CustomLabel id="demo-controlled-radio-buttons-group">
          Placement images / textes
        </CustomLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={layout || "alternate"}
          onChange={handleChange}
        >
          <FormControlLabel
            value="alternate"
            control={<Radio sx={CustomRadio} />}
            label="Alterner l'ordre"
          />
          <FormControlLabel
            value="images-left"
            control={<Radio sx={CustomRadio} />}
            label="Images à gauche"
          />
          <FormControlLabel
            value="images-right"
            control={<Radio sx={CustomRadio} />}
            label="Texte à gauche"
          />
        </RadioGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={!(respLayout === false)}
              onChange={handleCheck}
              inputProps={{ "aria-label": "mobile: text always before" }}
              sx={CustomRadio}
            />
          }
          label="Sur une colonne, toujours afficher le texte d'abord"
        />
      </FormControl>
    </Stack>
  );
};

export default PresentationInput;
