export const getPresentationItem = (index) => ({
  description: {
    title: { data: `Mon titre ${index}` },
    subtitle: { data: `Mon sous-titre ${index}` },
    text: {
      data: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus suscipit tortor eget felis porttitor volutpat. Sed porttitor lectus nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.`,
    },
  },
  image: { data: "https://picsum.photos/600/300" },
  more: {},
});
