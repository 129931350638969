import { createSlice } from "@reduxjs/toolkit";

export interface errorSuccess {
  message: string | null;
  type: "success" | "error" | null;
}

export const errorSuccessSlice = createSlice({
  name: "errorSuccess",
  initialState: {
    message: null,
    type: null,
  },
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    reset: (state, action) => {
      state.message = null;
      state.type = null;
    },
  },
});

export const { setMessage, reset } = errorSuccessSlice.actions;
export const selectMessage = ({
  errorSuccess,
}: {
  errorSuccess: errorSuccess;
}) => errorSuccess.message;
export const selectType = ({ errorSuccess }: { errorSuccess: errorSuccess }) =>
  errorSuccess.type;
export default errorSuccessSlice.reducer;
