import { useLayoutEffect, useState, useEffect, PropsWithChildren } from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { Box, Breakpoint, Container, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectUiProp, setUiProp } from "../store/uiSlice";
import { useElementSize } from "../hooks/useElementSize";
import themes, { createEditTheme } from "../theme";
// import { Poppins } from "next/font/google";

// const poppins = Poppins({
//   weight: ["300", "400", "700"],
//   subsets: ["latin"],
//   display: "swap",
//   fallback: ["sans-serif"],
// });

const defaultTheme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: themes.default.palette,
    // typography: {
    //   fontFamily: poppins.style.fontFamily,
    // },
  }),
  {
    breakpoints: ["xs", "sm", "md", "lg", "xl"],
    factor: 4,
  }
);

export default function Wrapper({
  children,
  originalTheme,
}: PropsWithChildren<{ originalTheme: string }>) {
  const dispatch = useDispatch();
  const device = useSelector(selectUiProp("currentDevice"));
  const contentScale = useSelector(selectUiProp("contentScale"));
  const [squareRef, { width }] = useElementSize();
  const [maxWidth, setMaxWidth] = useState<false | "sm" | "lg">(() => false);
  //   const [contentScale, setContentScale] = useState(() =>
  //     maxWidth && width
  //       ? Math.max(1, width / theme.breakpoints.values[maxWidth])
  //       : 1
  //   );
  const [themeScale, setThemeScale] = useState(() => 1); // window.innerWidth / width
  const [theme, setTheme] = useState(() => defaultTheme);
  useEffect(() => {
    setMaxWidth(device === "phone" ? "sm" : device === "tablet" ? "lg" : false);
  }, [device]);
  useLayoutEffect(
    () => {
      setThemeScale(
        window.innerWidth /
          (maxWidth ? defaultTheme.breakpoints.values[maxWidth] : width)
      );
      dispatch(
        setUiProp({
          prop: "contentScale",
          value:
            maxWidth && width
              ? Math.min(1, width / defaultTheme.breakpoints.values[maxWidth])
              : 1,
        })
      );
      //   setContentScale(
      //     maxWidth && width
      //       ? Math.min(1, width / defaultTheme.breakpoints.values[maxWidth])
      //       : 1
      //   );
    },
    typeof window !== "undefined"
      ? [width, maxWidth, window.innerWidth]
      : [width, maxWidth]
  );

  useLayoutEffect(() => {
    const theme = createEditTheme({
      primary: themes[originalTheme].palette.primary.main,
      themeScale,
    });
    // const theme = responsiveFontSizes(
    //   createTheme({
    //     breakpoints: {
    //       values: {
    //         xs: 0,
    //         sm: 601 * themeScale,
    //         md: 901 * themeScale,
    //         lg: 1201 * themeScale,
    //         xl: 1537 * themeScale,
    //       },
    //     },
    //     palette: themes[originalTheme].palette,
    //     typography: {
    //       fontFamily: roboto.style.fontFamily,
    //       h1: {
    //         fontSize: "6rem",
    //         fontWeight: 300,
    //       },
    //     },
    //   }),
    //   {
    //     breakpoints: ["xs", "sm", "md", "lg", "xl"].slice(0, 3) as Breakpoint[],
    //     factor: 4,
    //   }
    // );
    console.log("WrapperTheme", theme);
    setTheme(theme);
  }, [themeScale, originalTheme]);
  console.log({ themeScale, width });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Container disableGutters maxWidth={maxWidth}>
        <Box ref={squareRef}>
          <ThemeProvider theme={theme}>
            <Box
              width={
                maxWidth ? defaultTheme.breakpoints.values[maxWidth] : "100%"
              }
              sx={{
                transformOrigin: "0 0 0",
                transform: `scale(${contentScale - 0.001})`,
              }}
            >
              {children}
            </Box>
          </ThemeProvider>
        </Box>
      </Container>
    </Box>
  );
}
