import { loginRequest } from "../requests/login";
import {
  Button,
  Container,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

type Props = {};

const Connexion = (props: Props) => {
  const dispatch = useDispatch();
  // const router = useRouter();
  const [login, setLogin] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleLogin = async () => {
    if (!login.email.match(mailformat)) {
      setError("Veuillez renseigner une adresse email valide");
      return;
    } else if (!login.password) {
      setError("Veuillez rentrer un mot de passe");
    } else {
      const res = await loginRequest(login.email, login.password);
      if (res !== "error") {
        navigate("/tableau-de-bord");
      } else {
        setError(
          "Connexion impossible :  vérifier l'adresse mail et le mot de passe"
        );
      }
    }
  };

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ your logic here
        handleLogin();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [login]);

  return (
    <Container
      maxWidth="sm"
      sx={{ mt: 4, textAlign: "center", mb: 2 }}
      className={"login"}
    >
      {error ? <Typography>{error}</Typography> : null}

      <Stack spacing={3} sx={{ mt: 4 }}>
        <FormControl>
          <TextField
            value={login.email}
            label="Adresse email"
            type="email"
            autoComplete="email"
            onChange={(e) => {
              setLogin((state) => ({ ...state, email: e.target.value }));
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            value={login.password}
            label="Mot de passe"
            type="password"
            autoComplete="current-password"
            onChange={(e) => {
              setLogin((state) => ({ ...state, password: e.target.value }));
            }}
          />
        </FormControl>
        <Button variant="contained" onClick={handleLogin}>
          Se connecter
        </Button>
      </Stack>
    </Container>
  );
};

export default Connexion;
