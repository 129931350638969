/* eslint-disable react/jsx-key */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import AlignInputSelect from "./AlignInputSelect";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FontweightInputSelect from "./FontweightInputSelect";
import TextDecorationLineInputSelect from "./TextDecorationLineInputSelect";
import ColorSelectInput from "./ColorSelectInput";
import ImageIcon from "@mui/icons-material/Image";
import SubjectIcon from "@mui/icons-material/Subject";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import GradientIcon from "@mui/icons-material/Gradient";
import OpacityIcon from "@mui/icons-material/Opacity";
import TungstenIcon from "@mui/icons-material/Tungsten";
import { Layer } from "../AnnonceComponents/HeroSection";
import { CustomRadio, CustomSlider } from "./CustomStyles";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SpeedIcon from "@mui/icons-material/Speed";
import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HeightIcon from "@mui/icons-material/Height";
import TypoVariantInputSelect from "./TypoVariantInputSelect";
import TypoComponentInputSelect from "./TypoComponentInputSelect";
import { selectAnnonceProp, setAnnonceProp } from "../../store/annonceSlice";
import ImageInput from "./ImageInput";
import { useParams } from "react-router-dom";
// import { useRouter } from "next/router";

const defaultVariantMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "h6",
  subtitle2: "h6",
  body1: "p",
  body2: "p",
  inherit: "p",
};

type Filter = [
  label: string,
  prop: string,
  defaultValue: number,
  icon: any,
  maxValue: number
];

const filters: Filter[] = [
  ["Nuances de gris", "grayscale", 0, <GradientIcon />, 100],
  ["Opacité", "opacity", 100, <OpacityIcon />, 100],
  [
    "Luminosité",
    "brightness",
    100,
    <TungstenIcon sx={{ transform: "rotate(180deg)" }} />,
    150,
  ],
  ["Flou", "blur", 0, <BlurOnIcon />, 20],
];

const ParallaxInput = ({
  path,
  source = "page",
}: {
  path: string[];
  source?: string;
}) => {
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();

  const dispatch = useDispatch();
  const props: any = useSelector(selectAnnonceProp(path.slice(1), {}));
  const { layers } = props;

  const setProp: (propPath: (string | number)[], value: any) => void = (
    propPath,
    value
  ) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", "hero", "layers", ...propPath],
        value,
      })
    );
  };
  // const swapLayers = (i1: any, i2: any) => () => {
  //   let newLayers: Layer[] = [...layers];
  //   [newLayers[i1], newLayers[i2]] = [newLayers[i2], newLayers[i1]];
  //   setProp([], [...newLayers]);
  // };
  const [layerIndex, setLayerIndex] = useState(0);
  const [content, setContent] = useState(1);
  const handleLayerIndexChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setLayerIndex(newValue);
    if (newValue === 0) {
      setContent(1);
    } else if (newValue === 1) {
      setContent(0);
    }
  };
  // const handleContentChange = (
  //   event: React.SyntheticEvent,
  //   newValue: number
  // ) => {
  //   setContent(newValue);
  // };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenWidthMenu = (event: any) => setAnchorEl(event.currentTarget);
  const handleCloseWidthMenu = (value: any) => () => {
    value !== undefined && setProp([layerIndex, "maxWidth"], value);
    setAnchorEl(null);
  };

  console.log("Parallax input", { layers, path, props });

  return (
    <Stack direction="column" spacing={2} m={2}>
      <Tabs
        value={layerIndex}
        onChange={handleLayerIndexChange}
        aria-label="basic tabs example"
      >
        {/* {(layers || []).map((layer: any, index: number) => (
          <Tab label={`Couche ${index + 1}`} />
        ))} */}
        <Tab label="Texte " />
        <Tab label="Image de fond" />
      </Tabs>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        // keepMounted
        open={!!anchorEl}
        onClose={handleCloseWidthMenu(undefined)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {[
          ["Largeur maximale du contenu"],
          ["xs", "444 pixels"],
          ["sm", "600 pixels"],
          ["md", "900 pixels"],
          ["lg", "1200 pixels"],
          ["xl", "1536 pixels"],
          [false, "Pleine largeur"],
        ].map(([w, label], index) =>
          index === 0 ? (
            <MenuItem key={(w || label) as string} disabled>
              {w}
            </MenuItem>
          ) : (
            <MenuItem
              key={(w || label) as string}
              selected={layers[layerIndex]?.maxWidth === w}
              onClick={handleCloseWidthMenu(w)}
            >
              {label}
            </MenuItem>
          )
        )}
      </Menu>
      {(layers.filter((_: any, i: number) => i === layerIndex) || []).map(
        (layer: any) => (
          <Stack spacing={2}>
            <FormControl size="small" fullWidth>
              <FormGroup row>
                <ToggleButton
                  value="visible"
                  size="small"
                  selected={!(layer.hidden === true)}
                  onChange={() =>
                    setProp(
                      [layerIndex, "hidden"],
                      layer.hidden === true ? false : true
                    )
                  }
                >
                  <VisibilityIcon />
                </ToggleButton>
                <IconButton
                  sx={{
                    width: 42,
                    border: "1px solid rgb(187, 187, 187)",
                    borderRadius: 0,
                  }}
                  onClick={handleOpenWidthMenu}
                >
                  <HeightIcon sx={{ transform: "rotate(90deg)" }} />
                </IconButton>
                <TextField
                  id="outlined-start-adornment"
                  type="number"
                  sx={{ width: "15ch", height: 42 }}
                  inputProps={{ style: { height: 25 }, min: -50, max: 0 }}
                  value={layer.speed || 0}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SpeedIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    setProp(
                      [layerIndex, "speed"],
                      Math.max(-50, Math.min(0, parseInt(e.target.value)))
                    )
                  }
                />
                {/* <IconButton
                  size="small"
                  sx={{
                    width: 42,
                    border: "1px solid rgb(187, 187, 187)",
                    borderRadius: 0,
                  }}
                  disabled={layerIndex === 0}
                  onClick={swapLayers(layerIndex, layerIndex - 1)}
                >
                  <ArrowUpwardIcon />
                </IconButton> */}
                {/* <IconButton
                  sx={{
                    width: 42,
                    border: "1px solid rgb(187, 187, 187)",
                    borderRadius: 0,
                  }}
                  disabled={layerIndex === layers.length - 1}
                  onClick={swapLayers(layerIndex, layerIndex + 1)}
                >
                  <ArrowDownwardIcon />
                </IconButton> */}
                {/* <IconButton
                  sx={{
                    width: 42,
                    border: "1px solid rgb(187, 187, 187)",
                    borderRadius: 0,
                  }}
                  // disabled={layer.texts!.length === 1}
                  onClick={() => {
                    setProp(
                      [],
                      layers.filter((_: any, i: number) => i !== layerIndex)
                    );
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton> */}
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" fullWidth size="small">
              <FormLabel component="legend">Mise en page</FormLabel>
              <RadioGroup
                row
                aria-label="Mise en page"
                name="layout"
                value={layer.layout}
                onChange={(e) =>
                  setProp([layerIndex, "layout"], e.target.value)
                }
                sx={{ pt: 1 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid gray",
                    borderRadius: 2,
                    pr: "12px",
                    mr: "4px",
                  }}
                >
                  <FormControlLabel
                    sx={{ m: 0 }}
                    value="image-left"
                    control={<Radio size="small" />}
                    label=""
                  />
                  <ImageIcon />
                  <SubjectIcon sx={{ color: "white" }} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid gray",
                    borderRadius: 2,
                    pr: "4px",
                    mr: "4px",
                  }}
                >
                  <FormControlLabel
                    sx={{ m: 0 }}
                    value="image-right"
                    control={<Radio size="small" />}
                    label=""
                  />
                  <SubjectIcon sx={{ color: "white" }} />
                  <ImageIcon />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid gray",
                    borderRadius: 2,
                    pr: "4px",
                    mr: "4px",
                  }}
                >
                  <FormControlLabel
                    sx={{ m: 0 }}
                    value="text"
                    control={<Radio size="small" />}
                    label=""
                  />
                  <SubjectIcon />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid gray",
                    borderRadius: 2,
                    pr: "4px",
                    mx: 0,
                  }}
                >
                  <FormControlLabel
                    sx={{ m: 0 }}
                    value="image"
                    control={<Radio size="small" />}
                    label=""
                  />
                  <ImageIcon />
                </Box>
              </RadioGroup>
            </FormControl>
            <Tabs
              variant="fullWidth"
              value={content}
              // onChange={handleContentChange}
              aria-label="basic tabs example"
            >
              {layerIndex === 0 ? <Tab label="Texte" /> : null}
              {layerIndex === 1 ? <Tab label="Image" /> : null}
            </Tabs>
            {content === 0 ? (
              <>
                <ImageInput
                  path={["data", "hero", "layers", layerIndex, "image", "src"]}
                  editAltText={false}
                  editLayout={false}
                />
                {layer.image ? (
                  <Stack spacing={3} sx={{ pt: 4, mr: 2 }}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Affichage
                      </FormLabel>
                      <RadioGroup
                        row
                        value={layer.image.style?.objectFit || "cover"}
                        onChange={(e) =>
                          setProp(
                            [layerIndex, "image", "style", "objectFit"],
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="cover"
                          control={<Radio />}
                          label="Couvrir"
                        />
                        <FormControlLabel
                          value="contain"
                          control={<Radio />}
                          label="Contenir"
                        />
                      </RadioGroup>
                    </FormControl>
                    {filters.map(
                      ([label, prop, defaultValue, icon, maxValue]) => (
                        <Box
                          display="flex"
                          alignItems="center"
                          width="100%"
                          key={label}
                        >
                          {icon}
                          <Typography
                            sx={{ ml: 1, flex: 1.5, textAlign: "left" }}
                          >
                            {label}
                          </Typography>
                          <CustomSlider
                            size="small"
                            value={
                              (layer.image?.filter ?? {})[prop] !== undefined
                                ? (layer.image?.filter ?? {})[prop]
                                : defaultValue
                            }
                            valueLabelDisplay="on"
                            max={maxValue}
                            step={Math.max(1, Math.floor(maxValue / 100))}
                            sx={CustomRadio}
                            style={{ width: "90%", flex: 1 }}
                            onChangeCommitted={(_, newValue) =>
                              setProp(
                                [layerIndex, "image", "filter", prop],
                                newValue
                              )
                            }
                          />
                        </Box>
                      )
                    )}
                  </Stack>
                ) : null}
              </>
            ) : (
              <>
                {layer.texts
                  ? layer.texts.map((line: any, lineIndex: number) => (
                      <Stack direction="column">
                        <Stack direction="row">
                          <FormControl size="small">
                            <FormGroup row>
                              <TypoVariantInputSelect
                                value={line.variant || "body1"}
                                onChange={(e) =>
                                  setProp(
                                    [layerIndex, "texts", lineIndex, "variant"],
                                    e.target.value
                                  )
                                }
                              />
                              <TypoComponentInputSelect
                                value={
                                  line.component ||
                                  defaultVariantMapping[
                                    (line.variant as keyof typeof defaultVariantMapping) ??
                                      "inherit"
                                  ]
                                }
                                onChange={(e) =>
                                  setProp(
                                    [
                                      layerIndex,
                                      "texts",
                                      lineIndex,
                                      "component",
                                    ],
                                    e.target.value
                                  )
                                }
                              />
                              <AlignInputSelect
                                value={line.align || "left"}
                                onChange={(e) =>
                                  setProp(
                                    [layerIndex, "texts", lineIndex, "align"],
                                    e.target.value
                                  )
                                }
                              />
                              <FontweightInputSelect
                                value={line.fontWeight || 400}
                                onChange={(e) =>
                                  setProp(
                                    [
                                      layerIndex,
                                      "texts",
                                      lineIndex,
                                      "fontWeight",
                                    ],
                                    e.target.value
                                  )
                                }
                              />
                              <TextDecorationLineInputSelect
                                value={line.sx?.textDecorationLine || "none"}
                                onChange={(e: any) =>
                                  setProp(
                                    [
                                      layerIndex,
                                      "texts",
                                      lineIndex,
                                      "sx",
                                      "textDecorationLine",
                                    ],
                                    e.target.value
                                  )
                                }
                              />
                              <ColorSelectInput
                                Icon={FormatColorTextIcon}
                                value={line.color || "text.primary"}
                                onChange={(e: any) =>
                                  setProp(
                                    [layerIndex, "texts", lineIndex, "color"],
                                    e.target.value
                                  )
                                }
                              />
                              <ColorSelectInput
                                Icon={FormatColorFillIcon}
                                target="background"
                                value={
                                  line.sx?.backgroundColor || "transparent"
                                }
                                onChange={(e: any) =>
                                  setProp(
                                    [
                                      layerIndex,
                                      "texts",
                                      lineIndex,
                                      "sx",
                                      "backgroundColor",
                                    ],
                                    e.target.value
                                  )
                                }
                              />
                              <IconButton
                                sx={{
                                  width: 70,
                                  border: "1px solid rgb(187, 187, 187)",
                                  borderRadius: 0,
                                }}
                                disabled={lineIndex === 0}
                                onClick={() => {
                                  let newTexts = [...layer.texts!];
                                  [
                                    newTexts[lineIndex - 1],
                                    newTexts[lineIndex],
                                  ] = [
                                    newTexts[lineIndex],
                                    newTexts[lineIndex - 1],
                                  ];
                                  setProp([layerIndex, "texts"], [...newTexts]);
                                }}
                              >
                                <ArrowUpwardIcon />
                              </IconButton>
                              <IconButton
                                sx={{
                                  width: 70,
                                  border: "1px solid rgb(187, 187, 187)",
                                  borderRadius: 0,
                                }}
                                disabled={lineIndex === layer.texts!.length - 1}
                                onClick={() => {
                                  let newTexts = [...layer.texts!];
                                  [
                                    newTexts[lineIndex + 1],
                                    newTexts[lineIndex],
                                  ] = [
                                    newTexts[lineIndex],
                                    newTexts[lineIndex + 1],
                                  ];
                                  setProp([layerIndex, "texts"], [...newTexts]);
                                }}
                              >
                                <ArrowDownwardIcon />
                              </IconButton>
                              <IconButton
                                sx={{
                                  width: 70,
                                  border: "1px solid rgb(187, 187, 187)",
                                  borderRadius: 0,
                                }}
                                // disabled={layer.texts!.length === 1}
                                onClick={() =>
                                  setProp(
                                    [layerIndex, "texts"],
                                    layer.texts!.filter(
                                      (_: any, i: number) => i !== lineIndex
                                    )
                                  )
                                }
                              >
                                <RemoveCircleIcon />
                              </IconButton>
                            </FormGroup>
                          </FormControl>
                        </Stack>
                        <TextField
                          value={line.children}
                          onChange={(e) =>
                            setProp(
                              [layerIndex, "texts", lineIndex, "children"],
                              e.target.value
                            )
                          }
                        />
                      </Stack>
                    ))
                  : null}
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() =>
                    setProp([layerIndex, "texts", layer.texts?.length || 0], {
                      variant: "h2",
                      children: "Hello",
                    })
                  }
                  sx={{ alignSelf: "flex-start" }}
                >
                  Ajouter du texte
                </Button>
              </>
            )}
          </Stack>
        )
      )}
      {/* <Button
        variant="contained"
        fullWidth
        onClick={() => {
          if (!layers) {
            setProp(
              [],
              [
                {
                  layout: "image",
                  speed: -40,
                  image: {
                    src: "/banner-background.jpg",
                    alt: "background",
                    style: { objectFit: "cover" },
                  },
                },
              ]
            );
          } else {
            setProp([layers?.length || 0], {
              layout: "image",
              speed: -40,
              image: {
                src: "/banner-background.jpg",
                alt: "background",
                style: { objectFit: "cover" },
              },
            });
          }
        }}
        sx={{ alignSelf: "flex-start" }}
      >
        Ajouter une couche
      </Button> */}
    </Stack>
  );
};

export default ParallaxInput;
