import React, { useState, useEffect } from "react";

const useReponsesFilter = (filters: string[], reponses: any[]) => {
  const [filteredReponses, setFilteredReponses] = useState<any[]>([]);

  useEffect(() => {
    let filtered: any[] = [];
    if (filters.length === 0) {
      setFilteredReponses(reponses);
      return;
    } else {
      filters.forEach((filter) => {
        reponses.forEach((reponse) => {
          const conditions: { [key: string]: boolean } = {
            "Nouvelles demandes": reponse?.data.dispoVirtuelle === false,
            "Disponibilités visio envoyées":
              reponse?.data.dispoVirtuelle === true &&
              reponse?.data.rdvVirtuel === "",
            "Rendez-vous visio programmé":
              reponse?.data.rdvVirtuel !== "" &&
              new Date(reponse?.data.rdvVirtuel.fin).getTime() >
                new Date().getTime(),
            "Rendez-vous visio passé":
              reponse?.data.rdvVirtuel !== "" &&
              new Date(reponse?.data.rdvVirtuel.fin).getTime() <
                new Date().getTime() &&
              reponse?.data.demandeRetour === false,
            "Demande de retour envoyée":
              reponse?.data.demandeRetour === true &&
              reponse?.data.dateRetour === null,
            "Retour envoyé":
              reponse?.data.dateRetour !== null &&
              reponse?.data.dispoSurplace === false,
            "Disponibilités sur place envoyées":
              reponse?.data.dispoSurplace === true &&
              reponse?.data.rdvSurplace === "",
            "Rendez-vous sur place programmé":
              reponse?.data.rdvSurplace !== "" &&
              new Date(reponse?.data.rdvSurplace.fin).getTime() >
                new Date().getTime(),
            "Rendez-vous sur place passé":
              reponse?.data.rdvSurplace !== "" &&
              new Date(reponse?.data.rdvSurplace.fin).getTime() <
                new Date().getTime(),
          };

          if (conditions[filter]) {
            filtered.push(reponse);
          }
        });
      });

      setFilteredReponses(filtered);
    }
  }, [reponses, filters]);

  return filteredReponses;
};

export default useReponsesFilter;
