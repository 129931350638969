import {
  Alert,
  Box,
  Collapse,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import { useHover } from "usehooks-ts";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Delete from "@mui/icons-material/Delete";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { selectUiProp, setSelected } from "../store/uiSlice";
import { selectAnnonceProp, setAnnonceProp } from "../store/annonceSlice";
import { useLocation } from "react-router-dom";
// import { useRouter } from "next/router";

const backgroundColorMenuItems = (
  handleClose: any,
  getPaletteColor: any,
  backgroundColor: any
) =>
  [
    ["Couleur de fond"],
    ["transparent", "Transparent"],
    ["white", "Blanc"],
    ["black"],
    ["text.primary"],
    ["text.secondary"],
    ["background.default"],
    ["primary.dark"],
    ["primary.main"],
    ["primary.light"],
  ].map(([c, label], index) =>
    index === 0 ? (
      <MenuItem key={c} disabled>
        {c}
      </MenuItem>
    ) : label ? (
      <MenuItem
        key={c}
        selected={backgroundColor === c}
        onClick={handleClose(c)}
      >{`${backgroundColor === c ? "✓ " : ""}${label}`}</MenuItem>
    ) : (
      <MenuItem
        key={c}
        color={c}
        onClick={handleClose(c)}
        selected={backgroundColor === c}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            width: "100%",
          }}
        >
          {backgroundColor === c ? <CheckRoundedIcon /> : null}
          <Box
            sx={{
              width: "100%",
              height: 24,
            }}
            style={{
              backgroundColor: getPaletteColor(c),
              color: getPaletteColor(c),
            }}
          />
        </Stack>
      </MenuItem>
    )
  );

const widthMenuItems = (handleClose: any, maxWidth: any) =>
  [
    ["Largeur maximale du contenu"],
    ["xs", "444 pixels"],
    ["sm", "600 pixels"],
    ["md", "900 pixels"],
    ["lg", "1200 pixels"],
    ["xl", "1536 pixels"],
    [false, "Pleine largeur"],
  ].map(([w, label], index) =>
    index === 0 ? (
      <MenuItem key={(w || label) as string} disabled>
        {w}
      </MenuItem>
    ) : (
      <MenuItem
        key={(w || label) as string}
        selected={maxWidth === w}
        onClick={handleClose(w)}
      >
        {label}
      </MenuItem>
    )
  );

const toRGB = (rgba: any) => {
  const [r, g, b, a] = rgba.slice(5, -1).split(",");
  return `rgb(${(255 - r) * (1 - a)},${(255 - g) * (1 - a)},${
    (255 - b) * (1 - a)
  })`;
};

type OwnProps = {
  children: any;
  backgroundColor?: string;
  pageIndex?: string;
  path?: string[];
  inputType?: string;
  itemProps?: any;
  defaultItemProps?: any;
  items: any[];
  index: number;
  contentScale: number;
};

type Props = OwnProps & {
  handleItemSelect: any;
  itemProps: any;
  setItemDisplay: any;
  setItemFill: any;
  moveItem: any;
};

const SectionItemWrapper = (props: Props) => {
  const {
    children,
    handleItemSelect,
    itemProps,
    setItemDisplay,
    setItemFill,
    inputType,
    moveItem,
    contentScale,
    pageIndex,
  } = props;
  const { backgroundColor = "transparent", isSelected, display } = itemProps;
  const hoverRef = useRef(null);
  const theme = useTheme();
  const isHover = useHover(hoverRef);
  const [menu, setMenu] = useState("");
  console.log("SectionWrapper", props);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  // const router = useRouter();
  if (!location.pathname.includes("/modification/") && display === false)
    return null;
  if (!location.pathname.includes("/modification/") || !inputType) {
    return (
      <Box
        sx={{
          backgroundColor,
        }}
      >
        <Container disableGutters maxWidth={itemProps.maxWidth}>
          {children}
        </Container>
      </Box>
    );
  }
  // const pageId = useSelector(selectCurrentPageId);

  const getPaletteColor = (color: string) => {
    switch (color) {
      case "background.default":
        return theme.palette.background.default;
      case "primary.main":
        return theme.palette.primary.main;
      case "primary.light":
        return theme.palette.primary.light;
      case "primary.dark":
        return theme.palette.primary.dark;
      case "text.primary":
        return toRGB(theme.palette.text.primary);
      case "text.secondary":
        return toRGB(theme.palette.text.secondary);
      case "black":
      case "white":
        return theme.palette.common[color];
    }
  };

  const open = Boolean(anchorEl);
  const handleMenuClick =
    (menu: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenu(menu);
      setAnchorEl(event.currentTarget);
    };
  const handleClose = (value: any) => () => {
    menu === "fill" && value !== undefined && setItemFill(pageIndex, value);
    setAnchorEl(null);
  };
  return (
    <Box
      ref={hoverRef}
      sx={{
        backgroundColor,
        position: "relative",
        borderColor:
          isHover || itemProps.display === false || isSelected
            ? "primary.main"
            : backgroundColor,
        borderWidth:
          isHover || itemProps.display === false || isSelected ? 2 : 0,
        borderStyle: "dashed",
        minHeight: 42,
      }}
    >
      <Container disableGutters maxWidth={itemProps.maxWidth}>
        {children}
      </Container>
      {display === false ? (
        <Box
          sx={{
            bottom: 0,
            width: "100%",
            backgroundColor: "rgba(255,255,255,0.80)",
            position: "absolute",
            top: 0,
            p: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert severity="info">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Cet élément est désactivé et n'apparaîtra pas sur votre site
          </Alert>
        </Box>
      ) : null}
      {isHover || open || display === false ? (
        <Stack
          direction="row"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            height: 40,
            color: "white",
            transform: `scale(${1 / contentScale})`,
            transformOrigin: "top right",
          }}
        >
          <Collapse in={display !== false} orientation="horizontal">
            <IconButton
              sx={{
                borderRadius: 0,
                backgroundColor: "warning.dark",
                "&:hover": {
                  backgroundColor: "warning.light",
                },
              }}
              color="white"
              onClick={() => {
                moveItem(pageIndex);
              }}
            >
              <Delete />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: 0,
                backgroundColor: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
              color="white"
              onClick={() => {
                moveItem(pageIndex, "up");
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: 0,
                backgroundColor: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
              color="white"
              onClick={() => {
                moveItem(pageIndex, "down");
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: 0,
                backgroundColor: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
              color="white"
              onClick={handleMenuClick("fill")}
            >
              <FormatColorFillIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose(undefined)}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              {menu === "width"
                ? widthMenuItems(handleClose, itemProps.maxWidth)
                : backgroundColorMenuItems(
                    handleClose,
                    getPaletteColor,
                    itemProps.backgroundColor
                  )}
            </Menu>
            <IconButton
              sx={{
                borderRadius: 0,
                backgroundColor: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
              color="white"
              onClick={() => {
                handleItemSelect();
              }}
            >
              <EditIcon />
            </IconButton>
          </Collapse>
          <IconButton
            sx={{
              borderRadius: 0,
              backgroundColor: "primary.dark",
              "&:hover": {
                backgroundColor: "primary.light",
              },
            }}
            color="white"
            onClick={() => {
              setItemDisplay(pageIndex, display === false);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Stack>
      ) : null}
    </Box>
  );
};

const mapDispatchToProps = (
  dispatch: any,
  { path, inputType, pageIndex, items, index }: OwnProps
) => {
  const location = window.location;
  return location.pathname.includes("/modification/") && path
    ? {
        handleItemSelect: () => dispatch(setSelected({ path, inputType })),
        moveItem: (pageId: string, action?: string) => {
          const item = { ...items[index] };
          items.splice(index, 1);
          if (action === "up") {
            items.splice(index - 1, 0, item);
          }
          if (action === "down") {
            items.splice(index + 1, 0, item);
          }
          dispatch(
            setAnnonceProp({
              id: pageId,
              path: ["data", ...path.slice(0, -1)],
              value: [...items],
            })
          );
        },
        setItemDisplay: (pageId: string, value: boolean) =>
          dispatch(
            setAnnonceProp({
              id: pageId,
              path: ["data", ...path, "itemProps", "display"],
              value: value,
            })
          ),
        setItemFill: (pageId: string, value: string) =>
          dispatch(
            setAnnonceProp({
              id: pageId,
              path: ["data", ...path, "itemProps", "backgroundColor"],
              value: value,
            })
          ),
      }
    : {};
};

const definedProps = (obj: {}) =>
  Object.fromEntries(Object.entries(obj).filter(([_k, v]) => v !== undefined));

const mapStateToProps = (state: any, props: OwnProps) => {
  const location = window.location;
  console.log("SectionItemnWrapper - mapStateToProps", { props });
  const { display, maxWidth, backgroundColor } =
    props.path && location.pathname.includes("/modification/")
      ? (selectAnnonceProp([...props.path, "itemProps"], {})(state) as any)
      : props.itemProps || {};
  const userItemProps = definedProps({ display, maxWidth, backgroundColor });
  const { defaultItemProps = {} } = props;
  const contentScale = selectUiProp("contentScale")(state) as number;
  return {
    contentScale,
    itemProps: {
      display: true,
      maxWidth: false,
      backgroundColor: "transparent",
      ...defaultItemProps,
      ...userItemProps,
      ...(props.path
        ? {
            isSelected:
              state.ui.selected?.path.join(".") === props.path.join("."),
          }
        : {}),
    },
  };
};

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionItemWrapper);

export const withSectionItemWrapper =
  (Component: any) =>
  // eslint-disable-next-line react/display-name
  ({
    pageIndex,
    path,
    inputType,
    itemProps,
    defaultItemProps,
    items,
    index,
    contentScale,
    ...rest
  }: any) =>
    (
      // @ts-ignore
      <Connected
        {...{
          contentScale,
          pageIndex,
          path,
          inputType,
          itemProps,
          defaultItemProps,
          items,
          index,
        }}
      >
        <Component {...rest} {...{ path, index }} />
      </Connected>
    );

export default Connected;
