export const getToken = async (email: string, password: string) => {
  try {
    const response = await fetch(
      "https://formio.mobilecoveragemap.com/user/login",
      {
        method: "POST",
        body: JSON.stringify({
          data: {
            email: email,
            password: password,
          },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      const token = response.headers.get("x-jwt-token");
      return token;
    } else {
      return "error";
    }
  } catch (err) {
    return "error";
  }
};
