import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import UndoRedo from "./UndoRedo";
import SaveIcon from "@mui/icons-material/Save";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import PaletteIcon from "@mui/icons-material/Palette";
import SendIcon from "@mui/icons-material/Send";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import DeviceSelect from "./DeviceSelect";
import { useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";
import { ActionCreators } from "redux-undo";
import { selectUiProp, setLoading, setSelected } from "../../store/uiSlice";
import { selectAnnonceById } from "../../store/annonceSlice";
import { EntityId } from "@reduxjs/toolkit";
// import { useRouter } from "next/router";
import { putAnnonce } from "../../requests/annonces";
import { setMessage } from "../../store/errorSuccessSlice";
import { useNavigate, useParams } from "react-router-dom";
// import { SiteIdentity } from "../../pages/admin/espace-client";

const EditionBar = ({
  setSelected,
  setTheme,
}: {
  setSelected: any;
  setTheme: any;
}) => {
  const isSaving = useSelector(selectUiProp("isSaving"));
  const [showSaving, setShowSaving] = useState<boolean>(() => isSaving);

  // const [message, setMessage] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const [showParams, setShowParams] = useState(false);
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const annonce = useSelector((state: any) =>
    selectAnnonceById(state, annonceId as EntityId)
  );

  useEffect(() => {
    if (isSaving) {
      setShowSaving(true);
    } else {
      setTimeout(() => {
        setShowSaving(false);
      }, 3000);
    }
  }, [isSaving]);

  const save = async () => {
    dispatch(setLoading({ isLoading: true }));

    putAnnonce(annonceId as string, annonce).then((res) => {
      if (res === "ok") {
        dispatch(setLoading({ isLoading: false }));
        dispatch(setMessage({ message: "Enregistré !", type: "success" }));
        dispatch(ActionCreators.clearHistory());
      } else {
        dispatch(setLoading({ isLoading: false }));
        dispatch(
          setMessage({ message: "Une erreur est survenue", type: "error" })
        );
      }
    });

    // dispatch(setLoading({ isLoading: false }));
  };

  const navigate = useNavigate();
  return (
    <>
      <AppBar
        position="relative"
        style={{
          height: "80px",
          backgroundColor: "white",
          borderRadius: "0px 0px 20px 20px",
          marginBottom: 15,
        }}
      >
        <Toolbar style={{ height: "100%" }}>
          <Box width="100%">
            {/* <Box display="flex" alignItems="center"> */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                display={{ xs: "none", md: "flex" }}
                alignItems="center"
                justifyContent="space-between"
              >
                <IconButton
                  size="large"
                  edge="start"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#535A5D",
                    textTransform: "uppercase",
                  }}
                  onClick={() => navigate("/tableau-de-bord")}
                >
                  <DashboardIcon />
                  <Typography sx={{ fontSize: "12px" }}>Mon espace</Typography>
                </IconButton>
                <IconButton
                  size="large"
                  edge="start"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#535A5D",
                    textTransform: "uppercase",
                    ml: 1,
                  }}
                  onClick={setSelected(["theme"], "ThemePicker")}
                >
                  <PaletteIcon />
                  <Typography sx={{ fontSize: "12px" }}>Couleurs</Typography>
                </IconButton>
              </Box>
              <Box
                display={{ xs: "flex", md: "none" }}
                alignItems="center"
                justifyContent="space-between"
              >
                <IconButton
                  size="large"
                  edge="start"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#535A5D",
                    textTransform: "uppercase",
                    ml: 1,
                  }}
                  onClick={() => setOpenMenu(true)}
                >
                  <MenuIcon />
                  <Typography sx={{ fontSize: "12px" }}>Menu</Typography>
                </IconButton>
              </Box>
              <DeviceSelect />
              <Box display="flex">
                <UndoRedo />
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Collapse in={showSaving && isSaving} orientation="horizontal">
                  <Box sx={{ mr: 2 }}>
                    <Chip
                      variant="outlined"
                      color="info"
                      sx={{ p: 2 }}
                      icon={
                        <CircularProgress
                          size={20}
                          style={{ marginRight: 0 }}
                        />
                      }
                      label="Enregistrment ..."
                    />
                  </Box>
                </Collapse>
                <Collapse in={showSaving && !isSaving} orientation="horizontal">
                  <Box sx={{ mr: 2 }}>
                    <Chip
                      variant="outlined"
                      color="success"
                      sx={{ p: 2 }}
                      icon={<SaveIcon />}
                      label="Enregistré !"
                    />
                  </Box>
                </Collapse>
                <Button
                  variant="contained"
                  onClick={save}
                  color="secondary"
                  sx={{
                    mr: 2,
                    borderRadius: "48px",
                    textTransform: "capitalize",
                    padding: "10px 18px",
                    fontSize: "16px",
                  }}
                >
                  Enregistrer
                </Button>
                {/* )} */}
              </Box>
            </Box>
          </Box>
        </Toolbar>
        {/* <Dialog
          open={!!showParams}
          fullWidth
          maxWidth="sm"
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Paper sx={{ p: 2, backgroundColor: "#ebebeb" }}>
            <Box
              sx={{ textAlign: "right", cursor: "pointer" }}
              onClick={() => setShowParams(false)}
            >
              <CloseIcon />
            </Box>
          </Paper>
        </Dialog> */}
        {/* <Backdrop
          open={!!message}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Paper sx={{ p: 2 }}>
            <Stack direction="column" spacing={2}>
              <Typography>{message}</Typography>
              <Typography>
                Si vous souhaitez obtenir un nom de domaine personnalisé,
                <a href="mailto:lien@t-connect.fr"> contactez-nous</a>
              </Typography>
              <Button variant="contained" onClick={() => setMessage("")}>
                OK
              </Button>
            </Stack>
          </Paper>
        </Backdrop> */}
      </AppBar>
      <Drawer
        anchor={"left"}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        sx={{
          zIndex: 499,
          width: 110,
          [`& .MuiDrawer-paper`]: {
            width: 110,
            boxSizing: "border-box",
          },
        }}
      >
        <Box
          position="fixed"
          top={180}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={110}
        >
          <IconButton
            size="large"
            edge="start"
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#535A5D",
              textTransform: "uppercase",
              margin: "auto",
            }}
            onClick={() => navigate("/tableau-de-bord")}
          >
            <DashboardIcon />
            <Typography sx={{ fontSize: "12px" }}>Mon espace</Typography>
          </IconButton>
          <IconButton
            size="large"
            edge="start"
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#535A5D",
              textTransform: "uppercase",
              margin: "auto",
            }}
            onClick={setSelected(["theme"], "ThemePicker")}
          >
            <PaletteIcon />
            <Typography sx={{ fontSize: "12px" }}>Couleurs</Typography>
          </IconButton>
        </Box>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  ...state.undoable.present,
});
const mapDispatchToProps = (dispatch: any) => ({
  setSelected: (path: string[], inputType?: string) => () =>
    dispatch(setSelected({ path, inputType })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditionBar);
