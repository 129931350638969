import { disconnect } from "../store/authSlice";
import store from "../store/store";
import { formioRequest } from "./formioRequest";

export const getReponses = async (ownerId?: string) => {
  let res;

  try {
    res = await fetch(
      `https://formio.mobilecoveragemap.com/form/650adfb34a8a916f7a2dff9a/submission?data.archiver=false&limit=1000`,
      {
        method: "GET",
        headers: {
          "x-jwt-token": localStorage.getItem("token") as string,
        },
      }
    );
    if (res.status === 200) {
      const json = await res.json();
      return json;
    } else if (res.status === 440) {
      store.dispatch(disconnect());
    } else {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};
export const getArchivedReponses = async (ownerId?: string) => {
  let res;

  try {
    res = await fetch(
      `https://formio.mobilecoveragemap.com/form/650adfb34a8a916f7a2dff9a/submission?data.archiver=true&limit=1000`,
      {
        method: "GET",
        headers: {
          "x-jwt-token": localStorage.getItem("token") as string,
        },
      }
    );
    if (res.status === 200) {
      const json = await res.json();
      return json;
    } else if (res.status === 440) {
      store.dispatch(disconnect());
    } else {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};

export const patchReponse = async (
  reponseId: string,
  data: { op: string; path: string; value: string | boolean }[]
) => {
  try {
    await formioRequest({
      url: `/650adfb34a8a916f7a2dff9a/submission/${reponseId}`,
      method: "PATCH",
      data: data,
      headers: {
        "x-jwt-token": localStorage.getItem("token") as string,
      },
    });

    return "ok";
  } catch (error: any) {
    return "error";
  }
};
