import React, { SetStateAction, Dispatch } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Link as MuiLink,
  CardMedia,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import sanitizeHtml from "sanitize-html";

type Props = {
  article: any;
  setArticle?: Dispatch<
    SetStateAction<{ titre: string; content: string; image: any }>
  >;
  setAction?: Dispatch<SetStateAction<null | "add" | "update" | "delete">>;
  setArticleId?: Dispatch<SetStateAction<null | string | undefined>>;
  id?: string;
};

const ArticleCard = ({
  article,
  setArticle,
  setAction,
  setArticleId,
  id,
}: Props) => {
  const location = useLocation();
  let content = sanitizeHtml(article.content, { allowedTags: [] });
  // let content = article.content.replace("\n", "");
  // content = content.replaceAll("<p>", "");
  // content = content.replaceAll("</p>", " ");
  // content = content.replaceAll("<ul>", "");
  // content = content.replaceAll("</ul>", " ");
  // content = content.replaceAll("<li>", "");
  // content = content.replaceAll("</li>", " ");
  // content = content.replaceAll("<div>", "");
  // content = content.replaceAll("</div>", " ");
  // content = content.replaceAll("<span>", "");
  // content = content.replaceAll("</span>", " ");
  // content = content.replaceAll("<ol>", "");
  // content = content.replaceAll("</ol>", " ");

  return (
    <Grid item xs={1}>
      <Card
        sx={{
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
          borderRadius: "20px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardMedia
          component="img"
          sx={{ height: 272, objectFit: "fill" }}
          image={article.image.imageUrl}
          alt={article.slug.split("-").join(" ")}
        />
        <CardContent>
          <Typography gutterBottom>{article.titre}</Typography>
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "4",
              WebkitBoxOrient: "vertical",
            }}
          >
            {content}
          </Typography>
        </CardContent>
        <CardActions sx={{ p: 2 }}>
          {location.pathname.includes("tableau-de-bord") ? (
            <>
              <Button
                onClick={() => {
                  //  @ts-ignore
                  setArticle(article);
                  //  @ts-ignore
                  setAction("update");
                  //  @ts-ignore
                  setArticleId(id);
                }}
              >
                Modifier
              </Button>
              <Button
                onClick={() => {
                  //  @ts-ignore
                  setArticle(article);
                  //  @ts-ignore
                  setAction("delete");
                  //  @ts-ignore
                  setArticleId(id);
                }}
              >
                Supprimer
              </Button>
            </>
          ) : (
            <MuiLink
              component={Link}
              to={`/blog/${article.slug}`}
              sx={{ textDecoration: "none" }}
            >
              Lire la suite
            </MuiLink>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ArticleCard;
