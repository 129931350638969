import { disconnect } from "../store/authSlice";
import store from "../store/store";
import { formioRequest } from "./formioRequest";

export const changeUserInfos = async (data: any) => {
  const json = JSON.stringify(data);
  try {
    const res = await formioRequest({
      url: `/64a7e0af7bd8647721f99ae6/submission/${data._id}`,
      method: "PUT",
      data: json,
      headers: {
        "Content-Type": "application/json",
        "x-jwt-token": localStorage.getItem("token") as string,
      },
    });
    if (res.status === 200) {
      return "ok";
    } else if (res.status === 440) {
      store.dispatch(disconnect());
    } else {
      return "error";
    }
  } catch (error) {
    return "error";
  }
};
