import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import annonceSlice from "./annonceSlice";
import errorSuccessSlice from "./errorSuccessSlice";
import uiSlice from "./uiSlice";
import undoable, { combineFilters, excludeAction } from "redux-undo";
import articleSlice from "./articleSlice";
import paginationSlice from "./paginationSlice";

const undoableRootReducer = undoable(
  combineReducers({
    annonces: annonceSlice,
  }),
  {
    ignoreInitialState: true,
    groupBy: (action: any) =>
      action.payload?.path ? action.payload.path.join(",") : null,
    filter: combineFilters(
      excludeAction([
        "__NEXT_REDUX_WRAPPER_HYDRATE__",
        "@@INIT",
        "ui/setLoading",
        "ui/setUiProp",
        "pagination/setTotal",
        "pagination/setNumberPerPage",
        "auth/setConnected",
      ]),
      (action: any, _currentState: any, _previousHistory: any) => {
        if (
          ["annonces/setAnnonceProp"].includes(action.type) &&
          JSON.stringify(action.payload.path) === JSON.stringify(["updatedAt"])
        ) {
          return false;
        }
        return true;
      },
      (action: any, _currentState: any, _previousHistory: any) => {
        if (["annonces/setAllAnnonces"].includes(action.type)) {
          return false;
        }
        return true;
      },
      (action, _currentState, _previousHistory) => {
        if (
          ["annonces/setAnnonceProp"].includes(action.type) &&
          action.payload.path.includes("image") &&
          !action.payload?.undo
        ) {
          return false;
        }
        return true;
      }
    ),
  }
);

const finalReducer = combineReducers({
  auth: authSlice,
  errorSuccess: errorSuccessSlice,
  // rooms: roomsSlice,
  ui: uiSlice,
  undoable: undoableRootReducer,
  articles: articleSlice,
  pagination: paginationSlice,
});

// const rootReducer = (state: any, action: AnyAction) => {
//   if (action.type === "auth/disconnect") {
//     localStorage.removeItem("token");
//     // state = undefined;
//     state.auth.token = undefined;
//     state.auth.connected = false;
//     state.auth.user = undefined;
//   }
//   return finalReducer(state, action);
// };

// export type AppStore = ReturnType<typeof rootReducer>;
// export const wrapper = createWrapper<AppStore>(finalReducer);

export default configureStore({
  reducer: finalReducer,
});
