const url = "https://48heklt6di.execute-api.eu-west-1.amazonaws.com/dev/";
// const url = "http://localhost:5000/";

export const deleteRdvAmplify = async (props: {
  ownerId: string;
  rdvId: string;
  titre: string;
  annonce: string;
}) => {
  return fetch(`${url}directmail/annuler`, {
    method: "POST",
    body: JSON.stringify({
      ownerId: props.ownerId,
      rdvId: props.rdvId,
      titre: props.titre,
      annonce: props.annonce,
      patch: "delete rdv",
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return { message: "Annulation prise en compte", type: "success" };
      } else {
        return {
          message: "Une erreur est survenue, veuillez réessayer",
          type: "error",
        };
      }
    })
    .catch((err) => ({
      message: "Une erreur est survenue, veuillez réessayer",
      type: "error",
    }));
};

export const getDisponibilites = async (props: {
  reponseId: string;
  email: string;
  ownerId: string;
  type: "virtuelle" | "sur place";
}) => {
  return fetch(`${url}directdata/getdispos`, {
    method: "POST",
    body: JSON.stringify({
      reponseId: props.reponseId,
      ownerId: props.ownerId,
      type: props.type,
      data: {
        emailAcheteur: props.email,
      },
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res;
    })
    .catch(() => "error");
};

export const getRdvs = async (props: {
  reponseId: string;
  email: string;
  ownerId: string;
}) => {
  return fetch(`${url}directdata/rdvs`, {
    method: "POST",
    body: JSON.stringify({
      reponseId: props.reponseId,
      ownerId: props.ownerId,
      data: {
        emailAcheteur: props.email,
      },
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return "error";
      }
    })
    .catch(() => "error");
};

export const newRdv = (props: {
  debut: string;
  fin: string;
  vendeurId: string;
  acheteurEmail: string;
  reponseId: string;
  titre: string;
  annonceId: string;
  type: "virtuelle" | "sur place";
}) => {
  return fetch(`${url}directmail/confirmer`, {
    method: "POST",
    body: JSON.stringify({
      debut: props.debut,
      fin: props.fin,
      ownerId: props.vendeurId,
      titre: props.titre,
      reponseId: props.reponseId,
      annonceId: props.annonceId,
      patch: "rdv",
      type: props.type,
      data: {
        emailAcheteur: props.acheteurEmail,
      },
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postRetour = (props: {
  reponseId: string;
  email: string;
  interesse: string;
  remarques: string;
}) => {
  return fetch(`${url}directdata/postretour`, {
    method: "POST",
    body: JSON.stringify({
      reponseId: props.reponseId,
      email: props.email,
      interesse: props.interesse,
      remarques: props.remarques,
      patch: "retour",
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return { message: "Remarques envoyées", type: "success" };
      } else {
        return {
          message: "Une erreur est survenue, veuillez réessayer",
          type: "error",
        };
      }
    })
    .catch((err) => ({
      message: "Une erreur est survenue, veuillez réessayer",
      type: "error",
    }));
};

export const getRdvAdmin = async (rdvId: string, email: string) => {
  let res;
  try {
    res = await fetch(`${url}directdata/rdv`, {
      method: "POST",
      body: JSON.stringify({
        rdvId,
        data: {
          emailAcheteur: email,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status === 200) {
      return res.json();
    } else {
      return undefined;
    }
  } catch (error: any) {
    return undefined;
  }
};

export const sendDispos = async (props: {
  ownerId: string;
  acheteurId: string;
  reponseId: string;
  titre: string;
}) => {
  return fetch(`${url}directmail/dispos`, {
    method: "POST",
    body: JSON.stringify({
      ownerId: props.ownerId,
      acheteurId: props.acheteurId,
      reponseId: props.reponseId,
      titre: props.titre,
      token: localStorage.getItem("token") as string,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return { message: "Email envoyé", type: "success" };
      } else {
        return {
          message: "Une erreur est survenue, veuillez réessayer",
          type: "error",
        };
      }
    })
    .catch((err) => ({
      message: "Une erreur est survenue, veuillez réessayer",
      type: "error",
    }));
};

export const askRetour = async (props: {
  acheteurId: string;
  reponseId: string;
  titre: string;
}) => {
  return fetch(`${url}directmail/retour`, {
    method: "POST",
    body: JSON.stringify({
      reponseId: props.reponseId,
      acheteurId: props.acheteurId,
      titre: props.titre,
      token: localStorage.getItem("token") as string,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return { message: "Email envoyé", type: "success" };
      } else {
        return {
          message: "Une erreur est survenue, veuillez réessayer",
          type: "error",
        };
      }
    })
    .catch((err) => ({
      message: "Une erreur est survenue, veuillez réessayer",
      type: "error",
    }));
};

export const sendDisposSurplace = async (props: {
  ownerId: string;
  acheteurId: string;
  reponseId: string;
  titre: string;
}) => {
  return fetch(`${url}directmail/surplace`, {
    method: "POST",
    body: JSON.stringify({
      ownerId: props.ownerId,
      acheteurId: props.acheteurId,
      reponseId: props.reponseId,
      titre: props.titre,
      token: localStorage.getItem("token"),
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return { message: "Email envoyé", type: "success" };
      } else {
        return {
          message: "Une erreur est survenue, veuillez réessayer",
          type: "error",
        };
      }
    })
    .catch((err) => ({
      message: "Une erreur est survenue, veuillez réessayer",
      type: "error",
    }));
};

export const postReponse = async (props: { ownerId: string; data: any }) => {
  return fetch(`${url}directdata/newreponse`, {
    method: "POST",
    body: JSON.stringify({
      ownerId: props.ownerId,
      data: props.data,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return "ok";
      } else if (res.status === 400) {
        return "already exists";
      } else {
        return "error";
      }
    })
    .catch((err) => "error");
};

export const ownerDeleteRdv = async (props: {
  token: string;
  rdvId: string;
  vendeurEmail: string;
  annonce: string;
  titre: string;
  ownerId: string;
}) => {
  return fetch(`${url}directmail/annulation`, {
    method: "POST",
    body: JSON.stringify({
      ownerId: props.ownerId,
      token: props.token,
      rdvId: props.rdvId,
      vendeurEmail: props.vendeurEmail,
      annonce: props.annonce,
      titre: props.titre,
      patch: "delete rdv",
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return "ok";
      } else {
        return "error";
      }
    })
    .catch((err) => "error");
};

export const stripeCheckout = async ({
  email,
  type,
}: {
  email?: string;
  type: "1h" | "2h" | "3h" | "4h" | "5h" | "site";
}) => {
  console.log(email);
  return fetch(`${url}directdata/stripesession`, {
    method: "POST",
    body: JSON.stringify({ email, type }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res;
      } else {
        return "error";
      }
    })
    .catch((err) => {
      console.log(err);
      return "error";
    });
};
