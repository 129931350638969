import React from "react";
import { Typography, Grid, Box, Container } from "@mui/material";
import economique from "../images/direct-proprietaire-economique.png";
import facile from "../images/direct-proprietaire-facile-achat-immobilier.png";
import rapide from "../images/direct-proprietaire-rapide-achat-immobilier.png";
import anonyme from "../images/direct-proprietaire-anonyme-achat-immobilier.png";
// import Image from "next/image";

type Props = {};

const PourquoiCreer = (props: Props) => {
  return (
    <Container maxWidth="xl">
      <Box sx={{ backgroudColor: "white", p: 2, py: 6 }}>
        <Typography variant="h2" textAlign="center" sx={{ mb: 4 }}>
          Pourquoi créer son propre site internet ?
        </Typography>
        <Grid container columns={{ xs: 1, sm: 2, md: 4 }} spacing={4}>
          <Grid item xs={1}>
            <img
              src={economique}
              // width={150}
              width="50%"
              alt=""
            />

            <Typography variant="h3">Économique</Typography>
            <Typography variant="body2">
              Payez uniquement pour la création de votre site internet sans
              aucun frais ni commissions
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <img
              src={facile}
              width="50%"
              // width={150}
              alt=""
            />
            <Typography variant="h3">Facile</Typography>
            <Typography variant="body2">
              Toute la procédure de création du site est simple et intuitive
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <img
              src={rapide}
              width="50%"
              // width={150}
              alt=""
            />
            <Typography variant="h3">Rapide</Typography>
            <Typography variant="body2">
              Le temps de création du site est de moins d’une heure, en quelques
              clics votre site est prêt à être publié
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <img
              src={anonyme}
              width="50%"
              // width={150}
              alt=""
            />
            <Typography variant="h3">Anonyme</Typography>
            <Typography variant="body2">
              Échange de documents, photos vidéos, agenda partagé sans donner
              son identité
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PourquoiCreer;
