import { disconnect } from "../store/authSlice";
import store from "../store/store";
import { annonceTemplate } from "../templateAnnonce";
import { formioRequest } from "./formioRequest";
import set from "lodash.set";

export const getAllAnnonces = async () => {
  try {
    const res = await fetch(
      `https://formio.mobilecoveragemap.com/form/6509bc064a8a916f7a2dfb01/submission?data.annoncePubliee=true&limit=1000`
    );
    if (res.status === 200) {
      const data = await res.json();
      return data;
    } else {
      return "error";
    }
  } catch (err) {
    return "error";
  }
};
export const getAnnoncesByOwner = async (owner: string) => {
  try {
    const res = await fetch(
      `https://formio.mobilecoveragemap.com/form/6509bc064a8a916f7a2dfb01/submission?owner=${owner}`
    );
    if (res.status === 200) {
      const data = await res.json();
      return data;
    } else if (res.status === 440) {
      store.dispatch(disconnect());
    } else {
      return "error";
    }
  } catch (err) {
    return "error";
  }
};

export const putAnnonce = async (id: string, annonce: any) => {
  try {
    const res = await formioRequest({
      url: `/6509bc064a8a916f7a2dfb01/submission/${id}`,
      method: "PUT",
      data: JSON.stringify({ data: annonce.data }),
      headers: {
        "Content-Type": "application/json",
        "x-jwt-token": localStorage.getItem("token") as string,
      },
      // headers: {
      //   // "x-jwt-token": store.getState().auth.token ,
      //   "x-jwt-token": localStorage.getItem("token") as string,
      // },
    });
    if (res.status === 200) {
      return "ok";
    } else if (res.status === 440) {
      store.dispatch(disconnect());
    } else {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};

export const patchAnnonces = async (
  annonceId: string,
  data: { op: string; path: string; value: any }[]
) => {
  try {
    const res = await formioRequest({
      url: `/6509bc064a8a916f7a2dfb01/submission/${annonceId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-jwt-token": localStorage.getItem("token") as string,
      },
      // headers: {
      //   "x-jwt-token": store.getState().auth.token,
      // },
    });

    if (res.status === 200) {
      const resData = res.data;
      set(resData, data[0].path.split("/").slice(1), data[0].value);
      putAnnonce(annonceId, resData);
    } else if (res.status === 440) {
      store.dispatch(disconnect());
    } else {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};

export const newAnnonce = async (token: string) => {
  const ref = Math.ceil(Math.random() * 10000)
    .toString()
    .padStart(4, "0");
  const annonce = annonceTemplate(parseInt(ref));
  try {
    const res = await fetch(
      "https://formio.mobilecoveragemap.com/form/6509bc064a8a916f7a2dfb01/submission",
      {
        method: "POST",
        body: JSON.stringify(annonce),
        headers: {
          "Content-Type": "application/json",
          "x-jwt-token": token,
        },
      }
    );
    if (res.status === 400) {
      const json = await res.json();
      if (json.details[0].message === "ref must be unique") {
        newAnnonce(token);
      } else {
        return "error";
      }
    } else if (res.status === 201) {
      return "ok";
    } else {
      return "error";
    }
  } catch (err) {
    return "error";
  }
};

export const getOneAnnonce = async (id: string) => {
  try {
    let res = await formioRequest({
      url: `/6509bc064a8a916f7a2dfb01/submission/${id}`,
      method: "GET",
    });
    if (res.status === 200) {
      return res.data;
    } else {
      return "error";
    }
  } catch (error: any) {
    return "error";
  }
};
