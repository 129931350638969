/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef } from "react";
import { getRdvAdmin, deleteRdvAmplify } from "../../requests/amplify";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  TextField,
  Container,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setMessage } from "../../store/errorSuccessSlice";
import { useSelector } from "react-redux";
import { selectAnnonceById } from "../../store/annonceSlice";
import { EntityId } from "@reduxjs/toolkit";
import { useParams, useSearchParams } from "react-router-dom";
import { setLoading } from "../../store/uiSlice";
// import { useRouter } from "next/router";
// import { useSearchParams } from "next/navigation";

type Props = {};

const ManageRdv = (props: Props) => {
  // const router = useRouter();
  // const rdvId = router.query.rdvId;
  const { rdvId } = useParams();
  const [searchParams] = useSearchParams();
  const annonce = useSelector((state: any) =>
    selectAnnonceById(state, searchParams.get("annonce") as EntityId)
  );
  const dispatch = useDispatch();
  const [rdv, setRdv] = useState<any>();
  const [rdvData, setRdvData] = useState<any>();
  const email = useRef<any>();
  const [error, setError] = useState(false);
  const [deletedRdv, setDeletedRdv] = useState(false);

  useEffect(() => {
    if (rdv !== undefined) {
      const date = new Intl.DateTimeFormat("fr-FR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(new Date(rdv?.data?.debut));
      const heureDebut = new Date(rdv?.data?.debut).toLocaleString("fr-FR", {
        timeZone: "Europe/Paris",
        hour: "2-digit",
        minute: "2-digit",
      });
      const heureFin = new Date(rdv?.data?.fin).toLocaleString("fr-FR", {
        timeZone: "Europe/Paris",
        hour: "2-digit",
        minute: "2-digit",
      });
      setRdvData({ date, heureDebut, heureFin });
    }
  }, [rdv]);

  const getRdv = async () => {
    getRdvAdmin(rdvId as string, email.current.value)
      .then((res) => {
        if (res !== undefined) {
          setRdv(res);
        } else {
          setError(true);
        }
      })
      .catch((err) => setError(true));
  };

  const deleteRdv = () => {
    dispatch(setLoading({ isLoading: true }));
    deleteRdvAmplify({
      ownerId: rdv.data.vendeurId,
      rdvId: rdvId as string,
      titre: annonce!.data.titre,
      annonce: searchParams.get("annonce") as string,
    }).then((res) => {
      if (res.type === "success") {
        setDeletedRdv(true);
        dispatch(setLoading({ isLoading: false }));
      } else {
        dispatch(setMessage({ message: res.message, type: res.type }));
        dispatch(setLoading({ isLoading: false }));
      }
    });
  };
  if (rdvId === undefined || searchParams.get("annonce") === null) {
    return (
      <Container maxWidth="lg" sx={{ mt: 6, textAlign: "center" }}>
        <Typography>
          Une erreur est survenue. Si vous avez cliqué sur le lien reçu par
          email, contactez-nous.
        </Typography>
      </Container>
    );
  }

  if (deletedRdv) {
    return (
      <Container maxWidth="lg" sx={{ mt: 6, textAlign: "center" }}>
        <Typography>Le rendez-vous à bien été supprimé</Typography>
      </Container>
    );
  }
  return rdvData ? (
    <Container maxWidth="lg" sx={{ mt: 6 }}>
      <Card>
        <CardContent>
          <Typography>Rendez-vous</Typography>
          <Typography>
            Le {rdvData.date} de {rdvData.heureDebut} à {rdvData.heureFin}
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => deleteRdv()}>Annuler le rendez-vous</Button>
        </CardActions>
      </Card>
    </Container>
  ) : !error ? (
    <Container
      maxWidth="lg"
      sx={{
        mt: 6,
        textAlign: "center",
      }}
    >
      <Stack spacing={2}>
        <TextField inputRef={email} label="Adresse email" />
        <Button variant="contained" onClick={getRdv}>
          Récupérer mon rendez-vous
        </Button>
      </Stack>
    </Container>
  ) : (
    <Container maxWidth="lg" sx={{ mt: 6, textAlign: "center" }}>
      <Typography>
        Nous n'avons pas trouvé de rendez-vous associé à cette adresse email.
      </Typography>
      <Typography mb={3}>
        Cela peut arriver si l'adresse email n'est pas celle associée au
        rendez-vous ou si le rendez-vous a déjà été supprimé
      </Typography>
      <Button variant="contained" onClick={() => setError(false)}>
        Essayer une autre adresse
      </Button>
    </Container>
  );
};

export default ManageRdv;
