import React, { useEffect, useState } from "react";
import { Container, Box, Typography } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectArticleById } from "../../store/articleSlice";
import { EntityId } from "@reduxjs/toolkit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
} from "react-share";
import TwitterIcon from "react-share/lib/TwitterIcon";
import { renderDoc } from "../../requests/s3";

type Props = {};

const Article = (props: Props) => {
  const { articleSlug } = useParams();
  const location = useLocation();
  const article = useSelector((state) =>
    selectArticleById(state, articleSlug as EntityId)
  );
  const [image, setImage] = useState("");

  useEffect(() => {
    if (article !== undefined) {
      if (article.data.image.imageUrl.startsWith("direct")) {
        getImage(article.data.image.imageUrl).then((res) => {
          setImage((res as { url: string; data: string }).url);
        });
      } else {
        setImage(article.data.image.imageUrl);
      }
      //preloading image
      const img = new Image();
      img.src = image;
    }
  }, [article]);

  const getImage = async (path: string) => {
    return await renderDoc(path);
  };

  return article ? (
    <Container maxWidth="xl">
      <img
        src={image}
        style={{ width: "100%", margin: 0, padding: 0, display: "block" }}
        alt={article.data.slug.split("-").join(" ")}
      />
      <Box mb={10}>
        <Box
          component={Link}
          to="/blog"
          sx={{
            backgroundColor: "primary.main",
            display: "flex",
            pt: 1,
            pl: 1,
            color: "white",
            textDecoration: "none",
          }}
        >
          <ArrowBackIcon color="inherit" sx={{ mr: 1 }} />
          retour
        </Box>
        <Typography
          variant="h1"
          py={15}
          px={4}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            textAlign: "center",
          }}
        >
          {article.data.titre}
        </Typography>
        <Typography
          maxWidth="md"
          margin="auto"
          mt={6}
          variant="body2"
          sx={{
            "&>p": { marginTop: "16px" },
            "&>ul>li": { marginBottom: "16px" },
          }}
          dangerouslySetInnerHTML={{ __html: article.data.content }}
        ></Typography>
        <Box mt={4} textAlign={"right"}>
          <Typography>Partager sur :</Typography>
          <LinkedinShareButton
            url={`https://www.direct-proprietaire.fr${location.pathname}`}
          >
            <LinkedinIcon size={40} round={true} />
          </LinkedinShareButton>
          <FacebookShareButton
            url={`https://www.direct-proprietaire.fr${location.pathname}`}
          >
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>
          <TwitterShareButton
            url={`https://www.direct-proprietaire.fr${location.pathname}`}
          >
            <TwitterIcon size={40} round={true} bgStyle={{ fill: "#0f1419" }} />
          </TwitterShareButton>
        </Box>
      </Box>
    </Container>
  ) : null;
};

export default Article;
