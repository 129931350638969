import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectPaginationProp } from "../store/paginationSlice";

const usePagination = (data: any[]) => {
  const currentPage = useSelector(selectPaginationProp("currentPage"));
  const numberPerPage = useSelector(selectPaginationProp("numberPerPage"));
  const [displayedData, setDisplayedData] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const arr = data.slice(
        (currentPage - 1) * numberPerPage,
        currentPage * numberPerPage
      );
      setDisplayedData(arr);
    }
  }, [data, currentPage, numberPerPage]);
  return displayedData;
};

export default usePagination;
