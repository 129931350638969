export const annonceTemplate = (ref: number) => ({
  data: {
    data: {
      hero: {
        layers: [
          {
            layout: "image",
            speed: 0,
            image: {
              src: {
                id: 2,
                name: "photo-principale.jpg",
                size: 359906,
                type: "image/jpeg",
                valid: true,
                imageUrl:
                  "direct-proprietaire/annonces/annonceTemplate/photo-principale.jpg",
              },
              alt: "background",
              style: {
                objectFit: "cover",
              },
              filter: {
                brightness: 70,
              },
            },
            maxWidth: false,
          },
          {
            layout: "text",
            speed: 0,
            texts: [
              {
                variant: "h1",
                component: "h1",
                fontWeight: "700",
                children: "",
                align: "center",
              },
              {
                variant: "h2",
                component: "h2",
                fontWeight: "400",
                children: "",
                align: "center",
              },
            ],
            maxWidth: false,
          },
          {
            layout: "image-right",
            speed: null,
            image: {
              src: "/banner-background.jpg",
              alt: "background",
              style: {
                objectFit: "cover",
              },
              filter: {
                brightness: 0,
                opacity: 0,
              },
            },
            texts: [
              {
                variant: "h1",
                children: "Appartement 125m² Lyon 6  Brotteaux-Massena",
                color: "background.default",
              },
              {
                variant: "h3",
                children: "Localisation : Lyon 6, 69006",
                color: "background.default",
              },
              {
                variant: "h3",
                children: "Nombre de pièces : 5 pièces",
                color: "background.default",
              },
              {
                variant: "h3",
                children: "Surface habitable : 125m2",
                color: "background.default",
              },
            ],
          },
        ],
      },
      presentation: {
        items: [
          {
            description: {
              title: {
                data: "Pièces de vie",
              },
              subtitle: {
                data: "Séjour moderne et lumineux",
              },
              text: {
                data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.\n",
              },
            },
            images: [
              {
                image: {
                  id: 3,
                  name: "annonce-piece-de-vie-pacejoy-tht9awecp74-unsplash-1-copie.webp",
                  size: 95394,
                  type: "image/webp",
                  valid: true,
                  imageUrl:
                    "direct-proprietaire/annonces/annonceTemplate/annonce-piece-de-vie-pacejoy-tht9awecp74-unsplash-1-copie.webp",
                },
                description:
                  "Une grande pièce de vie pour partager un verre entre amis",
              },
              {
                image: {
                  id: 5,
                  name: "spacejoy-3z_61bnbFhM-unsplash.jpg",
                  size: 88622,
                  type: "image/webp",
                  valid: true,
                  imageUrl:
                    "direct-proprietaire/annonces/annonceTemplate/spacejoy-3z_61bnbFhM-unsplash.jpg",
                },
                description:
                  "Un séjour lumineux pour boire le café avec un peu de musique et un journal",
              },
            ],
          },
          {
            description: {
              title: {
                data: "Pièces de vie",
              },
              subtitle: {
                data: "Cuisine indépendante équipée sur-mesures",
              },
              text: {
                data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.\n   ",
              },
            },
            images: [
              {
                image: {
                  id: 12,
                  name: "rune-enstad-UXFJ-6Zj27M-unsplash.jpg",
                  size: 64290,
                  type: "image/jpeg",
                  valid: true,
                  imageUrl:
                    "direct-proprietaire/annonces/annonceTemplate/rune-enstad-UXFJ-6Zj27M-unsplash.jpg",
                },
                description: "",
              },
            ],
          },
          {
            description: {
              title: {
                data: "Pièces de vie",
              },
              subtitle: {
                data: "Petit salon cosy et chaleureux",
              },
              text: {
                data: "Agréable pièce de vie aménagée pour pouvoir se reposer avec un livre et un peu de musique mais aussi pour travailler ponctuellement sur un petit bureau d’appoint les jours de télétravail.",
              },
            },
            images: [
              {
                image: {
                  id: 6,
                  name: "spacejoy-3z_61bnbFhM-unsplash.jpg",
                  size: 451722,
                  type: "image/jpeg",
                  valid: true,
                  imageUrl:
                    "direct-proprietaire/annonces/annonceTemplate/spacejoy-3z_61bnbFhM-unsplash.jpg",
                },
                description: "",
              },
              {
                image: {
                  id: 18,
                  name: "annonce-piece-de-vie-pacejoy-tht9awecp74-unsplash-1-copie.webp",
                  size: 86096,
                  type: "image/webp",
                  valid: true,
                  imageUrl:
                    "direct-proprietaire/annonces/annonceTemplate/annonce-piece-de-vie-pacejoy-tht9awecp74-unsplash-1-copie.webp",
                },
                description: "",
              },
            ],
          },
          {
            description: {
              title: {
                data: "Chambres",
              },
              subtitle: {
                data: "Chambre d'enfants",
              },
              text: {
                data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.\n",
              },
            },
            images: [
              {
                image: {
                  id: 9,
                  name: "sidekix-media-jkF_WFUQzSI-unsplash.jpg",
                  size: 50422,
                  type: "image/jpeg",
                  valid: true,
                  imageUrl:
                    "direct-proprietaire/annonces/annonceTemplate/sidekix-media-jkF_WFUQzSI-unsplash.jpg",
                },
                description: "",
              },
            ],
          },
          {
            description: {
              title: {
                data: "Chambres",
              },
              subtitle: {
                data: "Chambre parentale chic et distinguée",
              },
              text: {
                data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.\n    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus suscipit tortor eget felis porttitor volutpat. Sed porttitor lectus nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.",
              },
            },
            images: [
              {
                image: {
                  id: 15,
                  name: "spacejoy-vOa-PSimwg4-unsplash.jpg",
                  size: 149954,
                  type: "image/jpeg",
                  valid: true,
                  imageUrl:
                    "direct-proprietaire/annonces/annonceTemplate/spacejoy-vOa-PSimwg4-unsplash.jpg",
                },
                description: "",
              },
            ],
          },
          {
            description: {
              title: {
                data: "Autres pièces",
              },
              subtitle: {
                data: "Salle de bain moderne et épurée",
              },
              text: {
                data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.\n",
              },
            },
            images: [
              {
                image: {
                  id: 2,
                  name: "collov-home-design-kSoe7EoxHIE-unsplash.jpg",
                  size: 111662,
                  type: "image/jpeg",
                  valid: true,
                  imageUrl:
                    "direct-proprietaire/annonces/annonceTemplate/collov-home-design-kSoe7EoxHIE-unsplash.jpg",
                },
                description: "",
              },
            ],
          },
        ],
        minItems: 1,
        maxItems: 15,
        display: true,
      },
      informations: {
        description:
          "<p>Appartement - 125 m2 - 4 ch <br />Lyon 6 ème, entre le secteur de Montgolfier et de La Rédemption, dans un immeuble de bon standing , l’appartement lumineux et traversant de 125 m2 comprenant un hall d’entée, un double séjour en exposition Ouest, une cuisine indépendantes, aménagée et équipée avec un cagibi, actuellement 2 chambres au calme sur côté Est, possibilité d’une troisième chambre comme à l’origine. De nombreux placards et rangements. Une salle de bains , un wc. Une cave et possibilité d’acquérir en supplément un garage en sous-sol au prix de 50 000 € FAI.Soit prix appartement 650 000 € FAI + GARAGE 50 000 € FAI = 625 000 € Commerces , transports, écoles et Lycée à proximité .une vraie vie de quartier dans un secteur très convoité.<br />Copropriété de 30 lots<br />Charges annuelles : 4536 euros. <br />Procédure en cours : NON Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site Géorisques : *** <br />Les Plus : ÉTAGE ÉLEVÉ - VUE SUR FOURVIÈRE - Secteur prisé <br /><br />Référence annonce : 2023382 <br />Date de réalisation du diagnostic : 03/04/2023 <br /><br />Consommation énergie primaire : Non communiquée. <br />Consommation énergie finale : Non communiquée. <br /><br />Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site Géorisques : www.georisques.gouv.fr</p>",
        localisation: {
          data: "Lyon 6, 69006",
        },
        nombrePieces: {
          data: 5,
        },
        surface: {
          data: 125,
        },
        prix: {
          data: 700000,
        },
        classeEnergie: {
          data: "B",
        },
        ges: {
          data: "C",
        },
        ascenseur: {
          data: true,
        },
        etage: {
          data: 6,
        },
        typeBien: "appartement",
      },
    },
    achatLocation: "",
    ref: ref,
    titre: "Appartement 125m2 Lyon 6 Brotteaux-Massena",
    annoncePubliee: false,
    thumbnail: {
      id: 3,
      name: "photo-principale.jpg",
      size: 359906,
      type: "image/jpeg",
      valid: true,
      imageUrl:
        "direct-proprietaire/annonces/annonceTemplate/photo-principale.jpg",
    },
  },
});
