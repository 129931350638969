import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  styled,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { selectAnnonceProp, setAnnonceProp } from "../../store/annonceSlice";
import { useParams } from "react-router-dom";
// import { useRouter } from "next/router";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(0.5),
      border: 0,
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  })
);

const TextFormating = ({
  path,
  defaultAlign,
  defaultWeight,
  source = "page",
}: {
  path: string[];
  source?: string;
  defaultAlign: string;
  defaultWeight: string;
}) => {
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const props = useSelector(selectAnnonceProp(path.slice(1), {}));
  const weights = ["300", "400", "500", "700"];
  const dispatch = useDispatch();
  const { align = defaultAlign } = props;
  const { underline = "none" } = props;
  const { weight = defaultWeight } = props;

  const handleAlignChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "align"],
        value: value,
      })
    );
  };
  const handleUnderlineChange = () => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "underline"],
        value: underline === "none" ? "underline" : "none",
      })
    );
  };
  const handleWeightChange = (sign: string) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "weight"],
        value:
          sign === "+"
            ? weights[weights.indexOf(weight) + 1]
            : weights[weights.indexOf(weight) - 1],
      })
    );
  };
  return (
    <Box>
      <Paper sx={{ display: "inline-flex", mt: 2 }}>
        <StyledToggleButtonGroup
          size="small"
          value={align}
          exclusive
          onChange={handleAlignChange}
          aria-label="text alignment"
        >
          <ToggleButton value="left" aria-label="left aligned">
            <FormatAlignLeftIcon />
          </ToggleButton>
          <ToggleButton value="center" aria-label="centered">
            <FormatAlignCenterIcon />
          </ToggleButton>
          <ToggleButton value="right" aria-label="right aligned">
            <FormatAlignRightIcon />
          </ToggleButton>
          <ToggleButton value="justify" aria-label="justified">
            <FormatAlignJustifyIcon />
          </ToggleButton>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        <ToggleButton
          size="small"
          value={underline}
          selected={underline === "underline" ? true : false}
          onChange={handleUnderlineChange}
          sx={{ border: "0", m: 0.5 }}
        >
          <FormatUnderlinedIcon />
        </ToggleButton>
      </Paper>

      <Box
        display="inline-flex"
        alignItems="center"
        sx={{ border: "1px solid #0000001f", mt: 1, borderRadius: "4px" }}
      >
        <IconButton
          sx={{ borderRadius: "0", borderRight: "1px solid #0000001f" }}
          onClick={() => handleWeightChange("-")}
          disabled={weight === "300" ? true : false}
        >
          <RemoveIcon />
        </IconButton>
        <FormatBoldIcon sx={{ margin: 1 }} />
        <IconButton
          sx={{ borderRadius: "0", borderLeft: "1px solid #0000001f" }}
          onClick={() => handleWeightChange("+")}
          disabled={weight === "700" ? true : false}
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TextFormating;
