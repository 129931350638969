import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { withSectionWrapper } from "../SectionWrapper";
import { Box, Typography, Stack, Button } from "@mui/material";
import DOMPurify from "isomorphic-dompurify";
import { setSelected } from "../../store/uiSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// import { useRouter } from "next/router";

type Props = {
  data: {
    typeBien: string;
    description: string;
    localisation: {
      data: string;
      color?: string;
    };
    nombrePieces: {
      data: number;
      color?: string;
    };
    nombreChambres?: {
      data: number;
      color?: string;
    };
    surface: {
      data: number;
      color?: string;
    };
    prix: {
      data: number;
      color?: string;
    };
    classeEnergie: {
      data: "A" | "B" | "C" | "D" | "E" | "F";
      color?: string;
    };
    ges: {
      data: "A" | "B" | "C" | "D" | "E" | "F";
      color?: string;
    };
    ascenseur?: {
      data: boolean;
      color?: string;
    };
    etage?: {
      data: number;
      color?: string;
    };
    modeChauffage?: {
      display?: boolean;
      data?: string;
      color?: string;
    };
    anneeConstruction?: {
      display?: boolean;
      data?: number;
      color?: string;
    };
    taxeFonciere?: {
      display?: boolean;
      data?: number;
      color?: string;
    };
    charges?: {
      display?: boolean;
      data?: number;
      color?: string;
    };
    exterieur?: {
      display?: boolean;
      data?: number;
      color?: string;
    };
  };
  setInterested: Dispatch<SetStateAction<boolean>>;
};

const Informations = ({ data, setInterested }: Props) => {
  const location = useLocation();
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    setDescription(DOMPurify.sanitize(data.description));
  }, [data.description]);
  const etage = () => {
    if (data.etage?.data === 0) {
      return "rez-de-chaussée";
    } else if (data.etage?.data === 1) {
      return "1er";
    } else {
      return `${data.etage?.data}ème`;
    }
  };
  const selected = () => {
    dispatch(
      setSelected({
        path: ["informations"],
        inputType: "InformationsInput",
      })
    );
  };
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      sx={{ p: 2 }}
      onClick={selected}
    >
      <Box
        flex={1}
        display="flex"
        flexDirection={"column"}
        sx={{ mr: 2, mb: 2 }}
      >
        <Typography variant="h3" component="p">
          Description
        </Typography>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: description }}
        ></Typography>
      </Box>
      <Stack flex={1} spacing={2}>
        <Box
          display={"flex"}
          justifyContent="space-between"
          color={data.localisation?.color}
        >
          <Typography variant="h3" component="p">
            Localisation&nbsp;:
          </Typography>
          <Typography variant="h3" component="p">
            {data.localisation.data}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent="space-between"
          color={data.nombrePieces?.color}
        >
          <Typography variant="h3" component="p">
            Nombre de pièces&nbsp;:
          </Typography>
          <Typography variant="h3" component="p">
            {data.nombrePieces.data}{" "}
            {data.nombrePieces.data === 1 ? "pièce" : "pièces"}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent="space-between"
          color={data.nombrePieces?.color}
        >
          <Typography variant="h3" component="p">
            Nombre de chambres&nbsp;:
          </Typography>
          <Typography variant="h3" component="p">
            {data.nombreChambres?.data}{" "}
            {data.nombreChambres?.data === 1 ? "chambre" : "chambres"}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent="space-between"
          color={data.surface?.color}
        >
          <Typography variant="h3" component="p">
            Surface habitable&nbsp;:
          </Typography>
          <Typography variant="h3" component="p">
            {data.surface.data}
            m²
          </Typography>
        </Box>
        {!data.exterieur?.display ? null : (
          <Box
            display={"flex"}
            justifyContent="space-between"
            color={data.exterieur?.color}
          >
            <Typography variant="h3" component="p">
              Extérieur&nbsp;:
            </Typography>
            <Typography variant="h3" component="p">
              {data.exterieur?.data}
              m²
            </Typography>
          </Box>
        )}
        <Box
          display={"flex"}
          justifyContent="space-between"
          color={data.prix?.color}
        >
          <Typography variant="h3" component="p">
            Prix&nbsp;:
          </Typography>
          <Typography variant="h3" component="p">
            {new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
              maximumFractionDigits: 0,
            }).format(data.prix.data)}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          component="p"
          sx={{ alignSelf: "flex-end" }}
          style={{ marginTop: 0 }}
        >
          {/* @ts-ignore */}
          {parseInt(data.prix.data / data.surface.data)}
          €/m²
        </Typography>
        <Box display="flex">
          <Stack flex={1} spacing={2} sx={{ mr: 3 }}>
            {!data.anneeConstruction?.display ? null : (
              <Box
                display={"flex"}
                justifyContent="space-between"
                color={data.anneeConstruction?.color}
              >
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: "0.9rem" }}
                >
                  Année de construction&nbsp;:
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: "0.9rem" }}
                >
                  {data.anneeConstruction?.data}
                </Typography>
              </Box>
            )}
            <Box
              display={"flex"}
              justifyContent="space-between"
              color={data.classeEnergie?.color}
            >
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: "0.9rem" }}
              >
                Classe énergétique&nbsp;:
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: "0.9rem" }}
              >
                {data.classeEnergie.data}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent="space-between"
              color={data.ges?.color}
            >
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: "0.9rem" }}
              >
                GES&nbsp;:
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: "0.9rem" }}
              >
                {data.ges.data}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent="space-between"
              alignItems="flex-end"
              color={data.modeChauffage?.color}
            >
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: "0.9rem" }}
              >
                Mode de chauffage principal&nbsp;:
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: "0.9rem" }}
              >
                {data.modeChauffage?.data}
              </Typography>
            </Box>
            {!data.taxeFonciere?.display ? null : (
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignItems="flex-end"
                color={data.taxeFonciere?.color}
              >
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: "0.9rem" }}
                >
                  Taxe foncière&nbsp;:
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: "0.9rem" }}
                >
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(data.taxeFonciere?.data as number)}
                </Typography>
              </Box>
            )}
            {!data.charges?.display ? null : (
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignItems="flex-end"
                color={data.charges?.color}
              >
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: "0.9rem" }}
                >
                  Charges&nbsp;:
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: "0.9rem" }}
                >
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(data.charges?.data as number)}
                </Typography>
              </Box>
            )}
          </Stack>
          {data.typeBien === "appartement" ? (
            <Stack flex={1} spacing={2}>
              <Box
                display={"flex"}
                justifyContent="space-between"
                color={data.ascenseur?.color}
              >
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: "0.9rem" }}
                >
                  Ascenseur&nbsp;:
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: "0.9rem" }}
                >
                  {data.ascenseur?.data ? "Oui" : "Non"}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent="space-between"
                color={data.etage?.color}
              >
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: "0.9rem" }}
                >
                  Étage de votre bien&nbsp;:
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: "0.9rem" }}
                >
                  {etage()}
                </Typography>
              </Box>
            </Stack>
          ) : null}
        </Box>
        <Button
          variant="contained"
          onClick={() => setInterested(true)}
          disabled={location.pathname.includes("/modification/")}
          sx={{
            display: "block",
            mt: 2,
            fontSize: "1.2rem",
            background:
              "var(--linear-2-paints, radial-gradient(95.16% 79.42% at 98.94% -11.71%, #FFC254 0%, rgba(255, 194, 85, 0.00) 100%), linear-gradient(47deg, #2D4EFC -5.89%, #CE30C4 58.72%, #FFA400 122.21%))",
          }}
        >
          Je suis intéressé
        </Button>
      </Stack>
    </Box>
  );
};

export default withSectionWrapper(Informations);
