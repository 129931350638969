import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@mui/material";

type Props = {
  checked: string[];
  setChecked: Dispatch<SetStateAction<string[]>>;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const filters = [
  "Nouvelles demandes",
  "Disponibilités visio envoyées",
  "Rendez-vous visio programmé",
  "Rendez-vous visio passé",
  "Demande de retour envoyée",
  "Retour envoyé",
  "Disponibilités sur place envoyées",
  "Rendez-vous sur place programmé",
  "Rendez-vous sur place passé",
];
const FilterReponses = ({ checked, setChecked }: Props) => {
  const handleChange = (event: SelectChangeEvent<typeof filters>) => {
    const {
      target: { value },
    } = event;
    setChecked(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl sx={{ m: 1, width: 400 }}>
      <InputLabel id="demo-multiple-checkbox-label">Filtres</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        value={checked}
        onChange={handleChange}
        multiple
        input={<OutlinedInput label="Filtres" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {filters.map((fil) => (
          <MenuItem key={fil} value={fil}>
            <Checkbox checked={checked.indexOf(fil) > -1} />
            <ListItemText primary={fil} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterReponses;
