import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import LoginForm from "../pages/Login";
import Annonce from "../pages/annonce/Annonce";
import Dashboard from "../pages/Dashboard";
import Layout from "./Layout";
import CalendrierDispos from "../pages/calendrier/CalendrierDispos";
import ManageRdv from "../pages/rdv/ManageRdv";
import Retour from "../pages/retour/Retour";
// import TrouverAnnonce from "../pages/TrouverAnnonce";
// import AcheteurHome from "../pages/AcheteurHome";
import Page from "../pages/modification/Edition";
import ProtectedRoutes from "./ProtectedRoutes";
import MonCompte from "../pages/MonCompte";
import SignUp from "../pages/SignUp";
import Blog from "../pages/Blog";
import MentionsLegales from "../pages/MentionsLegales";
import PolitiqueConfidentialite from "../pages/PolitiqueConfidentialite";
import CGV from "../pages/CGV";
import Assistance from "../pages/Assistance";
import Article from "../pages/blog/Article";
import BlogWrapper from "./BlogWrapper";
import Annonces from "../pages/Annonces";
import { Typography } from "@mui/material";
import Coaching from "../pages/Coaching";
import Success from "../pages/Success";
import Cancel from "../pages/Cancel";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Typography>Une erreur s'est produite</Typography>,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "connexion",
        element: <LoginForm />,
      },
      {
        path: "creer-compte",
        element: <SignUp />,
      },
      {
        path: "mentions-legales",
        element: <MentionsLegales />,
      },
      {
        path: "politique-confidentialite",
        element: <PolitiqueConfidentialite />,
      },
      {
        path: "cgv",
        element: <CGV />,
      },
      {
        path: "assistance",
        element: <Assistance />,
      },
      { path: "coaching", element: <Coaching /> },
      {
        path: "success",
        element: <Success />,
      },
      {
        path: "cancel",
        element: <Cancel />,
      },
      // {
      //   path: "trouver-une-annonce",
      //   element: <TrouverAnnonce />,
      // },
      {
        path: "blog",
        element: <BlogWrapper />,
        children: [
          {
            index: true,
            element: <Blog />,
          },
          {
            path: ":articleSlug",
            element: <Article />,
          },
        ],
      },

      {
        path: "annonce",
        children: [
          {
            path: ":annonceId",
            element: <Annonce />,
          },
        ],
      },
      { path: "annonces", element: <Annonces /> },
      {
        path: "calendrier/:vendeurId",
        element: <CalendrierDispos />,
      },
      {
        path: "rdv/:rdvId",
        element: <ManageRdv />,
      },
      {
        path: "retour/:reponseId",
        element: <Retour />,
      },
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: "mon-compte",
            element: <MonCompte />,
          },
          {
            path: "tableau-de-bord",
            element: <Dashboard />,
          },
          {
            path: "/modification",
            children: [
              {
                path: ":annonceId",
                element: <Page />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
