/* eslint-disable no-lone-blocks */
import { useSelector, useDispatch } from "react-redux";
import { selectAnnonceById } from "../../store/annonceSlice";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getRdv } from "../../requests/rdv";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  Button,
} from "@mui/material";
import {
  askRetour,
  sendDispos,
  sendDisposSurplace,
} from "../../requests/amplify";
import { setMessage } from "../../store/errorSuccessSlice";
import { setLoading } from "../../store/uiSlice";
import { EntityId } from "@reduxjs/toolkit";
import Historique from "./Historique";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import {
  getArchivedReponses,
  getReponses,
  patchReponse,
} from "../../requests/reponses";

const AcheteurCard = ({
  reponse,
  // render,
  ownerId,
  index,
  setReponses,
  type = "potentiel",
}: {
  reponse: any;
  // render: (path: string) => void;
  ownerId: string;
  index: number;
  setReponses: Dispatch<SetStateAction<any>>;
  type?: "potentiel" | "archives";
}) => {
  const dispatch = useDispatch();
  const annonce = useSelector((state) =>
    selectAnnonceById(state, reponse.data.annonceId as EntityId)
  );
  const [rdv, setRdv] = useState<any>();

  useEffect(() => {
    if (reponse.data.rdvVirtuel !== "") {
      if (reponse.data.rdvSurplace !== "") {
        setRdv(reponse.data.rdvSurplace);
      } else {
        setRdv(reponse.data.rdvVirtuel);
      }
    }
  }, [reponse]);

  const sendDisposVirtuelle = () => {
    dispatch(setLoading({ isLoading: true }));
    sendDispos({
      ownerId,
      acheteurId: reponse.data.acheteurId,
      reponseId: reponse._id,
      titre: annonce!.data.titre,
    }).then((res) => {
      dispatch(setMessage({ message: res.message, type: res.type }));
      patchReponse(reponse._id, [
        {
          op: "replace",
          path: "/data/dispoVirtuelle",
          value: true,
        },
      ]).then(() => {
        getReponses().then((res) => {
          if (res !== "error") {
            setReponses(res);
          }
        });
      });
      dispatch(setLoading({ isLoading: false }));
    });
  };

  const sendDisposPlace = () => {
    dispatch(setLoading({ isLoading: true }));
    sendDisposSurplace({
      ownerId,
      acheteurId: reponse.data.acheteurId,
      reponseId: reponse._id,
      titre: annonce!.data.titre,
    }).then((res) => {
      dispatch(setMessage({ message: res.message, type: res.type }));
      patchReponse(reponse._id, [
        {
          op: "replace",
          path: "/data/dispoSurplace",
          value: true,
        },
      ]).then(() => {
        getReponses().then((res) => {
          if (res !== "error") {
            setReponses(res);
          }
        });
      });
      dispatch(setLoading({ isLoading: false }));
    });
  };

  const sendRetour = () => {
    dispatch(setLoading({ isLoading: true }));
    askRetour({
      reponseId: reponse._id,
      acheteurId: reponse.data.acheteurId,
      titre: annonce!.data.titre,
    }).then((res) => {
      dispatch(setMessage({ message: res.message, type: res.type }));
      patchReponse(reponse._id, [
        {
          op: "replace",
          path: "/data/demandeRetour",
          value: true,
        },
      ]).then(() => {
        getReponses().then((res) => {
          if (res !== "error") {
            setReponses(res);
          }
        });
      });
      dispatch(setLoading({ isLoading: false }));
    });
  };

  const archiveResponse = () => {
    dispatch(setLoading({ isLoading: true }));
    patchReponse(reponse._id, [
      {
        op: "replace",
        path: "/data/archiver",
        value: true,
      },
    ]).then(() => {
      getReponses().then((res) => {
        if (res !== "error") {
          setReponses(res);
        }
      });
    });
    dispatch(setLoading({ isLoading: false }));
  };

  const unarchived = () => {
    dispatch(setLoading({ isLoading: true }));
    patchReponse(reponse._id, [
      {
        op: "replace",
        path: "/data/archiver",
        value: false,
      },
    ]).then(() => {
      getArchivedReponses().then((res) => {
        if (res !== "error") {
          setReponses(res);
        }
      });
    });
    dispatch(setLoading({ isLoading: false }));
  };

  return reponse ? (
    <>
      <Card
        sx={{
          m: 4,
          boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: "12px",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              flex: { md: 4, xs: 1 },
            }}
          >
            <Typography variant="h3" component="h2" mb={2}>
              {reponse?.data.nom}
            </Typography>
            <Box mr={{ xs: 0, md: 6 }} fontSize={"0.9rem"}>
              <Box display="flex" justifyContent="space-between" mb={0.5}>
                <Typography fontSize="inherit" color="primary">
                  Financement :
                </Typography>
                <Typography fontSize="inherit">
                  {reponse?.data.accordBanque === "pasBesoin"
                    ? "Comptant"
                    : "Emprunt"}
                </Typography>
              </Box>

              {reponse?.data.accordBanque !== "pasBesoin" ? (
                <>
                  <Box display="flex" justifyContent="space-between" mb={0.5}>
                    <Typography fontSize="inherit" color="primary">
                      Part emprunt :
                    </Typography>
                    <Typography fontSize="inherit">
                      {reponse?.data.partEmprunt}%
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mb={0.5}>
                    <Typography fontSize="inherit" color="primary">
                      Pré-accord de la banque :
                    </Typography>
                    <Typography
                      fontSize="inherit"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {reponse?.data.accordBanque}
                    </Typography>
                  </Box>
                </>
              ) : null}
              <Box display="flex" justifyContent="space-between" mb={0.5}>
                <Typography fontSize="inherit" color="primary">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  L'acheteur connait la region :
                </Typography>
                <Typography
                  fontSize="inherit"
                  sx={{ textTransform: "capitalize" }}
                >
                  {reponse?.data.region}
                </Typography>
              </Box>
              <Typography fontSize="inherit" color="primary">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Ordre d'importance :
              </Typography>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                {reponse?.data.ordreImportance.map((item: string) => (
                  <Typography
                    fontSize="inherit"
                    sx={{ fontWeight: "700", paddingLeft: "17px" }}
                    key={item}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
          <Historique
            created={reponse.created}
            message={reponse.data?.message}
            visio={reponse.data.rdvVirtuel}
            interest={reponse.data?.toujoursInteresse}
            remarques={reponse.data?.remarques}
            dateRetour={reponse.data?.dateRetour}
            surplace={reponse.data.rdvSurplace}
            dispoVirtuelle={reponse.data?.dispoVirtuelle}
            demandeRetour={reponse.data?.demandeRetour}
            dispoSurplace={reponse.data?.dispoSurplace}
          />
        </CardContent>
        {type === "archives" ? (
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                padding: "14px 20px",
                borderRadius: "8px",
                fontWeight: "700",
                "&:hover": { color: "primary.main" },
              }}
              onClick={() => unarchived()}
            >
              Je souhaite retourner cette demande dans les demandes reçues
            </Button>
          </CardActions>
        ) : (
          <CardActions sx={{ justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              sx={{ py: 1 }}
              onClick={() => archiveResponse()}
            >
              Archiver
            </Button>
            {rdv === undefined && !reponse.data.dispoVirtuelle ? (
              <Button
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  padding: "14px 20px",
                  borderRadius: "8px",
                  fontWeight: "700",
                  "&:hover": { color: "primary.main" },
                }}
                onClick={() => sendDisposVirtuelle()}
              >
                <DateRangeOutlinedIcon sx={{ mr: 1.5 }} />
                Envoyer les disponibilités
              </Button>
            ) : null}
            {rdv !== undefined &&
            new Date(rdv?.fin).getTime() < new Date().getTime() &&
            reponse?.data.toujoursInteresse === "oui" &&
            !reponse.data.dispoSurplace ? (
              <Button
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  padding: "14px 20px",
                  borderRadius: "8px",
                  fontWeight: "700",
                  "&:hover": { color: "primary.main" },
                }}
                onClick={() => sendDisposPlace()}
              >
                <DateRangeOutlinedIcon sx={{ mr: 1.5 }} />
                Envoyer les disponibilités pour une visite sur place
              </Button>
            ) : null}
            {rdv !== undefined &&
            new Date(rdv?.fin).getTime() < new Date().getTime() &&
            reponse?.data.toujoursInteresse === "" &&
            !reponse.data.demandeRetour ? (
              <Button
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  padding: "14px 20px",
                  borderRadius: "8px",
                  fontWeight: "700",
                  "&:hover": { color: "primary.main" },
                }}
                onClick={() => sendRetour()}
              >
                Demander un retour
              </Button>
            ) : null}
          </CardActions>
        )}
      </Card>
    </>
  ) : null;
};

export default AcheteurCard;

// Pour les documents
{
  /* {new Date(
                new Date(reponse?.created).setDate(
                  new Date(reponse?.created).getDate() + 5
                )
              ).getTime() > new Date().getTime() ? (
                reponse?.data.file.map((f: any, index: number) => (
                  <Typography
                    key={f.key}
                    onClick={() => render(f.key)}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    Document {index + 1}
                  </Typography>
                ))
              ) : (
                <Typography>Les documents ne sont plus disponibles</Typography>
              )}*/
}
