import React from "react";
import PresentationInput from "./PresentationInput";
import ThemePicker from "./ThemePicker";
import ParallaxInput from "./ParallaxInput";
import PresentationItemInput from "./PresentationItemInput";
import InformationsInput from "./InformationsInput";

const Inputs = {
  ParallaxInput: ParallaxInput,
  PresentationInput: PresentationInput,
  ThemePicker: ThemePicker,
  PresentationItemInput: PresentationItemInput,
  InformationsInput: InformationsInput,
};

const Editor = (props: {
  path: [string | undefined, string[], string | undefined, string | undefined];
  theme: any;
}) => {
  const index = props.path[0];
  const path = props.path[1];
  const inputType = props.path[2];
  const source = props.path[3] || "page";

  if (inputType && Object.keys(Inputs).includes(inputType)) {
    const Component = Inputs[inputType as keyof typeof Inputs];
    return (
      <Component
        path={[index as string, ...path]}
        setTheme={props.theme}
        source={source}
      />
    );
  }

  return null;
};

export default Editor;
