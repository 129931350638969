import React, { Dispatch, SetStateAction } from "react";
import {
  Theme,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  Chip,
  useTheme,
  OutlinedInput,
} from "@mui/material";

type Props = {
  typeDeBien: string[];
  setTypeDeBien: Dispatch<SetStateAction<string[]>>;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

function getStyles(type: string, typeDeBien: readonly string[], theme: Theme) {
  return {
    fontWeight:
      typeDeBien.indexOf(type) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const TypeDeBien = ({ typeDeBien, setTypeDeBien }: Props) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    setTypeDeBien(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl sx={{ width: 250, height: "64.983px" }}>
      <InputLabel id="demo-multiple-chip-label">Type de bien</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={typeDeBien}
        onChange={handleChange}
        input={
          <OutlinedInput
            id="select-multiple-chip"
            label="Type de bien"
            inputProps={{
              sx: {
                lineHeight: "2em",
              },
            }}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "no-wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                sx={{ textTransform: "capitalize" }}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {["maison", "appartement", "atypique"].map((type) => (
          <MenuItem
            key={type}
            value={type}
            style={{
              ...getStyles(type, typeDeBien, theme),
              textTransform: "capitalize",
            }}
          >
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TypeDeBien;
