import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CustomLabel, CustomRadio, CustomTextfield } from "./CustomStyles";
import {
  selectAnnonceProp,
  selectAnnoncePropOutData,
  setAnnonceProp,
} from "../../store/annonceSlice";
import { useState, useEffect } from "react";
import { deleteDoc, renderDoc, uploadImage } from "../../requests/s3";
import { patchAnnonces } from "../../requests/annonces";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { setMessage } from "../../store/errorSuccessSlice";
import { useParams } from "react-router-dom";
import { selectUiProp } from "../../store/uiSlice";
// import { useRouter } from "next/router";

const ImageInput = ({
  path,
  type = "data",
  editLayout = true,
  editAltText = true,
}: {
  path: (string | number)[];
  type?: "data" | "thumbnail";
  editLayout?: boolean;
  editAltText?: boolean;
}) => {
  const [file, setFile] = useState<any>([]);
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const props: any = useSelector(
    type === "data"
      ? selectAnnonceProp(path.slice(1), {})
      : selectAnnoncePropOutData(["thumbnail"], {})
  );
  const { layout = "contain" } = props;
  const { altText = "" } = props;
  const dispatch = useDispatch();
  const annonce = useSelector(selectUiProp("currentAnnonceId"));

  useEffect(() => {
    setFile([]);
    const getUrl = async (path: string) => {
      return await renderDoc(path);
    };
    if (props !== null || typeof props !== "string") {
      if (props?.hasOwnProperty("imageUrl")) {
        getUrl(props.imageUrl).then((res) =>
          setFile([
            {
              ...props,
              imageUrl: (res as { url: string; data: string }).url,
            },
          ])
        );
      }
    }
  }, [props]);

  const updateFiles = async (incommingFiles: any) => {
    setFile(incommingFiles);
    const file = { ...incommingFiles[0] };
    delete file.file;

    if (type === "data") {
      const url = await uploadImage({
        fileName: incommingFiles[0].file.name,
        file: incommingFiles[0],
        annonceId: annonceId as string,
      });

      dispatch(
        setAnnonceProp({
          id: annonceId,
          path: ["data", ...path.slice(1)],
          value: { ...file, imageUrl: url },
        })
      );
      const dataPath = path.slice(1).join("/");
      patchAnnonces(annonceId as string, [
        {
          op: "replace",
          path: `/data/data/${dataPath}`,
          value: { ...file, imageUrl: url },
        },
      ])
        .then((res) => {
          if (res === "error") {
            dispatch(
              setMessage({ type: "error", message: "Une erreur est survenue" })
            );
          }
        })
        .catch(() =>
          dispatch(
            setMessage({ type: "error", message: "Une erreur est survenue" })
          )
        );
    } else if (type === "thumbnail") {
      const url = await uploadImage({
        fileName: incommingFiles[0].file.name,
        file: incommingFiles[0],
        annonceId: annonce as string,
      });
      dispatch(
        setAnnonceProp({
          id: annonce,
          path: ["thumbnail"],
          value: { ...file, imageUrl: url },
        })
      );
      patchAnnonces(annonce as string, [
        {
          op: "replace",
          path: `/data/thumbnail`,
          value: { ...file, imageUrl: url },
        },
      ])
        .then((res) => {
          if (res === "error") {
            dispatch(
              setMessage({ type: "error", message: "Une erreur est survenue" })
            );
          }
        })
        .catch(() =>
          dispatch(
            setMessage({ type: "error", message: "Une erreur est survenue" })
          )
        );
    }
  };
  const removeFile = (id: string | number | undefined) => {
    const fileToDelete = file.filter((x: any) => x.id === id);
    let filePath = fileToDelete[0].imageUrl.split("amazonaws.com/");
    filePath = filePath[1].split("?");
    if (!filePath.include("annonceTemplate")) {
      deleteDoc(filePath[0]);
    }
    setFile(file.filter((x: any) => x.id !== id));
    if (type === "data") {
      dispatch(
        setAnnonceProp({
          id: annonceId,
          path: ["data", ...path.slice(1)],
          value: "https://picsum.photos/600/300",
        })
      );
      const dataPath = path.slice(1).join("/");
      patchAnnonces(annonceId as string, [
        {
          op: "replace",
          path: `/data/data/${dataPath}`,
          value: "https://picsum.photos/600/300",
        },
      ])
        .then((res) => {
          if (res === "error") {
            dispatch(
              setMessage({ type: "error", message: "Une erreur est survenue" })
            );
          }
        })
        .catch(() =>
          dispatch(
            setMessage({ type: "error", message: "Une erreur est survenue" })
          )
        );
    } else if (type === "thumbnail") {
      dispatch(
        setAnnonceProp({
          id: annonce,
          path: ["tumbnail"],
          value: {},
        })
      );
      patchAnnonces(annonce as string, [
        {
          op: "replace",
          path: `/data/thumbnail`,
          value: {},
        },
      ])
        .then((res) => {
          if (res === "error") {
            dispatch(
              setMessage({ type: "error", message: "Une erreur est survenue" })
            );
          }
        })
        .catch(() =>
          dispatch(
            setMessage({ type: "error", message: "Une erreur est survenue" })
          )
        );
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "layout"],
        value: (event.target as HTMLInputElement).value,
      })
    );
  };
  const handleAltTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "altText"],
        value: (event.target as HTMLInputElement).value,
      })
    );
  };

  return (
    <Stack className="editor">
      <Dropzone
        onChange={updateFiles}
        value={file}
        maxFiles={1}
        accept={type === "data" ? "image/*, video/*" : "image/*"}
        localization="FR-fr"
        header={false}
        footer={false}
        label="Déposez votre fichier ici, ou cliquez pour parcourir"
      >
        {file.map((f: any) => {
          return (
            <FileMosaic key={f.imageUrl} {...f} preview onDelete={removeFile} />
          );
        })}
      </Dropzone>
      {editLayout ? (
        <FormControl sx={{ mt: 2 }}>
          <CustomLabel id="demo-controlled-radio-buttons-group">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Mise en page de l'image
          </CustomLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={layout}
            onChange={handleChange}
          >
            <FormControlLabel
              value="contain"
              control={<Radio sx={CustomRadio} />}
              label="Contenir"
            />
            <FormControlLabel
              value="cover"
              control={<Radio sx={CustomRadio} />}
              label="Recouvrir"
            />
          </RadioGroup>
        </FormControl>
      ) : null}
      {editAltText ? (
        <CustomTextfield
          sx={{ mt: 1 }}
          label="Texte alternatif"
          value={altText}
          helperText="Décrivez l'image en quelques mots"
          onChange={handleAltTextChange}
        />
      ) : null}
    </Stack>
  );
};

export default ImageInput;
