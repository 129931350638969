import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box, FormControl, Stack, Typography } from "@mui/material";
import ColorLensIcon from "@mui/icons-material/ColorLens";

const options = [
  "black",
  "text.primary",
  "text.secondary",
  "background.default",
  "white.main",
  "primary.dark",
  "primary.main",
  "primary.light",
];

export default function ColorSelectInput({ Icon = ColorLensIcon, ...rest }) {
  return (
    <FormControl size="small">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        {...rest}
        renderValue={(option: string) => (
          <Icon
            sx={{
              verticalAlign: "text-bottom",
              color: ["transparent", "background.default"].includes(option)
                ? "text.primary"
                : option,
            }}
          />
        )}
      >
        {rest.target === "background" ? (
          <MenuItem value="transparent">
            <Box
              sx={{ backgroundColor: "transparent", height: 38, width: "100%" }}
            />
          </MenuItem>
        ) : null}
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Box sx={{ backgroundColor: option, height: 38, width: "100%" }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
