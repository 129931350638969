import React, { PropsWithChildren, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { selectConnected, selectUser } from "../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAnnoncesByOwner } from "../requests/annonces";
import { setAllAnnonces } from "../store/annonceSlice";
import { setUiProp } from "../store/uiSlice";
import { getCurrent } from "../util/getCurrent";
import PaymentRequired from "./PaymentRequired";

type Props = {};

const ProtectedRoutes = ({ children }: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const connected = useSelector(selectConnected);
  const [count, setCount] = useState(0);
  const [paid, setPaid] = useState(true);

  useEffect(() => {
    setTimeout(() => setCount(count + 1), 1000);
  }, [connected]);

  useEffect(() => {
    if (count > 0) {
      if (connected === false) {
        navigate("/");
      }
    }
  }, [count]);

  useEffect(() => {
    if (user !== undefined) {
      getAnnoncesByOwner(user._id)
        .then((res: any) => {
          dispatch(setAllAnnonces(res));
          dispatch(setUiProp({ prop: "currentAnnonceId", value: res[0]._id }));
        })
        .catch(() => "error");
      checkPaid();
    }
  }, [user]);

  const checkPaid = async () => {
    const user = await getCurrent(localStorage.getItem("token") as string);

    if (Array.isArray(user.roles)) {
      const roleDirectProprietaire = user.roles.includes(
        process.env.REACT_APP_ROLE
      );
      if (roleDirectProprietaire) {
        setPaid(true);
        return;
      } else {
        if (user.data.paid) {
          setPaid(true);
        } else {
          setPaid(false);
        }
      }
    }
  };

  if (paid === false) {
    return <PaymentRequired />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
