import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { reset, selectMessage, selectType } from "../store/errorSuccessSlice";

type Props = {
  // message: string;
  // type: "error" | "success" | null;
  // setType: Dispatch<SetStateAction<"error" | "success" | null>>;
  // setMessage: Dispatch<SetStateAction<string>>;
};

const ErrorSuccessSnackbar = ({}: // message,
// type,
// setType,
// setMessage,
Props) => {
  const dispatch = useDispatch();
  const message = useSelector(selectMessage);
  const type = useSelector(selectType);

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(reset(null));
  };
  return (
    <>
      {type !== null ? (
        <>
          <Snackbar
            open={type === "success" ? true : false}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="success" elevation={6} variant="filled">
              {message}
            </Alert>
          </Snackbar>
          <Snackbar
            open={type === "error" ? true : false}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="error" elevation={6} variant="filled">
              {message}
            </Alert>
          </Snackbar>
        </>
      ) : null}
    </>
  );
};

export default ErrorSuccessSnackbar;
