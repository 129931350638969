import React from "react";
import { Box, Button, Typography, Container } from "@mui/material";
import image from "../images/direct-proprietaire-terrasse-annonce-rooftop-immobilier.jpg";
// import Image from "next/image";

type Props = {};

const HeroSection = (props: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: "#0E67DD",
      }}
    >
      {/* <Container maxWidth="lg" sx={{ pt: 8 }}> */}
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flex={1}
          pr={{ xs: 2, md: 6, xl: 25 }}
          pt={8}
          pl={4}
          pb={4}
        >
          <Typography
            variant="h1"
            sx={{ fontWeight: "600", color: "white", my: 2 }}
          >
            Direct Propriétaire, Vendez votre bien sans aucun frais de
            transaction
          </Typography>

          <Typography
            fontSize={24}
            fontWeight={"400"}
            sx={{
              color: "white",
            }}
          >
            Créez vous-même votre site internet temporaire pour vendre/louer
            votre bien immobilier
          </Typography>

          <Box color="dark" display="inline">
            <Button
              variant="contained"
              color="inherit"
              sx={{
                color: "white",
                backgroundColor: "dark.main",
                mt: 2,
                mr: 2,
                fontSize: "1.2rem",
                "&:hover": { color: "white", backgroundColor: "dark.main" },
              }}
              href="/creer-compte"
            >
              Créer votre site
            </Button>
          </Box>
          {/* <Box color="white" display="inline">
              <Button
                variant="contained"
                color="inherit"
                sx={{
                  color: "primary.main",
                  backgroundColor: "white",
                  mt: 2,
                  fontSize: "1.2rem",
                  "&:hover": { color: "primary.main" },
                }}
                // href="#contactez-nous"
              >
                Rechercher un bien
              </Button>
            </Box> */}
        </Box>
        <Box flex={1}>
          <img
            src={image}
            style={{ width: "100%", height: "100%" }}
            alt={"terrasse rooftop vue de haut"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
