import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  CardMedia,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { renderDoc } from "../../requests/s3";
import { setLoading } from "../../store/uiSlice";
import { useDispatch } from "react-redux";
import sanitizeHtml from "sanitize-html";

type Props = {
  annonce: any;
  id?: string;
};

const owners = ["65118bef4a8a916f7a2e1970", "650d7fa54a8a916f7a2e1096"];

const AnnonceCard = ({ annonce, id }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  let content = sanitizeHtml(annonce.data.data.informations.description, {
    allowedTags: [],
  });
  // let content = annonce.data.data.informations.description.replace("\n", "");
  // content = content.replaceAll("<p>", "");
  // content = content.replaceAll('<p style="text-align: justify;">', "");
  // content = content.replaceAll("</p>", " ");
  // content = content.replaceAll("<ul>", "");
  // content = content.replaceAll("</ul>", " ");
  // content = content.replaceAll("<li>", "");
  // content = content.replaceAll("</li>", " ");
  // content = content.replaceAll("<div>", "");
  // content = content.replaceAll("</div>", " ");
  // content = content.replaceAll("<span>", "");
  // content = content.replaceAll("</span>", " ");
  // content = content.replaceAll("<ol>", "");
  // content = content.replaceAll("</ol>", " ");
  // content = content.replaceAll("<br />", " ");

  useEffect(() => {
    dispatch(setLoading({ isLoading: true }));
    if (annonce !== undefined) {
      renderDoc(annonce.data.thumbnail.imageUrl).then((res) => {
        if (res !== "error") {
          setImage(res.url);
          dispatch(setLoading({ isLoading: false }));
        }
      });
    }
  }, [annonce]);

  return annonce ? (
    <Grid item xs={1}>
      <Card
        sx={{
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
          borderRadius: "20px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => navigate(`/annonce/${annonce._id}`)}
      >
        <CardMedia
          component="img"
          sx={{ height: 350, objectFit: "cover" }}
          image={image}
        />
        {owners.includes(annonce.owner) ? (
          <Chip
            label={"Annonce test"}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              backgroundColor: "white",
            }}
          />
        ) : null}
        <CardContent
          sx={{
            // flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Box display="flex" justifyContent={"space-between"} pb={2}>
            <Typography sx={{ color: "#FFA400" }}>
              {annonce.data.data.informations.localisation.data}
            </Typography>
            <Typography sx={{ color: "#FFA400" }}>
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
                maximumFractionDigits: 0,
              }).format(annonce.data.data.informations.prix.data)}
            </Typography>
          </Box>
          <Typography pb={2}>{annonce.data.titre}</Typography>
          <Box pb={2}>
            {annonce.data.data.informations.nombrePieces.data} pièces /{" "}
            {annonce.data.data.informations.nombreChambres.data} chambres /{" "}
            {annonce.data.data.informations.surface.data}m²
          </Box>
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "4",
              WebkitBoxOrient: "vertical",
            }}
          >
            {content}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ) : null;
};

export default AnnonceCard;
