import * as React from "react";
import LaptopIcon from "@mui/icons-material/Laptop";
import TabletIcon from "@mui/icons-material/Tablet";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectUiProp, setUiProp } from "../../store/uiSlice";

export default function DeviceSelect() {
  const device = useSelector(selectUiProp("currentDevice"));
  const dispatch = useDispatch();
  const handleDevices = (
    _event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    if (value) {
      dispatch(setUiProp({ prop: "currentDevice", value }));
    }
  };

  return (
    <ToggleButtonGroup
      value={device}
      onChange={handleDevices}
      exclusive
      aria-label="device"
    >
      <ToggleButton value="phone" aria-label="phone">
        <PhoneAndroidIcon />
      </ToggleButton>
      <ToggleButton value="tablet" aria-label="tablet">
        <TabletIcon />
      </ToggleButton>
      <ToggleButton value="laptop" aria-label="laptop">
        <LaptopIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
