import React, {
  useState,
  useEffect,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Container,
  Backdrop,
  Paper,
  Stack,
  Box,
  Button,
  Typography,
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import interactionPlugin from "@fullcalendar/interaction";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import fr from "date-fns/locale/fr";
import {
  add,
  areIntervalsOverlapping,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
} from "date-fns";
import ClearIcon from "@mui/icons-material/Clear";
import { getDisponibilites, putDisponibilites } from "../../requests/dispos";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../store/errorSuccessSlice";
import {
  createDispos,
  createEvents,
  createRdvs,
} from "../../util/createEvents";
import { getRdv, getRdvsByOwner } from "../../requests/rdv";
import { selectUser } from "../../store/authSlice";
import { ownerDeleteRdv } from "../../requests/amplify";
import { selectUiProp } from "../../store/uiSlice";
import { selectAnnonceById } from "../../store/annonceSlice";

type Props = {
  visiteVisio: string;
  disponibilites: any;
  setDisponibilites: Dispatch<SetStateAction<any>>;
};

const Calendrier = ({
  visiteVisio,
  disponibilites,
  setDisponibilites,
}: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const annonceId = useSelector(selectUiProp("currentAnnonceId"));
  const annonce = useSelector((state) => selectAnnonceById(state, annonceId));
  const [selectedDate, setSelectedDate] = useState("");
  const [creneaux, setCreneaux] = useState<any[]>([]);
  const [rdvs, setRdvs] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [creneau, setCreneau] = useState({
    date: "",
    heureDebut: "",
    heureFin: "",
  });
  const [deleteRdv, setDeleteRdv] = useState<{ open: boolean; data: any }>({
    open: false,
    data: {},
  });
  const [editCreneau, setEditCreneau] = useState<{
    open: boolean;
    data: any;
    overlap: number;
    error?: string;
  }>({
    open: false,
    data: {},
    overlap: 0,
  });

  useEffect(() => {
    getRdvsByOwner(user._id).then((res) => {
      if (res !== "error") {
        setRdvs(createRdvs(res, "vendeur"));
      } else {
        dispatch(
          setMessage({
            message: "Une erreur est survenue",
            type: "error",
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    if (disponibilites.data !== undefined) {
      setCreneaux(createDispos(disponibilites.data.creneaux));
    }
  }, [disponibilites]);

  useEffect(() => {
    setEvents(
      createEvents(
        creneaux,
        rdvs,
        {
          hours: parseInt(visiteVisio?.split(":")[0]),
          minutes: parseInt(visiteVisio?.split(":")[1]),
        },
        "vendeur"
      )
    );
  }, [rdvs, creneaux]);

  const saveCreneau = () => {
    const date = creneau.date === "" ? selectedDate : creneau.date;
    const heureDebut =
      creneau.heureDebut === ""
        ? () => {
            const hours = getHours(new Date(selectedDate));
            const minutes = getMinutes(new Date(selectedDate));
            return `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}`;
          }
        : () => {
            const hours = getHours(new Date(creneau.heureDebut));
            const minutes = getMinutes(new Date(creneau.heureDebut));
            return `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}`;
          };
    const heureFin =
      creneau.heureFin === ""
        ? () => {
            const date = add(new Date(selectedDate), {
              hours: parseInt(visiteVisio.split(":")[0]),
              minutes: parseInt(visiteVisio.split(":")[1]),
            });
            const hours = getHours(new Date(date));
            const minutes = getMinutes(new Date(date));
            return `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}`;
          }
        : () => {
            const hours = getHours(new Date(creneau.heureFin));
            const minutes = getMinutes(new Date(creneau.heureFin));
            return `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}`;
          };
    const toSave = {
      date: date,
      heureDebut: heureDebut(),
      heureFin: heureFin(),
    };

    const dispos = JSON.parse(JSON.stringify(disponibilites));
    dispos.data.creneaux.push(toSave);
    putDisponibilites(dispos).then((res) => {
      if (res !== "error") {
        getDisponibilites().then((res) => {
          if (res !== "error") {
            setSelectedDate("");
            setCreneau({
              date: "",
              heureDebut: "",
              heureFin: "",
            });
            setCreneaux(createDispos(res.data.creneaux));
            setDisponibilites(res);
            dispatch(
              setMessage({
                message: "Modification enregistrée !",
                type: "success",
              })
            );
          } else {
            dispatch(
              setMessage({
                message: "Une erreur est survenue",
                type: "error",
              })
            );
          }
        });
      } else {
        dispatch(
          setMessage({
            message: "Une erreur est survenue veuillez réessayer",
            type: "error",
          })
        );
      }
    });
  };

  const clickOnEvent = (info: any) => {
    console.log(info.event);
    if (info.event.title === "Disponible") {
      const currentCreneau = creneaux.find(
        (c) => c.creneauIndex === info.event.extendedProps.creneauIndex
      );
      const overlap = rdvs.map((rdv: any) =>
        areIntervalsOverlapping(
          {
            start: new Date(currentCreneau.start),
            end: new Date(currentCreneau.end),
          },
          { start: new Date(rdv.start), end: new Date(rdv.end) }
        )
      );
      const count = overlap.filter(Boolean).length;
      setEditCreneau({ open: true, data: currentCreneau, overlap: count });
    } else {
      getRdv(info.event.extendedProps.rdvId).then((res) => {
        if (res !== "error") {
          setDeleteRdv({ open: true, data: res });
        } else {
          dispatch(
            setMessage({
              message: "Une erreur est survenue veuillez réessayer",
              type: "error",
            })
          );
        }
      });
    }
  };

  const changeCreneau = () => {
    // vérifier si nombre overlap après changement est le même qu’avant
    const overlap = rdvs.map((rdv: any) =>
      areIntervalsOverlapping(
        {
          start: new Date(editCreneau.data.start),
          end: new Date(editCreneau.data.end),
        },
        { start: new Date(rdv.start), end: new Date(rdv.end) }
      )
    );
    const count = overlap.filter(Boolean).length;

    const overlappingRdv = rdvs.filter((rdv: any) => {
      if (
        areIntervalsOverlapping(
          {
            start: new Date(editCreneau.data.start),
            end: new Date(editCreneau.data.end),
          },
          { start: new Date(rdv.start), end: new Date(rdv.end) }
        )
      ) {
        return rdv;
      }
    });

    const conflictingRdv = overlappingRdv.map((rdv) => {
      if (isBefore(new Date(rdv.start), new Date(editCreneau.data.start))) {
        return true;
      } else if (isAfter(new Date(rdv.end), new Date(editCreneau.data.end))) {
        return true;
      } else {
        return false;
      }
    });

    const rdvConflict = conflictingRdv.some(Boolean);

    // si nombre différent “Veuillez annuler les rdvs qui ne se trouve pas sur la nouvelle plage de disponibilité“
    if (count !== editCreneau.overlap || rdvConflict) {
      setEditCreneau((state) => ({
        ...state,
        error:
          "Veuillez annuler les rdvs qui ne se trouve pas sur la nouvelle plage de disponibilité",
      }));
    } else if (count === editCreneau.overlap && !rdvConflict) {
      // si aucun conflit
      const heureDebut = () => {
        const hours = getHours(new Date(editCreneau.data.start));
        const minutes = getMinutes(new Date(editCreneau.data.start));
        return `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
      };
      const heureFin = () => {
        const hours = getHours(new Date(editCreneau.data.end));
        const minutes = getMinutes(new Date(editCreneau.data.end));
        return `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
      };
      const toSave = {
        date: new Date(editCreneau.data.start).toISOString(),
        heureDebut: heureDebut(),
        heureFin: heureFin(),
      };

      const dispos = JSON.parse(JSON.stringify(disponibilites));
      dispos.data.creneaux[editCreneau.data.creneauIndex] = toSave;
      putDisponibilites(dispos).then((res) => {
        if (res !== "error") {
          getDisponibilites().then((res) => {
            if (res !== "error") {
              setEditCreneau({ open: false, data: {}, overlap: 0 });
              setCreneaux(createDispos(res.data.creneaux));
              dispatch(
                setMessage({
                  message: "Modification enregistrée !",
                  type: "success",
                })
              );
            } else {
              dispatch(
                setMessage({
                  message: "Une erreur est survenue",
                  type: "error",
                })
              );
            }
          });
        } else {
          dispatch(
            setMessage({
              message: "Une erreur est survenue veuillez réessayer",
              type: "error",
            })
          );
        }
      });
    }
  };

  const delRdv = () => {
    ownerDeleteRdv({
      token: localStorage.getItem("token") as string,
      rdvId: deleteRdv.data._id,
      vendeurEmail: user.data.email,
      annonce: annonceId,
      titre: annonce?.data.titre,
      ownerId: user._id,
    }).then((res) => {
      if (res !== "error") {
        // envoi mail
        dispatch(
          setMessage({
            message: "La visite a été annulée",
            type: "success",
          })
        );
        getRdvsByOwner(user._id).then((res) => {
          if (res !== "error") {
            setRdvs(createRdvs(res, "vendeur"));
          } else {
            dispatch(
              setMessage({
                message: "Une erreur est survenue",
                type: "error",
              })
            );
          }
        });
        setDeleteRdv({ open: false, data: {} });
      } else {
        dispatch(
          setMessage({
            message: "Une erreur est survenue veuillez réessayer",
            type: "error",
          })
        );
      }
    });
  };

  const deleteCreneau = () => {
    const index = editCreneau.data.creneauIndex;
    const newDisponibilites = { ...disponibilites };

    newDisponibilites.data.creneaux.splice(index, 1);

    putDisponibilites(newDisponibilites).then((res) => {
      if (res !== "error") {
        getDisponibilites().then((res) => {
          if (res !== "error") {
            setEditCreneau({ open: false, data: {}, overlap: 0 });
            setCreneaux(createDispos(res.data.creneaux));
            dispatch(
              setMessage({
                message: "Modification enregistrée !",
                type: "success",
              })
            );
          } else {
            dispatch(
              setMessage({
                message: "Une erreur est survenue",
                type: "error",
              })
            );
          }
        });
      } else {
        dispatch(
          setMessage({
            message: "Une erreur est survenue veuillez réessayer",
            type: "error",
          })
        );
      }
    });
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ py: 4, px: 0 }}>
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          locale={frLocale}
          slotMinTime={"08:00:00"}
          slotMaxTime={"21:00:00"}
          slotDuration={"00:15:00"}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: "short",
          }}
          allDaySlot={false}
          events={events}
          height={"auto"}
          timeZone="local"
          eventClick={clickOnEvent}
          validRange={{
            start: new Date(),
          }}
          selectable={true}
          dateClick={(infos: any) => setSelectedDate(infos.date)}
        />
      </Container>
      <Backdrop
        open={!!selectedDate}
        sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
      >
        <Paper sx={{ p: 4 }}>
          <Box display="flex" justifyContent="flex-end">
            <ClearIcon
              onClick={() => {
                setSelectedDate("");
                setCreneau({
                  date: "",
                  heureDebut: "",
                  heureFin: "",
                });
              }}
              sx={{ "&:hover": { cursor: "pointer" } }}
            />
          </Box>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <Stack spacing={3}>
              <DatePicker
                disablePast
                value={creneau.date === "" ? selectedDate : creneau.date}
                onChange={(value) =>
                  setCreneau((state) => ({ ...state, date: value as string }))
                }
              />
              <TimePicker
                value={
                  creneau.heureDebut === "" ? selectedDate : creneau.heureDebut
                }
                onChange={(value) =>
                  setCreneau((state) => ({
                    ...state,
                    heureDebut: value as string,
                  }))
                }
                label="Début"
              />
              <TimePicker
                label="Fin"
                value={
                  creneau.heureFin === ""
                    ? add(new Date(selectedDate), {
                        hours: parseInt(visiteVisio.split(":")[0]),
                        minutes: parseInt(visiteVisio.split(":")[1]),
                      })
                    : creneau.heureFin
                }
                onChange={(value) =>
                  setCreneau((state) => ({
                    ...state,
                    heureFin: value as string,
                  }))
                }
              />
              <Button onClick={saveCreneau}>Enregistrer</Button>
            </Stack>
          </LocalizationProvider>
        </Paper>
      </Backdrop>
      <Backdrop
        open={deleteRdv.open}
        sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
      >
        <Paper sx={{ p: 4 }}>
          <Box display="flex" justifyContent="flex-end">
            <ClearIcon
              onClick={() => {
                setDeleteRdv({ open: false, data: {} });
              }}
              sx={{ "&:hover": { cursor: "pointer" } }}
            />
          </Box>
          <Box>
            <Typography
              variant={"h3"}
              component={"p"}
              sx={{ textAlign: "center", pb: 3 }}
            >
              Annuler la visite
            </Typography>
            {Object.keys(deleteRdv.data).length > 0 ? (
              <>
                <Typography>
                  Le{" "}
                  {new Intl.DateTimeFormat("fr-FR", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  }).format(new Date(deleteRdv.data?.data?.debut))}{" "}
                  de{" "}
                  {new Date(deleteRdv.data?.data?.debut).toLocaleString(
                    "fr-FR",
                    {
                      timeZone: "Europe/Paris",
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}{" "}
                  à{" "}
                  {new Date(deleteRdv.data?.data?.fin).toLocaleString("fr-FR", {
                    timeZone: "Europe/Paris",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
                <Box sx={{ textAlign: "center", pt: 2 }}>
                  <Button variant="contained" onClick={delRdv}>
                    Annuler
                  </Button>
                </Box>
              </>
            ) : null}
          </Box>
        </Paper>
      </Backdrop>
      <Backdrop
        open={editCreneau.open}
        sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
      >
        <Paper sx={{ p: 4 }}>
          <Box display="flex" justifyContent="flex-end">
            <ClearIcon
              onClick={() => {
                setEditCreneau({ open: false, data: {}, overlap: 0 });
              }}
              sx={{ "&:hover": { cursor: "pointer" } }}
            />
          </Box>
          {Object.keys(editCreneau.data).length > 0 ? (
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={fr}
            >
              <Stack spacing={3}>
                <TimePicker
                  label="Début"
                  value={new Date(editCreneau.data.start)}
                  onChange={(newValue) =>
                    setEditCreneau((state) => ({
                      ...state,
                      data: { ...state.data, start: newValue },
                    }))
                  }
                />
                <TimePicker
                  label="Fin"
                  value={new Date(editCreneau.data.end)}
                  onChange={(newValue) =>
                    setEditCreneau((state) => ({
                      ...state,
                      data: { ...state.data, end: newValue },
                    }))
                  }
                />
                <Button onClick={changeCreneau}>Enregistrer</Button>
                {editCreneau.overlap === 0 ? (
                  <Button onClick={deleteCreneau}>
                    Supprimer la disponibilité
                  </Button>
                ) : null}
              </Stack>
            </LocalizationProvider>
          ) : null}
          {editCreneau.error !== undefined ? (
            <Typography>{editCreneau.error}</Typography>
          ) : null}
        </Paper>
      </Backdrop>
    </>
  );
};

export default Calendrier;
