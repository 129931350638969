export const getCurrent = async (token: string) => {
  try {
    const response = await fetch(
      "https://formio.mobilecoveragemap.com/current",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-jwt-token": token,
        },
      }
    );
    if (response.status === 200) {
      const json = await response.json();
      return json;
    } else {
      return "error";
    }
  } catch (err) {
    return "error";
  }
};
