import {
  alpha,
  FormLabel,
  Slider,
  styled,
  Switch,
  TextField,
} from "@mui/material";

export const colorPrimary = "#0E67DD";
export const colorDark = "#0e5cc3";
export const colorLight = "#0e67ddab";

export const CustomTextfield = styled(TextField)({
  "& label.Mui-focused": {
    color: colorDark,
  },
  "& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: colorDark,
  },
});
export const CustomLabel = styled(FormLabel)({
  "&.Mui-focused": {
    color: colorDark,
  },
});

export const CustomRadio = {
  color: colorDark,
  "&.Mui-checked": {
    color: colorDark,
  },
};
export const CustomSlider = styled(Slider)({
  "& .MuiSlider-thumb": {
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${alpha(colorDark, 0.22)}`,
    },
  },
});
export const CustomSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: colorDark,
    "&:hover": {
      backgroundColor: alpha(colorDark, 0.1),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: colorDark,
    opacity: 0.3,
  },
});
