import { FormControl, Menu, MenuItem, Button } from "@mui/material";
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { selectAllAnnonces } from "../../store/annonceSlice";
import Slider from "@mui/material/Slider";

type Props = {
  surface: number[];
  setSurface: Dispatch<SetStateAction<number[]>>;
};

const Surface = ({ surface, setSurface }: Props) => {
  const annonces = useSelector(selectAllAnnonces);
  const [maxSurface, setMaxSurface] = useState(100);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    let max = 0;
    annonces.forEach((annonce) => {
      if (annonce.data.data.informations.surface.data > max) {
        max = annonce.data.data.informations.surface.data;
      }
    });
    setMaxSurface(max);
  }, [annonces]);

  useEffect(() => {
    console.log(maxSurface);
    setSurface((state) => [state[0], maxSurface]);
  }, [maxSurface]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setSurface(newValue as number[]);
  };

  return (
    <FormControl sx={{ width: 250 }}>
      <Button
        onClick={handleClick}
        disableRipple={true}
        sx={{
          height: "64.983px",
          backgroundColor: "white",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: !!!anchorEl ? "#0000003b" : "primary",
          borderRadius: "4px",
          "&:hover": {
            borderColor: "#000",
            backgroundColor: "white",
          },
          justifyContent: "flex-start",
          padding: "16.5px 32px 16.5px 14px",
          color: "#00000099",
          fontWeight: "700",
          fontSize: "1rem",
          // "@media minWidth(953px)": {
          //   fontSize: "1rem",
          // },
          "@media (max-width :952px)": {
            fontSize: "0.9rem",
          },
          "@media (max-width :910px)": {
            fontSize: "0.85rem",
          },
        }}
      >
        Surface : {Intl.NumberFormat("fr-FR").format(surface[0])}m² -{" "}
        {Intl.NumberFormat("fr-FR").format(surface[1])}m²
      </Button>

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          disableRipple={true}
          disableTouchRipple={true}
          sx={{
            minWidth: "300px",
            "&:hover": {
              backgroundColor: "transparent",
            },
            cursor: "default",
          }}
        >
          <Slider
            value={surface}
            onChange={handleChange}
            valueLabelDisplay="on"
            max={maxSurface}
            min={0}
            step={1}
            sx={{ width: "85%", mt: 5, ml: 2 }}
          />
        </MenuItem>
      </Menu>
    </FormControl>
  );
};

export default Surface;
