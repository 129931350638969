import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAnnonceProp, setAnnonceProp } from "../../store/annonceSlice";
import { Editor } from "@tinymce/tinymce-react";
import sanitizeHtml from "sanitize-html";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
// import { useRouter } from "next/router";

type Props = {
  path: string[];
};

const RichTextEditor = ({ path }: Props) => {
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const dispatch = useDispatch();
  const props: any = useSelector(selectAnnonceProp(path.slice(1), {}));
  const theme = useTheme();
  const primaryMain = theme.palette.primary.main;
  const primaryLight = theme.palette.primary.light;
  const primaryDark = theme.palette.primary.dark;
  const textPrimary = theme.palette.text.primary;
  const textSecondary = theme.palette.text.secondary;

  const colors = [
    "000000",
    "",
    textPrimary.replace("#", ""),
    "",
    textSecondary.replace("#", ""),
    "",
    "ffffff",
    "",
    primaryDark.replace("#", ""),
    "",
    primaryMain.replace("#", ""),
    "",
    primaryLight.replace("#", ""),
    "",
  ];
  const handleChange = (event: any) => {
    console.log(event);
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1)],
        value: sanitizeHtml(event, {
          allowedAttributes: {
            span: ["style"],
            div: ["style"],
            p: ["style"],
            li: ["style"],
            h1: ["style"],
            h2: ["style"],
            h3: ["style"],
            h4: ["style"],
            h5: ["style"],
            h6: ["style"],
          },
          parseStyleAttributes: false,
        }),
      })
    );
  };
  return (
    <Editor
      tinymceScriptSrc="/assets/libs/tinymce/tinymce.min.js"
      value={props}
      onEditorChange={handleChange}
      init={{
        statusbar: false,
        menubar: false,
        plugins: "lists",
        toolbar: [
          "bold italic underline aligncenter alignright alignjustify bullist numlist forecolor h1 h2 h3 h4 h5 h6",
        ],
        forced_root_block_attrs: { style: "text-align: justify" },
        language: "fr_FR",
        language_url: "/assets/libs/tinymce/fr_FR.js",
        color_map_foreground: colors,
        color_cols_foreground: 4,
        custom_colors: false,
        forced_root_block: "false",
        formats: {
          alignleft: {
            selector: "span,em,i,b,strong,div",
            block: "div",
            styles: { display: "block", "text-align": "left" },
          },
          aligncenter: {
            selector: "span,em,i,b,strong,div",
            block: "div",
            styles: { display: "block", "text-align": "center" },
          },
          alignright: {
            selector: "span,em,i,b,strong,div",
            block: "div",
            styles: { display: "block", "text-align": "right" },
          },
          alignjustify: {
            selector: "span,em,i,b,strong,div",
            block: "div",
            styles: { display: "block", "text-align": "justify" },
          },
        },
      }}
    />
  );
};

export default RichTextEditor;
