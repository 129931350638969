import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export type Article = {
  _id: string;
  data: {
    slug: string;
    [key: string]: any;
  };
  [key: string]: any;
};

const articleAdapter = createEntityAdapter<Article>({
  selectId: (article) => article.data.slug,
  sortComparer: (a, b) => b.created.localeCompare(a.created),
});

export const articleSlice = createSlice({
  name: "articles",
  initialState: articleAdapter.getInitialState(),
  reducers: {
    setAllArticles: articleAdapter.setAll,
  },
});

export const { setAllArticles } = articleSlice.actions;

export const { selectById: selectArticleById, selectAll: selectAllArticles } =
  articleAdapter.getSelectors((state: any) => state.articles);

export default articleSlice.reducer;
