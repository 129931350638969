import React, { useEffect, useState } from "react";
import { getDisponibilites, putDisponibilites } from "../../requests/dispos";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../store/errorSuccessSlice";
import { selectToken } from "../../store/authSlice";
import { Box, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import fr from "date-fns/locale/fr";
import { getHours, getMinutes, set as dateSet } from "date-fns";
import Calendrier from "./Calendrier";

type Props = {};

const Disponibilites = (props: Props) => {
  const dispatch = useDispatch();
  const [disponibilites, setDisponibilites] = useState<any>({});
  const [duree, setDuree] = useState<{ [key: string]: string }>({
    virtuelle: "",
    place: "",
  });
  const token = useSelector(selectToken);

  useEffect(() => {
    if (token !== undefined) {
      getDisponibilites().then((res) => {
        if (res !== "error") {
          setDisponibilites(res);
          setDuree({
            virtuelle: res.data.dureeVisiteVirtuelle,
            place: res.data.dureeVisitePlace,
          });
        } else {
          dispatch(
            setMessage({
              message: "Une erreur est survenue",
              type: "error",
            })
          );
        }
      });
    }
  }, [token]);

  const onTimeChange = (value: Date, type: string) => {
    const hours = getHours(value);
    const minutes = getMinutes(value);
    setDuree((state) => ({
      ...state,
      [type]: `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`,
    }));
  };

  const save = () => {
    const dispo = { ...disponibilites };
    dispo.data.dureeVisiteVirtuelle = duree.virtuelle;
    dispo.data.dureeVisitePlace = duree.place;
    putDisponibilites(dispo).then((res) => {
      if (res !== "error") {
        dispatch(
          setMessage({
            message: "Modification enregistrée !",
            type: "success",
          })
        );
        getDisponibilites().then((res) => {
          if (res !== "error") {
            setDisponibilites(res);
            setDuree({
              virtuelle: res.data.dureeVisiteVirtuelle,
              place: res.data.dureeVisitePlace,
            });
          } else {
            dispatch(
              setMessage({
                message: "Une erreur est survenue",
                type: "error",
              })
            );
          }
        });
      } else {
        dispatch(
          setMessage({
            message: "Une erreur est survenue veuillez réessayer",
            type: "error",
          })
        );
      }
    });
  };

  return Object.keys(disponibilites).length > 0 ? (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
        <Box display="flex" justifyContent={"space-between"}>
          <TimePicker
            ampm={false}
            value={dateSet(new Date(), {
              hours: parseInt((duree.virtuelle as string).split(":")[0]),
              minutes: parseInt((duree.virtuelle as string).split(":")[1]),
            })}
            onChange={(newValue) => onTimeChange(newValue as Date, "virtuelle")}
            label={"Durée d'une visite en visio"}
          />
          <TimePicker
            ampm={false}
            value={dateSet(new Date(), {
              hours: parseInt((duree.place as string).split(":")[0]),
              minutes: parseInt((duree.place as string).split(":")[1]),
            })}
            onChange={(newValue) => onTimeChange(newValue as Date, "place")}
            label={"Durée d'une visite sur place"}
          />

          <Button variant="contained" onClick={save}>
            Enregistrer
          </Button>
        </Box>
      </LocalizationProvider>
      <Calendrier
        visiteVisio={duree.virtuelle}
        disponibilites={disponibilites}
        setDisponibilites={setDisponibilites}
      />
    </>
  ) : null;
};

export default Disponibilites;
