import {
  Button,
  Container,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// import { useRouter } from "next/router";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signup } from "../requests/signup";
import { newAnnonce } from "../requests/annonces";
import { newDispos } from "../requests/dispos";

type Props = {};

const SignUp = (props: Props) => {
  const dispatch = useDispatch();
  // const router = useRouter();
  const [user, setUser] = useState({ email: "", password: "", nomcomplet: "" });
  const [error, setError] = useState("");
  const verifyPass = useRef<any>();
  const navigate = useNavigate();
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const passwordformat =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&\/#]{8,}$/;

  const handleLogin = async () => {
    setError("");
    if (!user.email.match(mailformat)) {
      setError("Veuillez renseigner une adresse email valide");
      return;
    } else if (user.password !== verifyPass.current.value) {
      setError("Les deux mots de passe ne sont pas identiques");
    } else if (!user.password.match(passwordformat)) {
      setError(
        "Votre mot de passe doit être composé d'au moins 8 caractères : une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial (@$!%*?&/#)"
      );
    } else if (user.nomcomplet.length < 3) {
      setError("Veuillez renseigner un nom valide");
    } else {
      signup(user).then((res) => {
        if (res === "ok") {
          newAnnonce(localStorage.getItem("token") as string);
          newDispos(localStorage.getItem("token") as string);
          navigate("/tableau-de-bord");
        } else {
          setError(
            "Création de compte impossible, veuillez réessayer. Si le problème persiste, contactez-nous."
          );
        }
      });
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ mt: 4, textAlign: "center" }}
      className={"login"}
    >
      {error ? <Typography>{error}</Typography> : null}

      <Stack spacing={3} sx={{ mt: 4, mb: 5 }}>
        <FormControl>
          <TextField
            value={user.nomcomplet}
            label="Nom complet"
            onChange={(e) => {
              setUser((state) => ({ ...state, nomcomplet: e.target.value }));
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            value={user.email}
            label="Adresse email"
            type="email"
            autoComplete="email"
            onChange={(e) => {
              setUser((state) => ({ ...state, email: e.target.value }));
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            value={user.password}
            label="Mot de passe"
            type="password"
            autoComplete="current-password"
            onChange={(e) => {
              setUser((state) => ({ ...state, password: e.target.value }));
            }}
          />
        </FormControl>
        <FormControl>
          <TextField
            inputRef={verifyPass}
            label="Vérification mot de passe"
            type="password"
          />
        </FormControl>
        <Button variant="contained" onClick={handleLogin}>
          Créer un compte
        </Button>
      </Stack>
    </Container>
  );
};

export default SignUp;
