import { IconButton, Stack, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CustomTextfield } from "./CustomStyles";
import { selectAnnonceProp, setAnnonceProp } from "../../store/annonceSlice";
import { useParams } from "react-router-dom";
// import { useRouter } from "next/router";

const TextInput = ({
  path,
  label,
  defaults = {},
  inputProps = {},
  type,
}: {
  path: string[];
  label: string;
  defaults?: {};
  inputProps?: {};
  type?: "number";
}) => {
  const dispatch = useDispatch();
  // const router = useRouter();
  // const annonceId = router.query.annonceId;
  const { annonceId } = useParams();
  const props: any = useSelector(selectAnnonceProp(path.slice(1), {}));
  const propsWithDefaults = { ...defaults, ...props };
  const { data, color } = propsWithDefaults;
  const theme = useTheme();
  console.log({ theme });
  const getPaletteColor = (color: string) => {
    switch (color) {
      case "primary.main":
        return theme.palette.primary.main;
      case "primary.light":
        return theme.palette.primary.light;
      case "primary.dark":
        return theme.palette.primary.dark;
      case "text.primary":
        return theme.palette.text.primary;
      case "text.secondary":
        return theme.palette.text.secondary;
      case "black":
      case "white":
        return theme.palette.common[color];
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "number") {
      dispatch(
        setAnnonceProp({
          id: annonceId,
          path: ["data", ...path.slice(1), "data"],
          value: isNaN(parseInt(event.target.value))
            ? ""
            : parseInt(event.target.value),
        })
      );
    } else {
      dispatch(
        setAnnonceProp({
          id: annonceId,
          path: ["data", ...path.slice(1), "data"],
          value: event.target.value,
        })
      );
    }
  };

  const handleColorChange = (c: string) => () =>
    dispatch(
      setAnnonceProp({
        id: annonceId,
        path: ["data", ...path.slice(1), "color"],
        value: c,
      })
    );

  return (
    <Stack>
      <CustomTextfield
        value={data}
        label={label}
        {...inputProps}
        onChange={handleChange}
      />
      <Stack direction="row" flexWrap="wrap" mt={1}>
        {[
          "black",
          "text.primary",
          "text.secondary",
          "white",
          "primary.dark",
          "primary.main",
          "primary.light",
        ].map((c) => (
          <div
            key={c}
            style={{
              width: 30,
              height: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: color === c ? "2px solid" : "none",
              borderRadius: 15,
              margin: 2,
            }}
          >
            <IconButton color="inherit" onClick={handleColorChange(c)}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  backgroundColor: getPaletteColor(c),
                  border: "1px solid black",
                }}
              />
            </IconButton>
          </div>
        ))}
      </Stack>
    </Stack>
  );
};

export default TextInput;
