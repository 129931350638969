import { ActionCreators as UndoActionCreators } from "redux-undo";
import { connect } from "react-redux";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { IconButton, Typography } from "@mui/material";

let UndoRedo = ({ canUndo, canRedo, onUndo, onRedo }: any) => (
  <>
    <IconButton
      size="large"
      edge="start"
      // color="inherit"
      aria-label="menu"
      sx={{
        mr: 2,
        color: "black",
        textTransform: "uppercase",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={onUndo}
      disabled={!canUndo}
    >
      <UndoIcon />
      <Typography sx={{ fontSize: "12px" }}>Annuler</Typography>
    </IconButton>
    <IconButton
      size="large"
      edge="start"
      // color="inherit"
      aria-label="menu"
      sx={{
        mr: 2,
        color: "black",
        textTransform: "uppercase",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={onRedo}
      disabled={!canRedo}
    >
      <RedoIcon />
      <Typography sx={{ fontSize: "12px" }}>Refaire</Typography>
    </IconButton>
  </>
);

const mapStateToProps = (state: any) => {
  return {
    canUndo: state.undoable.past.length > 0,
    canRedo: state.undoable.future.length > 0,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onUndo: () => dispatch(UndoActionCreators.undo()),
    onRedo: () => dispatch(UndoActionCreators.redo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UndoRedo);
