import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { postRetour } from "../../requests/amplify";
import { useDispatch } from "react-redux";
import { setMessage } from "../../store/errorSuccessSlice";
import { useParams, useSearchParams } from "react-router-dom";
import { setLoading } from "../../store/uiSlice";
// import { useRouter } from "next/router";

type Props = {};

const Retour = (props: Props) => {
  const dispatch = useDispatch();
  const { reponseId } = useParams();
  const [searchParams] = useSearchParams();
  // const router = useRouter();
  // const reponseId = router.query.reponseId;
  const [retour, setRetour] = useState({ interesse: "", remarques: "" });
  const [sent, setSent] = useState(false);

  const handleChangeInterest = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRetour((state) => ({ ...state, interesse: event.target.value }));
  };

  const handleChangeRemarque = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRetour((state) => ({ ...state, remarques: event.target.value }));
  };

  const save = () => {
    dispatch(setLoading({ isLoading: true }));
    postRetour({
      reponseId: reponseId as string,
      email: searchParams.get("email") as string,
      interesse: retour.interesse,
      remarques: retour.remarques,
    }).then((res) => {
      if (res.type === "success") {
        setSent(true);
        dispatch(setLoading({ isLoading: false }));
      } else {
        dispatch(setMessage({ message: res.message, type: res.type }));
        dispatch(setLoading({ isLoading: false }));
      }
    });
  };

  if (reponseId === undefined || searchParams.get("email") === null) {
    return (
      <Container maxWidth="lg" sx={{ textAlign: "center", mt: 6 }}>
        <Typography>
          Une erreur est survenue. Si vous avez cliqué sur le lien reçu par
          email, contactez-nous.
        </Typography>
      </Container>
    );
  }

  if (sent) {
    return (
      <Container maxWidth="lg" sx={{ textAlign: "center", mt: 6 }}>
        <Typography>Votre retour a bien été transmis au vendeur</Typography>
      </Container>
    );
  }
  return (
    <Container maxWidth="lg" sx={{ mt: 6 }}>
      <Stack spacing={2}>
        <FormControl>
          <FormLabel>Êtes-vous toujours intéressé par le bien</FormLabel>
          <RadioGroup
            value={retour.interesse}
            onChange={handleChangeInterest}
            row
          >
            <FormControlLabel value="oui" control={<Radio />} label="Oui" />
            <FormControlLabel value="non" control={<Radio />} label="Non" />
          </RadioGroup>
        </FormControl>
        <TextField
          multiline
          label="remarques"
          value={retour.remarques}
          onChange={handleChangeRemarque}
        />
        <Button onClick={save} variant="contained">
          Envoyer
        </Button>
      </Stack>
    </Container>
  );
};

export default Retour;
