import { formioRequest } from "./formioRequest";

export const getArticles = async () => {
  try {
    const res = await formioRequest({
      url: "/65251eff4a8a916f7a2e647a/submission?limit=1000",
      method: "GET",
    });
    if (res.status === 200) {
      return res.data;
    } else {
      return "error";
    }
  } catch (err) {
    return "error";
  }
};

export const postArticle = async (
  data: {
    slug: string;
    titre: string;
    content: string;
    image: any;
  },
  count = 0
) => {
  if (count > 0) {
    data.slug = data.slug + count;
  }
  try {
    let res = await fetch(
      "https://formio.mobilecoveragemap.com/form/65251eff4a8a916f7a2e647a/submission",
      {
        method: "POST",
        body: JSON.stringify({ data: { ...data } }),
        headers: {
          "x-jwt-token": localStorage.getItem("token") as string,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 400) {
      const json = await res.json();
      if (json.details[0].message === "slug must be unique") {
        postArticle(data, count + 1);
      } else {
        return "error";
      }
    } else if (res.status === 201) {
      return "ok";
    } else {
      return "error";
    }
  } catch (error) {
    return "error";
  }
};
export const putArticle = async ({
  data,
  articleId,
  count = 0,
}: {
  data: {
    slug: string;
    titre: string;
    content: string;
    image: any;
  };
  articleId: string;
  count?: number;
}) => {
  if (count > 0) {
    data.slug = data.slug + count;
  }
  try {
    let res = await fetch(
      `https://formio.mobilecoveragemap.com/form/65251eff4a8a916f7a2e647a/submission/${articleId}`,
      {
        method: "PUT",
        body: JSON.stringify({ data: { ...data } }),
        headers: {
          "x-jwt-token": localStorage.getItem("token") as string,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 400) {
      const json = await res.json();
      if (json.details[0].message === "slug must be unique") {
        putArticle({ data, articleId, count: count + 1 });
      } else {
        return "error";
      }
    } else if (res.status === 200) {
      return "ok";
    } else {
      return "error";
    }
  } catch (error) {
    return "error";
  }
};

export const delArticle = async (id: string) => {
  try {
    let res = await formioRequest({
      url: `/65251eff4a8a916f7a2e647a/submission/${id}`,
      method: "DELETE",
      headers: {
        "x-jwt-token": localStorage.getItem("token") as string,
      },
    });
    if (res.status === 200) {
      return "ok";
    } else {
      return "error";
    }
  } catch (error) {
    return "error";
  }
};

// export const getArticleBySlug = async (slug: string) => {
//   try {
//     let res = await formioRequest({
//       url: `/65251eff4a8a916f7a2e647a/submission?data.slug=${slug}`,
//       method: "GET",
//     });
//     if (res.status === 200) {
//       return res.data[0];
//     } else {
//       return "error";
//     }
//   } catch (error) {
//     return "error";
//   }
// };
