import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../store/errorSuccessSlice";
import {
  FormControl,
  TextField,
  Button,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { selectUser } from "../../store/authSlice";
import { changeUserInfos } from "../../requests/user";

type Props = {};

const ChangePassword = (props: Props) => {
  const dispatch = useDispatch();
  const password = useRef<any>();
  const verifyPass = useRef<any>();
  const user = useSelector(selectUser);
  const [error, setError] = useState("");
  const passwordformat =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&\/#]{8,}$/;

  const changePassword = () => {
    setError("");
    const currentUser = JSON.parse(JSON.stringify(user));
    if (password.current.value === verifyPass.current.value) {
      if (password.current.value.match(passwordformat)) {
        currentUser.data.password = password.current.value;
        changeUserInfos(currentUser).then((res) => {
          if (res === "ok") {
            dispatch(
              setMessage({
                message: "Modification enregistrée !",
                type: "success",
              })
            );
          } else {
            dispatch(
              setMessage({
                message: "Une erreur est survenue veuillez réessayer",
                type: "error",
              })
            );
          }
        });
      } else {
        setError(
          "Votre mot de passe doit être composé d'au moins 8 caractères : une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial (@$!%*?&/#)"
        );
      }
    } else {
      setError("Les deux mots de passe ne sont pas identiques");
    }
  };
  return (
    <Stack spacing={2} mt={4}>
      {error ? <Typography>{error}</Typography> : null}
      <FormControl>
        <TextField inputRef={password} label="Mot de passe" type="password" />
      </FormControl>
      <FormControl>
        <TextField
          inputRef={verifyPass}
          label="Vérification mot de passe"
          type="password"
        />
      </FormControl>
      <Box>
        <Button variant="contained" onClick={changePassword}>
          Changer mon mot de passe
        </Button>
      </Box>
    </Stack>
  );
};

export default ChangePassword;
