import { Button, Link, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import { setSelected } from "../../store/uiSlice";
// import Link from "next/link";

const variants = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "subtitle1",
  "subtitle2",
  "body1",
  "body2",
  "button",
  "caption",
  "overline",
];

const PresentationText = (props: {
  title: {
    data: string;
    color?: string;
    align?: string;
    weight?: string;
    underline?: string;
  };
  subtitle: {
    data: string;
    color?: string;
    align?: string;
    weight?: string;
    underline?: string;
  };
  text: {
    data: string;
    color?: string;
    align?: string;
    weight?: string;
    underline?: string;
  };
  more?: {
    data?: string;
    display?: boolean;
    href?: string;
  };
  setSelected: any;
  path: any;
}) => {
  const display = props.more?.display ?? true;
  return (
    <Stack direction="column" my={4} spacing={2}>
      {/* {variants.map((variant) => (
      <Typography variant={variant}>{variant}</Typography>
    ))} */}
      <Typography
        variant="h3"
        component={"h2"}
        sx={{
          textAlign: props.title?.align ? props.title?.align : "left",
          fontWeight: props.title?.weight ? props.title.weight : "700",
          textDecoration: props.title?.underline
            ? props.title?.underline
            : "none",
        }}
        color={props.title?.color ? props.title?.color : "primary.main"}
        onClick={props.setSelected}
      >
        {props.title.data}
      </Typography>
      <Typography
        color={props.subtitle?.color ? props.subtitle?.color : "text.primary"}
        sx={{
          textAlign: props.subtitle?.align ? props.subtitle?.align : "left",
          fontWeight: props.subtitle?.weight ? props.subtitle.weight : "700",
          textDecoration: props.subtitle?.underline
            ? props.subtitle?.underline
            : "none",
        }}
        variant="h2"
        component="h3"
        onClick={props.setSelected}
      >
        {props.subtitle.data}
      </Typography>
      <Typography
        variant="body2"
        component="p"
        sx={{
          whiteSpace: "pre-wrap",
          textAlign: props.text?.align ? props.text?.align : "left",
          fontWeight: props.text?.weight ? props.text.weight : "400",
          textDecoration: props.text?.underline
            ? props.text?.underline
            : "none",
        }}
        color={props.text?.color ? props.text.color : "text.secondary"}
        onClick={props.setSelected}
      >
        {props.text.data}
      </Typography>
      {/* {display ? (
        <Button
          variant="outlined"
          // href={props?.more?.href ?? "#contactez-nous"}
          sx={{
            alignSelf: "flex-start",
            p: "15px 20px",
            borderRadius: 0,
            letterSpacing: "1.53px",
            fontWeight: "300",
            "&:hover": { color: "white", backgroundColor: "primary.main" },
          }}
        >
          {typeof props?.more?.href === "string" &&
          props?.more?.href.startsWith("https://") ? (
            <a
              href={props?.more?.href}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "inherit",
                textDecorationLine: "none",
                letterSpacing: 1.53,
                fontWeight: "300",
              }}
            >
              {props?.more?.data || "En savoir plus"}
            </a>
          ) : (
            <Link
              href={props?.more?.href || "#contactez-nous"}
              style={{
                // color: "white",
                textDecorationLine: "none",
                letterSpacing: 1.53,
                fontWeight: "300",
              }}
            >
              {props?.more?.data || "En savoir plus"}
            </Link>
          )}
        </Button>
      ) : null} */}
    </Stack>
  );
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  setSelected: () =>
    dispatch(
      setSelected({
        path: ownProps.path.slice(0, -1),
        inputType: "PresentationItemInput",
      })
    ),
});

export default connect(null, mapDispatchToProps)(PresentationText);
